// export const districts = ["lucknow", "sultanpur", "kanpur"];
export const districts = [
  "औरैया",
  "अयोध्या",
  "आजमगढ़",
  "आगरा",
  "अलीगढ",
  "अम्बेडकर नगर",
  "अमेठी",
  "अमरोहा",
  "बदायू",
  "बागपत",
  "बहराइच",
  "बलिया",
  "बलरामपुर",
  "बाँदा",
  "बाराबंकी",
  "बरेली",
  "बस्ती",
  "भदोही",
  "बिजनौर",
  "बुलन्दशहर",
  "चन्दौली",
  "चित्रकूट",
  "देवरिया",
  "एटा",
  "इटावा",
  "फर्रूखाबाद",
  "फतेहपुर",
  "फिरोजाबाद",
  "गौतमबुद्ध नगर",
  "गाजियाबाद",
  "गाजीपुर",
  "गोण्डा",
  "गोरखपुर",
  "हमीरपुर",
  "हापुड",
  "हरदोई",
  "हाथरस",
  "जालौन",
  "जौनपुर",
  "झाँसी",
  "कन्नौज",
  "कानपुर देहात",
  "कानपुर नगर",
  "कासगंज",
  "कौशाम्बी",
  "कुशीनगर",
  "लखीमपुर-खीरी",
  "ललितपुर",
  "लखनऊ",
  "महराजगंज",
  "महोबा",
  "मैनपुरी",
  "मथुरा",
  "मऊ",
  "मेरठ",
  "मिर्जापुर",
  "मुरादाबाद",
  "मुज़फ़्फ़र नगर",
  "पीलीभीत",
  "प्रतापगढ़",
  "प्रयागराज",
  "रायबरेली",
  "रामपुर",
  "सम्भल",
  "संत कबीर नगर",
  "सहारनपुर",
  "शाहजहांपुर",
  "शामली",
  "श्रावस्ती",
  "सिद्धार्थनगर",
  "सीतापुर",
  "सोनभद",
  "सुल्तानपुर",
  "उन्नाव",
  "वाराणसी",
];
export const mediums = ["बेसिक शिक्षा", "माध्यमिक शिक्षा"];

export const schools = [
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय तुर्कीपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सुरान",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कखाबतू",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय खानपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पनहर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय धमसेनी",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रोसंगपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हजारीपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय नवलपुर ब्यौरा",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कन्या जगतपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राजन्दाजनगर अटसू",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बीसलपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय साफर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गुलाबपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बूढादाना",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोठीपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दिबियापुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय केशमपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अधासी",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बिरिया",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रौतियापुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हजरतपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय छदूंद",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय औतों",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भटौली",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय शहाबदा",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दहगांव",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय करौली",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मुकुटपुर",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रठगांव",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बाधमउ",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रठा",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दूल्हा राय का पुर्वा",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हमीरपुर सहार",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "गांधी इण्टर कालेज एरवाकटरा ऐरवाकटरा",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गजेन्द्र सिंह पब्लिक इ0का0 बिधूना",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्यामन्दिर इ0का0 दिबियापुर",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "वैदिक इ0का0 दिबियापुर",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "नगर पालिका इण्टर कालेज औरैया",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "जिला पंचायत इण्टर कालेज मुरादगंज",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "चै0 विशम्भर सिंह इ0का0 ओैरैया",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "तिलक इण्टर कालेज औरैया",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सुन्दर सिंह इ0का0 रामगढ़ हरचन्दपुर",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "नरायन इण्टर कालेज बडेरा",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज अजीतमल",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "जनसहयोगी इण्टर कालेज अमावता",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 आत्माराम मिश्रा इ0का0 क्योंटरा",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 बाबूराम पाण्डेय ऐकडमी औरेया रोड दिबियापुर",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "अमर शहीद वीरागंना रामकली बाई उ0मा0वि0 अटसू ओरैया",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दर्शन नगर-2",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिव नाथपुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय टेंधा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सरायनासिर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नायपुरा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरदोइया",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज गौहनिया अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पी एस पिलखावां",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पी एस देवराकोट",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय राम नगर धौरहरा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय ददेरा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अकवारा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पुरा-1",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रसूलाबाद",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उदय",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरियावां",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय मुंगीशपुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय संदरी",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिरौंधापुर रुदौली",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अब्बुसराय",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बभनगावां",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भदरसा बहार",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कन्या पूर्व माध्यमिक विद्यालय बीकापुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय लुत्फाबाद बचैली",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय चैरे बाजार",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय कोचा कम्पोजिट",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "मलेथू बुजुर्ग",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अटेसर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गाडौली",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मंझनपुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कटघरा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राम नगर अमावा सूफी",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जसरपुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पाराताजपुर प्रथम",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परवर तारा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घुरेटा",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "तुलसी राजकीय बालिका इंटर कॉलेज",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज हैदरगंज अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज टोरो माफ़ी दरियागंज बीकापुर अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज अमानीगंज अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज सलोनिया अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज रुदौली अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज सौरी सोहावल अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पूरे नंदा तिवारी बीकापुर अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका उच्च विद्यालय गढ़ा मिल्कीपुर अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका उच्च विद्यालय हरदी माया अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय सराय सागर माया अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय वेनवा माया अयोध्या",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हौसामतलूबपुर",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गौरी",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हसनाडीह",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अतरौलीया",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अतरौलीया",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बढ़या",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय छपरा सुल्तानपुर प्रथम",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महुला",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अजमतगढ़ प्रथम",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जमुवारी",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आ0 दे0 करखिया प्रथम",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हैदराबाद",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बछूआपार",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कप्तानगंज",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय वेलड़ाड़",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अमीलों",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय काशीपुर",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहगढ़ प्रथम",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जिगिनी",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धरनीपुर",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय  गजेन्द्रपट्टी",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अजमतगढ",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय श्रीनगर",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चकलालचन्द",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जहानागंज",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कोयलसा",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय देवगांव प्रथम",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय महराजगंज",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मेहनगर",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दुर्बासा",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय असाडा",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जाफरपुर",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रानी की सराय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय शाहगढ़ प्रथम",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रैसिहंपुर",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "गांधी गुरूकुल इण्टर कालेज भवरनाथ आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "गांधी इण्टर कालेज मुबारकपुर आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "शिब्ली नेशनल इण्टर कालेज आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0वी0 इण्टर कालेज आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्री दुर्गाजी इण्टर कालेज चण्डेश्वर आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "शिक्ष्क इण्टर कालेज भीमबर आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श इण्टर कालेज बनकट आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "जय किसान इण्टर कालेज घाघरा लाटघाट आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "एस0के0पी0 इण्टर कालेज आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्री राम राष्ट्रीय इण्टर कालेज जहानागंज आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "मुबारकपुर इण्टर कालेज मुबारकपुर ",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्री अग्रसेन कन्या इण्टर कालेज आजमगढ़",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्री कृष्णा गीता राष्ट्रीय इण्टर कालेज लालगंज ",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "इण्टरमीडिएट इण्टर कालेज सठियांव ",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "सी0बी0 इण्टर कालेज तरवा ",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 दहतोरा कम्पो0",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 न0 सोहनलाल कम्पो0",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 कैलाश कम्पो0",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 बाईपुर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 पथौली- 1",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 पथौली- 2",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बाईपुर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दहतोरा-1",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पथौली-1",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पथौली-2",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तोरा",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय म्यापुर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कलाल खेडिया",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बुढेरा",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कन्या बरौली अहीर-2 कम्पोजिट",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कु आखेडा कम्पोजिट",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय लकावली कम्पोजिट",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहगंज नं0 1",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय डिप्टी ग्याप्रसाद",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल ग्राम बडख़ेरा विकास खण्ड अकोला",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल ग्राम संधन पोस्ट संधन अछनेरा",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल इटैली तहसील बाह",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल ग्राम कहराई विकासखंड बरौली अहीर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल बैनपुर विकासखंड बिचपुरी",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल बहरामपुर एतमादपुर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल चैमा शाहपुर फतेहपुर सीकरी",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल करहकी जगनेर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल ग्राम गोपालपुरा विकासखंड जैतपुर कलान",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल पैंतखेरा खंडोली",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल भारपुर फतेहाबाद",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल सौनपुर चीट खेरागढ़",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल सेराब पिनाहट तहसील बाह",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल सौरा सैयान",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल जरौली समसाबाद",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज शाहगंज आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रत्नमुनी जैन इण्टर कालेज आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "पं०दीन द०उ०रा०मा०इ0का0 मिढाकुर आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "पं०दीन द० उ०रा०मा०इ० का०मनकेडा आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "पं०दीन द० उ०रा०मा०इ० का०सैमरा आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कलाल खेरिया आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज नैनानाजाट आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज ऑवल खेडा आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीमती दानकुवरि इण्टर कालेज ऑवल खेडा आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "वैप्टिस्ट उ०मा०वि० सांई की तकिया आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "बी0डी०जैन कन्या इण्टर कालेज आगरा",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "नगर निगम इण्टर कालेज ताजगंज आगरा",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "मुरारी लाल खत्री कन्या इण्टर कालेज आगरा",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "सेन्ट जॉस कन्या इण्टर कालेज आगरा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अकराबाद नंबर- 2",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जिरौली हीरा सिंह",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भिलावली",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कसेरी",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गनियावली",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिंधौली",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिनामयी",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौमुहा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरहौल",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला मुस्लिम",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिद्ध",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिरसा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला गुलार",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला अस्सु",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कन्होई",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बलरामपुर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिहोर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खिटकरी",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला आशा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बोनेर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कोड़रा कम्पो0वि0 कोड़रा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कोछोड़ कोछोड़",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सुजानपुर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय साथनी",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दादों",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पिशावा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रामगढ़ पंजीपुर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कन्या प्राथमिक विद्यालय न0 12",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "बा0 पाठशाला न0 19,",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पुल नानऊ",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जरतौली",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गोसपुर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कमालपुर मरौठा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बीठना",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सिघारपुर",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "नौरंगीलाल राजकीय इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज, अतरौली, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर काॅलेज, नरौना, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "चिरंजीलाल बालिका इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "खैर इण्टर काॅलेज, खैर अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0वी0 इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "एस0एम0बी0 इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "धर्म समाज इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "उदय सिंह जैन कन्या इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "शिवदान सिंह इ0का0, इगलास, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "महेश्वर कन्या इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "रतन पे्रम डी0ए0वी0 इ0का0, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "बाबूलाल जैन इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "के0एण्ड0 एस0आर0एम0वी0 इण्टर काॅलेज, अतरौली, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज, कजरौठ, अलीगढ़",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अछती",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बुकिया",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मदुआना",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मसेना मिर्ज़ापुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर प्रथम",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर केवटाही",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय आराजी देवारा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय माझा कम्हरिया यथानाद्ध",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देवलर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोपालपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कमालपुर पिकर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय एआरएआई सेकंड",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अशरफपुर भुआ",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बड़ेपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बहापुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमदही",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अंजोरपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसहा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौदहपरास",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खालिसपुर गोदाम",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय अलावलपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बहलोलपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बनियानी",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय फ़तेहपुर न्यू",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बसोहरी",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय उमरावां",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय भिटौरा दक्षिणी",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्चप्राथमिक विद्यालय माझा कम्हरिया",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्चप्राथमिक विद्यालय आराजी देवारा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्चप्राथमिक विद्यालय सुरहुरपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय भगोला",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय मूसेपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय औसानपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्चप्राथमिक विद्यालय फ़तेहपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय मुबारकपुर बालक",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर वी पी इंटर कॉलेज विधुत नगर टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "मिश्री लाल आर्य कन्या इंटर कॉलेज टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श जनता इंटर कॉलेज टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "कौमी इंटर कॉलेज टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एच टी इंटर कॉलेज टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एस वी एफ आर बी इंटर कॉलेज कुर्की बाजार",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज अकबरपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बी इंटर कॉलेज इनामीपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज एन पी एन टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "नरेन्द्र देव इंटर कॉलेज जलालपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रांगे राघव प्रथम हंसवर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज जलालपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज आलापुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज भीटी",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज बेवाना",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमेठी 2",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरायखेमा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमेठी 1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेनीपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जयस 2",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मोहना",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "थाना भदैया महमूदपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उडवा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सवंगी",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धीमा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इस्माइलपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भेंवई",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घाटकौर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सेमरा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उसका",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भुसियावां",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पचेहरी",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय साड़ी हृदय शाह",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय माधवपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भटगवां.2",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पालपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "थाना दौलतपुर लोनहट",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गड़रिया डीह",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पलिया पश्चिम",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कटारी 1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बलभद्रपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "थाना अजबगढ़",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरौलिया.1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पिंडारा 1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय दारा1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "थाना अढ़नपुर1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोचहित",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ठेंगहा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तिवारीपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जोगापुर",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "उमारमण राजकीय इ0का0 जामो अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीन दयाल उपाध्याय राजकीय माडल इ0का0 धरौली मुसाफिरखाना अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज शाहगढ अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज गौरीगंज अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज जामों अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज मुसाफिरखाना अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज सोनारीकला अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज इनहौना अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज राजाफत्तेपुर अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज फुरसतगंज अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज टीकरमाफी अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल हरिहरपुर अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कंजास अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल मंगरौली अमेठी",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 नौगावां सादात",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चैक",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 कैलसा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 जोया",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "कन्या उ0प्रा0वि0 धनौरा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 डियोटी",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 शिशोवाली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 कन्या बछरायूॅ",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 गजरौला",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 कुआ खेड़ा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 चकनवाला",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सिहाली जांगीर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 उझारी-1",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सैदनगली-1",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 कन्या लाल बाग",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 शोहरका नबावपुरा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 शेखूपुर झकड़ी",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 मौहम्मदाबाद",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सुल्तानठेर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 खुंगावली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सकतपुर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 रूखालू",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 आदमपुर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 बुरावली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 गंगाचोली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 जिवाई",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 अशरफपुर फेजगंज",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 आलमपुर कैंच",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 अल्लीपुर खादर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 डिडौली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 इकौंदा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सुलतानपुर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 नन्हेड़ा अल्यारपुर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 बोरवरी",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 बिजरा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज अमरोहा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "जे0एस0 हिन्दू इण्टर कालेज अमरोहा।",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "सिख इण्टर कालेज नारंगपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "भगवत शरण इण्टर कालिज, जोया",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "कृषक इण्टर कालेज कोठी खिदमतपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू मैमो0 इण्टर कालिज, ढवारसी",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "छतर सिंह सर्वेश देवी इण्टर कालेज मंगरौली हसनपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इण्टर कालेज ताहरपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "दयानन्द इण्टर का0, सैदनगली (इ0 वित्तविहीन) सैदनगली।",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालेज जब्दा,",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीराम किसान इण्टर कालेज बादशाहपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज मूढ़ाखेड़ा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गांधी विधालय इण्टर कालिज, धनौरा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालेज धनौरा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "ज्ञान भारती इण्टर कालेज गजरौला",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० समरेर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० डेली",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० धौरेरा",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कल्यानपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भीकमपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अल्लापुर भोगी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अन्नी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बरायमई खेड़ा",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० घटूना",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नसरुल्लापुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मदनजुड़ी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पनौड़ी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पैगाभीकमपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लधेरा",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० संडोली",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० करखेड़ी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हर्रायपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रानेट",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गौसपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गिरधरपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० डरैला",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० चैन",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० भानपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० डोलापुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जरीफनगर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि०मचलई",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "संवि०वि० जखौरा जौहरपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कादरवाडी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० म्याऊं",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सुखौरा",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खैरपुर खेराती",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उवप्रा०वि० गुरूपुरी विनायक",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खेडा जलालपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दहेमू",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० टिकुरी",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीन दयाल उपा० राजकीय माडल इ०का० चौड़ेरा कादरचौक बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का० चौड़ेरा कादरचौक बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ० का० घटपुरी बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 यूसुफनगर बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ० का० बुर्रा अहिरवारा ;बोंदरीद्ध बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 सैदपुर बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 सहसवान बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 नसीरपुरटप्पा मलसई बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ०का० दातागंज बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 डहरपुर कलां बदायूँ विज्ञानानन्द रामनारायण वैदिक इ0का0 बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "सिंग्लर गर्ल्स इ0का0 बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "कुँवर रूकुम सिंह वैदिक इ0का0 नगला पूर्वी बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "श्री कृष्ण इ0का0 बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "हाफिज सिद्दीक इस्लामियाँ इ0का0 बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "रामनारायण वैदिक इंटर कॉलेज",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तितरौदा नं01",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 तितरौदा",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 शाहजहापुर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भडल नं 02",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कान्हड",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पटटीबंजारान",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सन्तनगर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसी न0-1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसी न0-2",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसी न0-3",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्र्रा0 वि0 डौलचा- 1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्र्रा0 वि0 डौलचा- 2",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा वि कोताना -1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा वि कोताना -2",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय डौला",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय पुरामहादेव",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शबगा 1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शबगा- 2",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गौरीपुर जवाहरनगर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 गौरीपुर जवाहरनगर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सिसाना",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सिसाना कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 पाली कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खेडकी",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खुब्बीपुर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 निवाडा",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 निवाडा",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नैथला",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नैथला",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सुल्तानपुर हटाना कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 निनाना- 1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बागपत कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कन्या बागपत कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 कण्डेंरा-1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 कण्डेंरा-2",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज जहांगढ़ उर्फ ​​दौझा",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेजएबागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इंटर कॉलेज बावली बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इंटर कॉलेज बड़ौतए बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज दाहा बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज तुगाना बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय अहेड़ा बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय गौना बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च माध्यमिक विद्यालय सूपबागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय तिलपानी बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय मुबारिकपुर खेखड़ा बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय मवीकलां बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या उच्च विद्यालय फ़तेहपुर पुट्ठी बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय छोबली बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय बसौद बागपत",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 डीहा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बारौवा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चिलवरिया",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अमीनपुर नगरौर",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नाजिरपुरा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बनकटा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गोबरे बाग",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पिपरा कमाल",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भदौली चक",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बढिइनपुरवा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 अहिरनपुरवा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 महुलीशेर खाॅ",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 खालेबढ़ैया",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 थैलिया",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महसी",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पूरे शिवरतन सिंह",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गंगापुरवा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 देवलखा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 खिलाफतपुर",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 गाजीपुरवा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय पथरहिया",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय कनहर खास",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय मुकेरिया",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय नौबाना",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बभनी",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय टेपरा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय सेमरा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कमोलियाखास",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मुकाम",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय झालातरहर",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय शारदापारा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक बेड़नापुर",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अटवा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय करीम गनवा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अमीरमाह",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "महाराज सिंह इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मंदिर इंटर कॉलेज माधवपुरी",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "तारा महिला इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "बी0 एस0 एन0 इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "सिटी मोंटेसरी इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा बुद्ध विद्यापीठ इंटर कॉलेज कैलाशनगर",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "आर्य कन्या इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "आज़ाद इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "वैध भगवानदीन मिश्र इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "शाहिदा गर्ल्स इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "सैनिक संस्थान इंटर कॉलेज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "मोंटेसरी उ0 मा0 वि0 मेवातीपुरा बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "सर सैय्यद गर्ल्स इंटर कॉलेज काजीपुरा बहराइच",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट जूनियर हाई स्कूल बैरिया",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट जूनियर हाई स्कूल तालिबपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट जूनियर हाई स्कूल धाही बैरिया",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बघनव",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बकवा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय चौकानी",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भरसौता",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल रुद्रपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भरसौता नीरूपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय करंबर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सुजौली",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय सवारा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय चिलकहर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक विद्यालय बलुआ",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बसरिकापुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय छटा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय सवरूपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय सिंहाचौर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक विद्यालय पियारिया",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सागरपाली क्रमांक 1",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय टोला बाजराय",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय केवरा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय बड़सरी",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय रजौली",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय चैबे छपरा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय टोला फतेहराय",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय शाहपुर अफगा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय सोनाडीह",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय तुर्की",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय कोंथ",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय एकइल",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय अठिला",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय खरूआंव",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय जुड़नपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय बछईपुर",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस एस एच एस एस हुसेनाबाद बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "पी एम एच एस स्कूल परसिया बिसौली बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस बी आर एच एल एच एस स्कूल छाता बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस के एन के एस इंटर कॉलेज छितौनी छत बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "नागेश्वरी देवी इंटर कॉलेज छेदी बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "ए एस एस के के इंटर कॉलेज नारायणगढ़ बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस एस एस एस एस एस इंटर कॉलेज बहुआरा बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "राम सिद्ध इंटर कॉलेज सोनवानी बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "विश्वनाथ तिवारी उ म वि नीरूपुर बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस पी बी एच एस एस सीताकुंड बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एच एस स्कूल जवही दियर बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "इंटर कॉलेज दिउली बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एच एस स्कूल देवकली बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "आर आर आर बी इंटर कॉलेज आदर्श नगर सागरपाली बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "श्री एस एन इंटर कॉलेज रतसर बलिया",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सहजौरा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सादुल्लाह नगर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मुबारकपुर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय अचलापुर घाट",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बसावन बनकट",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लालनगर विरदा प्रथम",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय कन्या उतरौला",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय महुआधनी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बनघुसरा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय शिवपुर ग्रण्ट",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "नगर पालिका जूनियर हाई स्कूल",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भोजपुर नवीन",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मध्यनगरी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गौसड़ी डीह",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मटेहना",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोरखी डीह",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रतनपुर सोनपुर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोहरगडडी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मझगवां",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धनखरपुर हाटी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जानकीनगर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "जमुनी कलां",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय विजुवा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय धर्मपुर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय हंसुवाडोल",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय मुजेहानी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय रमई डीह",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भू जेहरा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कौवापुर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रामपुर बगनहा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आदर्श",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गुलवरिया",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खम्हरिया बाघा प्रथम",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पिपरी कोल्हुई",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० विद्यालय ढोया डाबर",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एमण् पीण् इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "बशंत लाल इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "भगवती आदर्श विद्यालय इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "डीण् एण् वीण् इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "स्वतंत्र भारत इंटर कॉलेज तुलसीपुर",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "लोकमान्य तिलक इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "फजले रहमानिया इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रामशंकर भारतीय इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "मोहनलाल रामलाल इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "भारतीय विद्यालय इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "बी पी एस इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "हाजी स्माईल इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "ए जी हाशमी इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय त्रिवेणी 2",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शुकुलकुवां",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परमपुरवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नादिरपुरवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मोहनपुरवा 2",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मवई बुजुर्ग 2",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक स्कूल लुकतारा 2",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खहरा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उटारवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिकलोडी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहपुरसानी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रानीपुर",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नाहर पुरवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नंदमऊ",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मझिवां सानी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पडेरी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जसपुरा 1",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चंदवारा 1",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक स्कूल. कानाखेड़ा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक स्कूल तंगामऊ",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गौरी कला",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय तग्राडेरा कम्पोजिट",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय काजीटोला",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बघैला",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय  महोखर 1",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चहितारा कम्पोजिट",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बेलापुरवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय  नंदनमऊ",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पछौहां",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय नारायणपुर",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बहेरी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय साहेवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पदमाई",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जमुवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सैमरी",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज बांदा",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज बांदा",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज तिंदवारी",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज पैलानी",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज कालिंजर",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज मटौंध",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज अतर्रा",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज बबेरू",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज कमासिन",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "गांधी राजकीय इंटर कॉलेज ओरान",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज बिलगवां",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज पटेलगंज",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज बिसंडा",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज कोर्रही",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज करतल",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अरूई",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रसूलपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सैदनपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्राoविo मुर्तीपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० न्योछाना",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मोतीकपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अंडका",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अनवारी",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गुग्गौर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कुर्सी 2",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दरावां",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रावविवसैदापुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बड्डूपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खिझना 2",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० रघुनाथगंज",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मोहम्मदपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हुसैनाबाद",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० मंझर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट गौरा उस्मानपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० बिलौली",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जेठवनी वि०ख० बनीकोडर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गदियाए वि०ख० बंकी",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० अटवटमऊ वि०ख० देवा",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दरियाबादण्1ए वि०ख० दरियाबाद",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० फतेहपुर वि०ख० फतेहपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० हैदरगढए वि०ख० हैदरगढ",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सतरिख वि०ख० हरख",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बडागांव वि०ख० मसौली",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कुर्सी 1 वि०ख० निन्दूरा",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० कस्बा इचौली वि०ख० पूरेडलई",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० किला रामनगर वि०ख० रामनगर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कोठी वि०ख० सिद्धौर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सिरौलीगौसपुर वि०ख० सिरौलीगौसपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सूरतगंजण्1 वि०ख० सूरतगंज",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दहिला वि०ख० त्रिवेदीगंज",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज बेलहरा",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज सूरतगंज",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज सिरौली गौसपुर",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज निन्दूरा",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "फतेहचन्द्र जगदीशराय इं०का० सफदरगजं बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "पार्श्वनाथ दिगम्बर जैन इं०का० टिकैतनगर बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "लाल बहादुर शास्त्री इण्टर कालेज दरियाबाद",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल इण्टर कालेज फतेहपुर",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "एंग्लो इण्टर कालेज नवाबगंज अलियाबाद बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "पायनियर मांटेसरी इ० का० लखपेड़ाबाग बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राम सेवक यादव स्मारक इ० का० बडेल बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सांई इंण्टर कालेज लखपेड़ाबाग बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती वि०मा०इं०का० केशवनगर बाराबंकी",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कैमुआ",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नवदिया घाढ़पुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बभियाना",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देवचरा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि० भुता",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि० सुनौरा मुरारपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि०शिवनगर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० इनायतपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नंदगाँव",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बल्लिया",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० परचवा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० फाजिलपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० औरंगाबाद",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अनन्दापुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बुखारपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चन्दपुर जोगियान",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मटियार",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० करगैनाण्प्रथन",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० घुरा राधवपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० करेलीण्प्रथम",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मुडिया जागीर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पिपरा नानकार",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय राठ",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चौपारा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अलीनगर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा०वि० सुरला",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा०वि० जादोंपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ० प्रा० वि० शिवनगर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० खजुरिया सम्पत",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ० प्रा० वि० रजऊ",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० अंधरपुरा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नगरिया विक्रम",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जेड",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कठर्रा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उवप्रा०वि० देवरनियों कन्याद्ध",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल उपाध्याय राजकीय मॉडल इण्टर कालेज चौबारी क्यारा बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल नन्दगाँव क्यारा बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "हवलदार अब्दुल हमीद इ०का० वाहनपुर बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "आर०पी० इ०का० मीरगंज बरेली।",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "जे०पी०एन० इ० का० नबावगंजए बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का०ए नबावगंज बरेली।",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल बल्लियों मीरगंज बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "यूनीक मॉडल इद्ध का० फतेहगंज पं० बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "दुर्गा प्रसाद मैमो हायर सकेन्द्री मीरापुर फतेंहगंजए बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "कुवैर ढाकन लाज़ इ०का० सहोड़ा मीरगंज बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "गाधी इण्टर कालेज शाही मीरगंज बरेली।",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल उपाध्याय राजकीय मॉडल इण्टर कालेज कचनेरा दमखोदा बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल शिवपुरी बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "सरदार भगत सिंह हाई स्कूलए राजपुर कलान बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "शिक्षा निकेतन इ०का० चम्पतपुर बरेली",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० हनुमानगंज",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० मझौवा कला",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० गोठवा द्वितीय",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० बभनियावंबुजुर्ग",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० जिगिना",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० मूडघाट",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० महसों प्रथम",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० गनेशपुर द्वितीय",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० धोबहिया",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० झलहा",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० अहरा",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० रौतापार",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० बेल्सुही",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० बोदवल",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० पोखरा",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० बैड़ारी मुस्तहकम",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० बानपुर",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० पसड़ा",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० परेवा",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि० मलौली गोसाई",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० पचमोहनी",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० मझारी",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० धवाय",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० पाउ",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० उंभाई",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० देवमी",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० महसो द्वितीय",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० रधुनाथ पुर",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० डारीडीहा",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० परसाजागिर",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० अहरा, कन्या अहरा",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० रामपुर देवरिया",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० मलौलीगोसाई",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० जगदीशपुर",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि० जगतापुर",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय इंटर कॉलेज बस्ती नगर क्षेत्र",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "पंडित दीन दयाल उपाध्याय राजकीय मॉडल इंटर कॉलेज गोठवा",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "पंडित दीन दयाल उपाध्याय राजकीय मॉडल इंटर कॉलेज गोठवा श्रीगीनारी",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय कन्या इंटर कॉलेज कछिया",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय कन्या इंटर कॉलेज सोनबरसा",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर माध्यमिक कलवारी एहतमाली",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर माध्यमिक खखुवा",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: '"राजकीय उच्चतर मध्यम ',
  },
  {
    district: ' सिकटा"',
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर माध्यमिक बरहपुर",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "सिकटा उच्चतर माध्यमिक अजगौवा जंगल",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर मध्यम महुलानी",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर माध्यमिक परसांव",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: '"जनता ',
  },
  {
    district: ' शिक्षा निकेतन इंटर कॉलेज ओडवारा"',
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "किसान इंटर कॉलेज भानपुर",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "कृषक इंटर कॉलेज कुरियार",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कस्तुरीपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 एकौनी",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मनापुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मोढ़ स्टेशन",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मोढ़डीह",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पट्टीबेजाव",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मनीगंज",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कौवापुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मँगुरा",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तेजसिंहपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकविद्यालय वीरभद्र पट्टी सेकंड",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकमिल्कीपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकविद्यालय हिम्मतपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकविद्यालय रामनगर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकविद्यालय दानुपुर पूरब पट्टी",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० चकलाला",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० झौवा",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० डभका",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिटवि० द्वारिकापुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिटवि० नरथुआ",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिटवि० लालानगर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "पू०मा० वि० लोकमनपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटेबना",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जेठूपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पूरेमिया खां",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 खेम्हईपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बभनौटी ।",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट चेतनीपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अर्जुनपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पिपरी",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सरैया",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 प्रजापतिपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सुल्तानापुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जयरामपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 अजयपुर",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल बर्जी",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल लालीपुर",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल चकमन्धाता",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल महुआपुर",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल बारीपुर",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "श्री गणेश प्रसाद इ0 का0 बरवाँ",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "गुलाबधर मिश्र इ0का0 गोपीगंज",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "पं0 रामवंकल इ0का0 रोही",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "लक्ष्मण प्रसाद इ0का0 करियाव",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "महाबीर इ0का0 बिछिया बनकट",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "कालिका इ0का0 कोईरौना",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "डा0 लोहिया इ0का0 सागरपुर बवई",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "इण्टर कालेज दुर्गागंज",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "श्री काशीराज महाविद्यालय इ0का0 औराई",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "श्री रामसजीवन लाल इ0 का0 खमरिया",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 इमरत पुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बनियावाला",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 साहा नगर कुराली",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय माधोवाला",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मुस्तफापुर तैय्यब",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मुस्तफापुर तैय्यब",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल वाजिदपुर बीरू",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल हकीमपुर मेढ़ा",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कशमीरी",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चकरपुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 फलोदा",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल नकीब पुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जसमौर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सेलाखेड़ी",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कुरीबांगर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बल्लानागला",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 छज्जूपुरा सादात",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "थाना जौनुलाबदीनपुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बालिका झालू",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नांगल जाट द्वितीय",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 नजीबाबाद",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हल्दौर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 स्वाहेड़ी",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सुवावाला",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नहटौर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 स्योहार द्वितीय",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गजरौला पाईमार प्रथम",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पिलाना",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सदाफल",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भटियाना",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पुरैनी",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 धौकलपुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 किवाड़",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गंगोड़ा जट",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कुम्हारपुरा द्वितीय",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय आदर्श जनता इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय नवरत्न इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय विवेकानन्द विद्या मन्दिर इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "पंडित दीनदयाल उपाध्याय राजकीय माडल इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "पंडित दीनदयाल उपाध्याय राजकीय माडल इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ऊँचागाँव खादर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा वि० नोजलपुर खादर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नित्यानन्दपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० उ०प्रा०वि० ग्रा०वि० रूठ बांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गोपालपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० उ०प्रा०वि० जोखाबाद",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० तिल की भवैया",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रजपुरा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शेरपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० राजारामपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० अप्रैल",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० उ०प्रा०वि० रमपुरा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० उ०प्रा०वि० मामनखुर्च",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुंडा खेड़ा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मेहरुपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक स्कूल अरनिया",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक स्कूल जखौता",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुरली नगला",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय दारापुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कर्मधर्म नरोरा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 गोकलपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रामघाट",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नरौरा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 फरीदाबांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चासीरसूलपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 थानागजरौला",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 स्याना",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सराय",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नहचैली",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 शेरपुरबांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 हसनपुरबांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सिरौराबांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 तिबडा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 हजरतपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 दरियापुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "भागीरथी वेदाग इ0का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री देवत्रय आदर्ष इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "इर्रिगेशन इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "इन्द्रजीत इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "इ0 गा0 क0 इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "पब्लिक इ0 का0-स्याना",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "भू0 स0 मै0 इ0 का0-स्याना",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "विवेकाननद इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "एल0 डी0 ए0 बी0 इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "जी0डी0ए0बी0इ0का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई0स्कूल",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "बुदसैन प्रेम चन्द्र इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री कृश्ण इ0 का0",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय खजहरा",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कसावद",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय तालसपुर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय ककरैत",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोरखहा",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जगदीशसराय",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय एम.कांशीराम आवास चंदौली",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिछिया-प्रथम",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिछिया कला",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय फुटिया",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इलिया-प्रथम",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कलानी",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तियारी",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेन",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गांधी नगर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नौगढ़ प्रथम",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अमृतपुर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जरहर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिकनी",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मझगांवा माझी बस्ती",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुंडा कनाला",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "यूपीएस कुंडा खुर्द",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुंडाखुर्द",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घुरहुपुर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कम्हरिया",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खेतरपाला",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय महराजगंज",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चकिया",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिंघा",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नाडी द्वितीय",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गद्दोचक",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय प्रसहता",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमादपुर प्रथम",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पपरौल",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पपरौल",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल चिठवनकला चकिया",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कुरहना चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कैडीहार चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल देवरीकला चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल लवारीकला नौगढ़ चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल गोलाबाद नौगढ़ चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल जमसोत नौगढ़ चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल उदितपुर मुर्रा चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल सोनवार चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "आर्दश जनता हा०से० स्कूल चतुरीपुर चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "बाल्मीकि इ0का0 बलुआ चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "सरवस्ती इ0का0 टाण्डा चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकुमार इ0का0 भुजना चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "धराव इ0का0 धराव चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल उपाध्याय उच्चतर मा०वि० मोदी नगर नियमताबाद",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गड़रियान पुरवा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमरपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नया पुरवा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बगुड़ा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मानिकपुर ग्रामीण",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भनभिया",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसिला",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बड़ी मड़ैयां",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कराही",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुरका",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अहिरानपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टिटौली",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोपा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जमीरा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छिवलहा 1",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरमबाबा का पुरवा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोलमाजरा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बंधी",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देउंधा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चकुंड",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चकजफर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय काशी 2",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कंठीपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सोनपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिवरामपुर 1",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक बड़ी मंडियां",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक दादरी माफ़ी",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व मध्यमक्लोधवारा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक बारामाफी",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "उच्चतर प्राथमिक विद्यालय लक्ष्मीपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "उच्चतर प्राथमिक विद्यालय अतरौली",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व मध्यम चंदाई",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक मुर्का",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "उच्चतर प्राथमिक विद्यालय कन्या छीबो",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "उच्चतर प्राथमिक विद्यालय कटिया खादर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज कर्वी",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गंगा प्रसाद जनसेवा इंटर कॉलेज कर्वी",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "सेठ राधा कृष्ण पोद्दार इंटर कॉलेज सीतापुर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजिकीय हाई  स्कूल  शिवरामपुर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजिकीय हाई  स्कूल रौली कल्याणपुर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "चित्रकोट इंटर कॉलेज कर्वी,चित्रकूट",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजिक्य हाईस्कूल भरतकूप",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजिकीय हाई  स्कूल नोनार",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "कृषक इंटर कॉलेज भौंरी",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "त्यागी इंटर कॉलेज ऐंचवारा",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श इंटर कॉलेज मानिकपुर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "सुभाष इंटर कॉलेज इटवा",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "रतन नाथ इंटर कॉलेज रसिन",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "गोस्वामी इंटर कॉलेज छीबो चित्रकूट",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "एस. पालेश्वर नाथ इंटर कॉलेज पहाड़ी चि",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रजडीहा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नौतन",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पिपरा खेमकरण",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बनकटा चित्रसेन",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भदीला",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नवलपुर",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० इचौना बाजार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चकरवाँ बहोरदास",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भागलपुर 2",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टिकर",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महुई श्रीकांत",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भैदवा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परसिया देवार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेलदार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिरईगोड़ा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सेमरौना",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सत्तूआभार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खोपा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेलवा दुबौली",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हतवा बाजार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जयराम",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "क०पू०मा०वि० मस्जिद वार्ड",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कल्याणी",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० धनीती कला",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० फुलेहरा देसही देवरिया",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि०खडेसर बरहज",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० तिलौली",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० उसका",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खुड़िया मिश्रा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खोराराम",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बागापार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० हरखौली",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक वि० भड़सर",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बंजरिया",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दुबौली",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "महाराजा अग्रसेन इण्टर कालेज देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "लाला करमचन्द्र थापर इण्टर कालेज, बरारी बैतालपुर देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "अशोक इण्टर कालेज रामपुर कारखाना-देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "चन्द्रशेखर अजाद इण्टर कालेज गौरी बाजार - देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "आचार्य नरेन्द्र देव इण्टर कालेज, पथरदेवा",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "महन्थ त्रिवेणी पर्वत इण्टर कालेज बरारी बैतालपुर देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "सुभाष इण्टर कालेज भटनी, देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "बाबा राघव दास कृषक इण्टर कालेज भाटपाररानी, देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "बापू इण्टर कालेज, सलेमपुर-देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस०डी० इण्टर कालेज, भाटपाररानी, देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "बब्बन सिंह इण्टर कालेज, रतसिया कोठी-देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "हर्ष चन्द्र इण्टर कालेज, बरहज देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "मदन मोहन मालवीय इण्टर कालेज भाटपाररानी, देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीकृष्ण इण्टर कालेज आश्रम बरहज- देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "बाबा गायादास टेक्निकल इण्टर कालेज, बरहज-देवरिया",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुचदायम खा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरायअगहत",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बनी",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला उम्मेद",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कैला",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इमादपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सर्रा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला मोहन",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जरारी",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लालपुर जखा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खिरिया बनार",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ललहट",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ढीपा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सकतपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घुटलई",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुबारकपुर छछैना",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सैदपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बहलोलपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अंगदपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कन्या खरसुलिया",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 राजा का रामपुर पू0",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अल्लापुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बन्धा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भदकी",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अहराई",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अहमदपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अमृतपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बड़ागाव",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बढ़ापुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 आसपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बक्सीपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चपरई",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अयार",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बहलोलपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "अविनाशी सहाय आर्य इ0का0 एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "एम0जी0एच0एम0 इ0का0 मारहरा एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज कैल्ठा एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0बी0 इ0का0 अलीगंज एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "वि़द्या निकेतन इण्टर कालेज वाहनपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालेज जिन्हैरा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री किसान इण्टर कालेज धिरामई",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री के0 एस0 इण्टर कालेज हिम्मतपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "उल्फतराय इण्टर कालेज वसुन्धरा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "लालबहादुर शास्त्री इण्टर कालेज कंगरौल",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "आर्दश इण्टर कालेज उम्मेदपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "एस0 वी0 एस0 इण्टर कालेज सकरौली",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "जन कल्याण इण्टर कालेज सिकरारी लालपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री नेहरू स्मारक इण्टर कालेज जैथरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० दासीपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० दतावली",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० चितभवन",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० कृपालपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० बीना",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बरालोकपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० शेखूपुर सरैया",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० पचावली",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० रामनगर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० नगला छत्ते",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० बेलैयापुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० घरवार",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि०कम्पो० बसैयाहार",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि०कम्पो० करौंधी",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० खितौरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० मडैया दिलीप नगर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० ईश्वरीपुरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० मेंहदीपुरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० विरौंधी",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० अकबरपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ० प्रा०विद्यालय लोहा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय बनामई",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा० विद्यालय महोला",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रादिद्यालय कम्पो० (नगर) जोर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय हनुमन्तपुरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ० प्रा० विद्यालय दनी केशीपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय आढरपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०ग्रा० विद्यालय कम्पो० बेसोलीघाट",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०दिद्यालय बिरारी",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय कम्पोत सलेमपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र० विद्यालय जोनई",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०ग्रा०विद्यालय सारंगपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा० विद्यालय धिगरई",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय यासीनगर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय अकबरपुर",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज कुण्डेशार",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "जन सहयोगी इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "गोपाल कन्हैया लाल इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "सर्वोदय इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "स्वतंत्र भारत इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "प्रहलाद स्मारक इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "बलवन्त शहीद इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीमती इन्दिरा गांधी इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "एस०ए०वी०इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "लोकमान्य रूरल इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर इण्टर कालेज",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गरिया हैवतपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हुसैनपुर तराई",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नैगवां-द्वितीय",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पपरीखुर्द",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परतापुर तराई",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय  रामपुर जसु",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रोशनाबाद",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुइयां खेड़ा",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रठौरा",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हिसामपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरना बुजुर्ग",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कनकौली",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राजेपुर टप्पा मंडल",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मधवापुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय  फ़तेहपुरराव साहब",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गुलरिया",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोपालपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बहबालापुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अजमतपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय विजहादरपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरझाला",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रसीदपुर तराई",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भटपुरा",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रजीपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सकवाई",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 पिपरगाॅव",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अताईपुर जदीद",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 जिनौल",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 राजेपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बहादुरपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरौन",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 महरूपुर सहजू",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "संविलयन विद्यालय कक्यौली",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "संविलयन विद्यालय नौगांव",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0 विद्यालय उम्मरपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "प०जवाहर लाल इ०का० जरारी",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "चौधरी गजराज सिंह इ० का० टिलियां",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी रामप्रकाश आ० इ०का०तिराहा मुरहास",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "रूपकिशोर चतुर्वेदी इ० का० सिकंदरपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "के०एस०आर० इ०का० कम्पिल",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इ0का0 रशीदाबाद तिवारीय",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "कर्नल ब्रह्मानंद इ० का० शुकरुल्लापुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजाराम इ० का० अचरा खलवारा नवाबगंज",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय वि०उ०मा०वि०आर्यननगर खलवारा",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहरलाल नेहरु उ०मा०वि० हुसैनपुर नवाबगंज",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "शास्त्री उ०मा०वि० चन्दुइया",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "सूर्य कुमारी इ०का० मंझना",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "श्री महिपाल शास्त्री इ०का०बबना",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी दीनबन्धु इ0का0",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "ए०वी०इण्टर कालेज",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कोराई द्वित्तीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० वाहिदपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० त्रिलोकीपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सहिली",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जगन्नाथपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सोनही",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कुसुम्भी द्वितीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रमवा द्वितीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० छिछनी",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० थरियावं प्रथम",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हसवां द्वितीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुरांव",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सुकेती",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गाजीपुर द्वितीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मखदूमपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पैगम्बरपुर बकरी",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० झलहा",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पलवां",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० छिवलहा प्रथम",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गौसपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० आबूनगर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० आदर्श खेलदार",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नहमूदपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बक्सपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नरायनपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० चितौरा",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कासिमपुर बीबीहाट",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बिलन्दा प्रथम",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० त्रिलोकीपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० चखेडी",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गौरा चुरियारा",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० उदई संराय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गोवर्धनपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०कि० बेलाया",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र०वि० टकौली",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पं० दीन दयाल उपाध्याय राजकीय माडल इण्टर कालेज ऐलई",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०वि० अजमतपुर फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०वि० लालपुर दरौटा फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दयानन्द इंटर कालेज बिन्दकी",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "बहुआ इंण्टर कालेज बहुआ",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पं० दीन दयाल उपाध्याय राजकीय माडल इण्टर कालेज लतीफपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एच एन बहुगुणा इण्टर कालेज हुसेनगंज",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पं जवाहर लाल इण्टर कालेज खखरेरू",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०वि० टीकर फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का० फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पं० दीन दयाल उपाध्याय राजकीय माडल इण्टर कालेज अलादातपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सदाशिव इण्टर कालेज रेलबाजार फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "निरंकारी बालिका इण्टर कालेज फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "ए०एस० इंटर कालेज फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "भारतीय विद्यालय हा०सें० स्कूल फतेहपुर",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि० सराय मटियारी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मिलावली",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० थरौआश्",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सुरेला",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नगला अकोला",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खैरगढ़ 1",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कुसुआमई",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बिल्टीगड 1",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० साथी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०कि नगला डरू",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कठफोरी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मदनपुर",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिरसाखास",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सरायमुरलीधर",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लखनई",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रूपसपुर 2",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सापुर 1",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नीम खेरिया",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नौशहरा",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० आररोज",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पुल खडीत",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कनवारा",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बिल्टीगढ",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भदान",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालयब दिवायची",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय विलहना",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय शंकरपुर",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय औरंगाबाद",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दारापुर मिलावली",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय ढोलपुरा",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बरतरा",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मौहम्मदी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बैजुआ खास",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कंथरी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय नगला सिंघी",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेज",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेज",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेज",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अट्टा 1",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अट्टा 2",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नया बासं",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हरौला",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मारेना",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय निठारी",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय छलेरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सदरपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० छलेरा 1",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सै0 12",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सै० 22",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गिझोड",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय होशियारपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यलाय आगाहपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० डाबरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० सादोपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० जोनसमाना",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० रानौलीलतीफपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० बीलअकबरपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० धूममानिकपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० रायपुर बाँगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० घोडीबछेडा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० चिटहरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० कटहरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० रामगढ",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० शादीपुर छिडौली",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नवादा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिलासपुरण् 2",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय समसपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय क्यामपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बल्ला की मढैया",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चुहड़पुर खादर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दौला रजपुरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय परसौल",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय उस्मानपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज नोएडा गौतम बुद्ध नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इंटर कॉलेज होशियारपुर नोएडा गौतम बुद्ध नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल  छाजरसी जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल  शादीपुर चिडोली जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री दयानंद इंटर कॉलेज बम्बावर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गांधी आईसी गौरी बछेड़ा सेक्टर एमयू द्वितीय जीआर नोएडा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "चौधरी केशराम आईसी हबीबपुर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय एस एस 1सी सदरपुर जीबी नगर ",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एस  एस  स्मारक किक शरफाबाद जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एस डी कन्या इंटर कॉलेज बिलासपुर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "मिहिर भोज गर्ल्स इंटर कॉलेज दादरी जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "विद्या देवी इंटर कॉलेज मकनपुर खादर गौतम बुद्ध नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "नोएडा कन्या इंटर कॉलेज भंगेल बेगमपुर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "बी डी आर डी एस वी एम इंटर कॉलेज दनकौर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "पी टी सालाग्राम इंटर कॉलेज हबीबपुर जीबी नगर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घूखना",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिहानी 2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिहानी 3",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिहानी 4",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिहानी गेट",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिरोरी-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गनोली",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय फतियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राजपुर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय निस्तोली-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नैपुरा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय यासीनगढ़ी",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जलालाबाद-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कनौजा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नूरपुर-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुशलिया",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भीकनपुर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शामली",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किन्हापुर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला मूसा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पट्टी",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमराला",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सारा-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट  विद्यालय जगतपुरी",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेगमाबाद-2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जोया",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मुरादनगर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय शहजादपुर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सौंदा-2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरना",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ढिंडार नं. 2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भनेडा नं. 2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुन्हैरा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय झलावा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय विहंग",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "सेठ मुकन्द लाल इन्टर काॅलिज, गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "मनोहारी विद्यालय मन्दिर हा0 सै0 स्कूल गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "नगर पालिका बालिका इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "एम0एम0एच0 बालिका इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "सुशीला गल्र्स इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सनातन धर्म इन्टर काॅलिज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गुरूनानक कन्या इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "इन्ग्राहम इंटर कॉलेज राजनगर गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इन्टर काॅलिज विजयनगर गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "कन्या वैदिक इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज नन्दग्राम गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "जैनमति उजागर मैल जैन इंटर कॉलेज कविनगर गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "एम0एम0एच0वी0 इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "एन0एच0के0एम0 इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "संभू दयाल इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दिलदारनगर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गहमर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजि विद्यालय वरेजी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० आराजी ओड़ासन",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० देवकठिया",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सराय वन्दी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० देवकली.1",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मऊ पारा",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पियरी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० करण्डा.2",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सहेड़ी.1",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दीनापुर.1",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अगस्ता",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नूरपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चकदराय",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कनेरी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पवहारी कुटी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सरदरपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हथौड़ा",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रावल",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पचोखर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० देवकली",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० रमवल",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० रसूलपुर हबीबुल्ला",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० मिर्जाबाद",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० उतराव",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कादीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय औड़िहार",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सरदरपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हथौड़ा",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० पारा",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय वाजिदपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० रामपुर बलभद्र",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बद्दोपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय धरवों",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गाधी इ०का० ढोटारी गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल इ०का० कासिमाबाद गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गाधी गेगोरियल इ०का० बहादुरगंज",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पी०एन० इ०का० मरदह गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श सेवा इ०का० नोनहरा गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "डा० एम० असारी इ०का० युसुफपुर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "इण्टर कालेज करण्डा गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "शिवकुमार शास्त्री इ०का० जंगीपुर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "इ०का० खालिसपुर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "केदारनाथ कृषक इ०का० उचौरी गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "टाउन नेशनल इ०का० सैदपुर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "हनुमान सिंह इ०का० देवकली",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "शहीद स्मारक इ०का० नन्दगंज गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गहमर इ०का० गहमर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीकृष्ण इण्टर कालेज डेढगांया गाजीपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दुल्लापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटरा लकड़मण्डी",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हनुमानगंज",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जहगीरवा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटरा शहबाजपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाबूराम पुरवा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पारस पुरवार डीह",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पिपरी रंहुआ",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भानपुरवा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० तपस्वीधाम",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नकहा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जोकही",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कादीपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पूरे गुलरिहा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बरदहीमऊ",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बिसुनापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बभनजोत",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० औराडीह",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दौलतपुर माफी",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लखनीपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० बेलहरी",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० ठाकुर दास पुरवा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० चन्दीपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० बनघुरा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० पूरे गजराज",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० मोहना",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० पण्डरीकृपाल",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० मल्लापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० छितौनी",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पों० वि० हथियागढ़",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० चन्दापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० बंजरिया",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० रामापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० तिलका",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० बड़गांव",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "गंगा प्रसाद मिश्री लाल इण्टर कालेज कौड़िया गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता उच्चतर माध्यमिक विद्यालय कौड़िया गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "संत सहजवन इण्टर कालेज बाबागंज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज इटियाथोक गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "महाराजा देवी बक्श सिंह इण्टर कालेज तरबगंज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "डी०ए०वी० इण्टर कालेज नबाबगंज",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "सुभाष इण्टर कोज उमरी बेगमगंज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज अमदही",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "कन्हैया लाल इण्टर कालेज कर्नलगंज",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इण्टर कालेज भभुआ गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "बालिका उ०मा०वि०कर्नलगंज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०वि० बस्ती गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "फ० अ० अ० राजकीय इण्टर कालेज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा० उ०मा०वि० रामपुर टेपरा गोण्डा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तिनकोनिया नं01",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 श्रीपुरा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भौवापार 1",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ०प्रा०वि० करही",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बकुलही",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सोनबरसा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 सोनवे",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 पथरा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अजायब टोला",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चनऊ",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बाघागाढ़ा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ०प्रा०वि० भरकच्छा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गाहासाड़ प्रथम",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 लहसडी 1",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 नौवा डुमरी 2",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 डोमहर",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जं0 औराही",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 छपिया",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ०प्रा०वि० डीहघाट",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 सेन्दुली बेन्दुली",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 बरबसपुर बिनहा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 सेमरौना",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "ए0डी0 राजकीय कन्या इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एल0पी0के0 इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 झरवा",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "महाराणा प्रताप इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अयोध्या प्रसाद",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 वसुधा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० डीहघाट 1",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 डुहिया",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय जुबिली इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एम0एस0आई0 इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा गाँधी इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "मारवाड़ इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गाँधी शताब्दी इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बरौली",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बगही",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिंहोरवा",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "इमामबाड़ा मुस्लिम गल्र्स इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दयानन्द डी0ए0वी0 इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री भोला राम मस्कारा इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जं0 नन्दलाल",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भगवानपुर",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पकड़ीयार",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एम0पी0पी0 आर्य कन्या इ0का0",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मंदिर इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुंडेरा ठकुराई",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बड़हरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मेरापुर",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भोला डेरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुतुबपुर पटिया",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इन्द्रपुरी",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बरौली",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उच्च प्राथमिक विद्यालय मनकी कलों",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ० प्राथमिक विद्यालय मनकी खुर्द",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कलौलीतीर",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चन्दुलीतीर",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरदहा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पारा ओझी कम्पो0",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सुरौलीखुर्द",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भौरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बडा कछार",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बरूवा कम्पोजिट",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "क० प्राथमिक विद्यालय अरतरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिसोलर",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) भदुरी",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिहरका",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गुरदहा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8)",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "गुसियारी",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) रीवन",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "कन्या उ० प्राथमिक विद्यालय बहदीना अछपुरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोरनपुरवा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय औरा डेरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भटरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गल्हिामऊ",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुल्लूपाल का डेरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "कन्या प्राथमिक विद्यालय बहगांव",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कुछेछा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नई बस्ती मलेहटा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरि०ब० मझगवां",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरगांव",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरि०ब० लिधौरा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 बेरी",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पारा रैपुरा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कुछेछा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 कुनहेटा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 पाटनपुर",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल गुन्देला",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल धमना",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 गोहाण्ड",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल बिलगाॅव नवीन",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 बौखर",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 भेडी डाडा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रा0कस्तुरबा गांधी बा0मा0वि0जरिया",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 चण्डौत",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री चित्रगुप्त इं0का0 राठ",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सरस्वती वि0मं0इं0का0 हमीरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खेड़ा-1",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 लालपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मिलक",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हसनपुर-1",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सिवाया",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दहीरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गांधी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 शिवगढ़ी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गणेश",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चमरी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गौंदी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सिरौधन",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अब्दुल्लापुर मोढ़ी-2",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गढ़-1",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 आलमगिरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मौ0 ररूस्तमपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कल्याणपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जनूपुरा-1",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भोगापुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बागडपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 आरिफपुर मढ़ैया",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नवादाकलां",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 फुलडेरा",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय लक्ष्मी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आदर्श",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कपूरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 करनपुर जट्ट",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हिम्मतनगर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पूठाहुसैनपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कस्तूरबा",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अम्बेडकर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय राजकीय",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हैदरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 गढ़मुक्तेश्वर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नयाबांस",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज बडौदा हिन्दूवान हापुड",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज मुर्शदपुर हापुड",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श इण्टर कालेज गढ",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज अनूपपुर डिबाई",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "लाला बाबू बैजल मो०इण्टर०का० लोधीपुर",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "उदयप्रताप इ०का०सपनावत",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "मारवाड इ० का० पिलखुवा",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "बी०आर० इण्टर का० समाना",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "एस०एस०बी० इण्टर का० हापुड.",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "दीवान इण्टर कालेज हापुड़",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "श्री चण्डी विद्यालय इ०का०पिलखुवा",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "सवैहितैषी इ०का० मदस्याना",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "स्ववंत्रभारत इ०का०सलारपुर",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "आर्दश इ०का० मतनौरा",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज शेरपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महोनी",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खेतुई",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोढ़वा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय काशीपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुजाहिदपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रीठवाई",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महसोना",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय निगोहिया",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ढिकुन्नी",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भरावन 1",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हड़हा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक शाला सिलबारी",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय फरिगहना",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खदिया नगला",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अजमतनगर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मेउरा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय झाला पुरवा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोडरा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिशिताली",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जमुनिहारा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल नयागांव मुबारकपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बावन",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कासिमपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल लालपुर बजरा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल मुंडेर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल यूटीआरए",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बालामऊ",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल शाहपुर गंगा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल इटारा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल लछनपुरवा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल मलैया",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल इंगवान",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बरहा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल टंडियावां",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल टोंडरपुर",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 हरदोई",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 टडियावां",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 हरदोई",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 सण्डीला",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 बिलग्राम",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 पाली",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 पिहानी",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपा0 रा0 माॅडल इ0का0 सिकरोहरी",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपा0 रा0 माॅडल इ0का0 खसरौल",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपा0 रा0 माॅडल इ0का0 जरौआ",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 टोडरपुर",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 बेगमगंज",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 अर्जुनपुर",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 रौरा",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 शिवसिंह पुरवा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय गंगाचौली",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय पुरा खुर्दा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कटैलिया",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय चन्द्रगढ़ी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय नूरपुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय जसराना",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय हनुमान चौकी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय सलेम पुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय सम्मामय रुहाल",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बसैकागी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय मुहारिया",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय छोड़ा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कोमारी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय राघनिया",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय ऊसावा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय निनामाय",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बासगोई",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बिजलीघर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बराह",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय अण्डौली",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय विष्णुपुरी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय पयंदापुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बासतोई",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बसई",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय सीछाबली सैनी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय मीतई",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कोटा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय हतीषा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कुँवरपुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विधायला कपुरा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कच्चापुरा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय नगला भूषण",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय खिजरपुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय रतिभानपुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बारामाय",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "पी.बी.ए.एस. इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "श्री अक्रूर इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "डी.आर.बी. इंटर कॉलेज आगरा रोड हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीकुकवनिक हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा गांधी इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "सेठ हरचरण दास गर्ल्स इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "आर.सी.ए. गर्ल्स इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "सुरजोबाई बालिका इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "सी.एल.आर.एन. सेकसरिया इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "आर.बी. इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "वी.सी. जे एच आर सेकेंडरी स्कूल हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज,हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "श्री राधे लाल आर्य इंटर कॉलेज एहन हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "मानिक चन्द्र इंटर कॉलेज लाडपुर हाथरस",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कंझारी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "पाथमिक विद्यालय निनानली जागीर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टोहर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छीना",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सोनेपुरा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बाबई",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरसेला",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरकुपुर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय दमरारा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुसमरिया",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शंकरपुर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "कन्या प्राथमिक विद्यालय बावली",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुठीद",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय विचौली",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नैनापुर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गायर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सालाबाद",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लौना",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सखजूरी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मिटारा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बम्हन मिनौरा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बजीदा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 ऐरी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कंझारी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 निनावली जागीर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कोड़ा किर्रही",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 लौना",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 खजुरी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 उसरगांव",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 छौक",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कुंआ खेड़ा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भेडी खुर्द",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 शिवनी बुजुर्ग कम्पो0",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 पचीपुरा कम्पो0",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 ईगुई कलां",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गाँधी इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0वी0 इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "सनातनधर्म इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "जनता विद्या मन्दिर इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "सर्वोदय इं0का0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "आचार्य नरेन्द्रदेव इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "आर्यकन्या इं0का0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "रा0वा0 इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मन्दिर इं0का0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "श्री विनायक एकेण्डमी इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "श्री महाकवि कालीदास उ0मा0वि0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी विवेकानन्द उ0मा0वि0 उरई।",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "एस0आर0 इं0का0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "सनातनधर्म वा0इं0का0 उरई",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हिम्मतपुर बदलापुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सिंगरामऊ बदलापुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "श्रीकृष्ण नगर बदलापुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय लालपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय महिमापुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय विवांमऊ",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दाउदपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रामपुर खुर्द",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट चौकीखुर्द",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सेटअपपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय शुदानीपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कुरानी",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गुरदौर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय उदयचंद्रपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आदर्श केराकत",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय शेखपुर अशरफपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय तिघरा",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खुटहन प्रथम",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय राजापुर देदारपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जमालपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नारायणपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय देवकाली",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "समग्र अहान",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कबीरपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पवारा",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गरियांव",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मयन्दीपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भन्नौर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "समग्र गोरापट्टी",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भद्रंवा",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालयबिथार",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय चौकीपिलखुवा",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सखैला",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कोठवार",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आरा",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज जफराबाद जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका प्रथम सी मछलीशहर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज मड़ियाहूं जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   पोरई कला जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल  सराय मोहुद्दीनपुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   डिहिया जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   सरायखानी जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   मैनुद्दीनपुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या  हाई स्कूल रामनगर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय राजकीय  हाई स्कूल   भकुरा जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय राजकीय  हाई स्कूल   कच्ची डीह जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज विद्यालय तियरा जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   बलरामपुर डोभी जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   माई जलालपुर जौनपुर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खरकासानी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हरपुरा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कैमाई",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मदरवास",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चुरारा-2",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सिजारी बुजुर्ग-2",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जनकपुर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रक्सा प्रथम",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बलौरा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खैलार प्रथम",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रसौई",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बम्हरौली",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 टांडा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भरोसा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 साजौनी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खजूरी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बांगरी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 लहरगिर्द",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 करगुवाँ",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अलीगोल",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चुरारा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 स्यावनी खुर्द",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रक्सा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सरवां",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बसोबई",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अमरौख",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 डी0सी0 तालपुरा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कोछांभॅावर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 गोरामछिया",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 गांधीनगर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रानीपुर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 घुराट",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सिगार",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चैकरी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रमपुरा",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज कटेरा",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज शाहजहांपुर मोठ",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज रक्सा",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "आर्यकन्या इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "बड़ागाँव इण्टर कॉलेज बड़ागाव",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श जनप्रिय इण्टर कॉलेज टहरौली",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "क्रिश्चियन इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "कस्तूरबा कन्या इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गुरूनानक खालसा इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "डा0 राजेन्द्र प्रसाद कन्या इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 कृष्ण चन्द्र शर्मा कन्या इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजर्षि पुरूषोत्तम दास टण्डन हा0से0स्कूल",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "लक्ष्मी व्यायाम मंदिर इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "शिक्षक उच्चतर माध्यमिक विद्यालय",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल हाफिज सिद्दकी इण्टर कॉलेज",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कछियापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अलीपुर जलेसर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गाजीपुर्वा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सराय दौलत",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 इन्दरगढ़ द्वितीय कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय छिबरामऊ",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय शरीफापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बेहेटा रामपुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 प्यारपुर्वा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अमरौली",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पड़ुआपुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मखाईपुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 झबरा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हरीपुर्वा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महसौनापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 ठठिया",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बद्धापुर्वा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "क0पा्र0वि0 अगौस",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 पट्टी कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नादेमऊ कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 हसेरन",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रेरीरामपुर कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 खरगपुर कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मानिकापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कछियापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जललाबाद",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जलालाबाद",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हैवतपुर कटरा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सौरिख",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रसूलाबाद",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सराय प्रयाग",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय इंदरगढ़",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जरिहापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय, अगौस",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय, खैरनगर",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज समधन कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "लाला श्याम लाल इण्टर कॉलेज कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "सेठ वासुदेव सहाय इ0 कॉ0 कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "बी0 डी0 तिवारी इ0 का0 जलालाबाद कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी बेधड़क हा0 से0 स्कूल नदसिया कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "महर्षि निशंक इ0 का0 नवादा कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "रसाल सिंह इ0 का0 महोई नौली कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "भारती वि0 इ0 कॉ0 कायमपुर सौरिख कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "श्री बाबू सिंह हा0 से0 स्कूल अरूहो कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "भारतीय शिक्षा सदन इ0 कॉ0 सिकन्दरपुर कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी इकरसानन्द इ0 कॉ0 तेराजाकेट कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "के0 एस0 हा0 से0 स्कूल मकरन्दनगर कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "बेनी माधौ अग्निहोत्री इ0 का0 ठठिया कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "कन्हैयालाल सरस्वती विद्या मन्दिर इ0 कॉ0 कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "महेन्द्र नीलम इ0 कॉ0 तिर्वा कन्नौज",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सुमेरपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पतारी",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रार्थने० सरका",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "पा०वि० गिल्किनपूता",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गहलिया",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि० भोगनीपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रेगवा",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बील्हापुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "पा०वि० करियापुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हलधरपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० राजपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पलावर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जवाहर नगर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० विकास नगर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० उसरी",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० विल्हा",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बहादुरपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चीतापुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० निभु",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बेनीपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय रोषनमऊ, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय अम्बरपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय इवघरा, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय लक्ष्मणपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय सहबाजपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय हसनापुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय बुडिया, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय ज्योतिश, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय तोड़ामोहम्मदपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय करौसा कानपुर, देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय पुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय आलमचन्द्रपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय मुक्तापुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय रहिनियापुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय अन्दाया, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "ग्राम विकास इण्टर कालेज बुधौली विकास खण्ड़ राजपुर,कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "राज इं0का0 सिकन्दरा, विकास खण्ड़ राजपुर कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "शिव सहाय इं0का0 कौरू फरहतपुर सन्दलपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "जन कल्याण इं0का0 उरसान, सन्दलपुर कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "गाॅधी विद्यालय इं0का0 झींझक कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "अन्तःपुरी इं0का0 सबलपुर,झींझक कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "विवेकानन्द इं0का0 पुखरायाॅ कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीकृश्ण औद्योगिक इं0का0 मोहम्मदपुर, मलासा कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "पटेल इं0का0 करौर, मलासा कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू इं0का0 गजनेर, सरवनखेड़ा कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "क्षेत्रीय इं0का0 फतेहपुर रोषनाई, सरवनखेडा, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "अकबरपुर इं0का0 अकबरपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "दयानन्द इं0का0 बाढ़ापुर, अकबरपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "बाघपुर इं0का0 बाघपुर, मैथा, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "ताराचन्द्र इं0का0 षिवली, मैथा, कानपुर देहात।",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा वि सुजातगंज",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि क0 कराची खाना",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 सदर बाजार",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 पटकापुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 हाता सवाई रिरह",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 क0 नौघडा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 कुलगाव",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 मोतीपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च मा0 वि0 क0 रूमा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च मा0 वि0 शेखपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 छतमरा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 टटिया भगवन्त",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कर्नलगंज",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय ग्वाल टोली",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय लाटूश रोड",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रामकृष्ण नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मॉडल प्रेमनगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय परेड",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय प्रेम नगर डिप्टी पढाव",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अनवरगज स्टेशन",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय परमपुरवा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय निराला नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या जूही",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या मछरिया",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नांवस्ता",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय लालपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अर्रा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय विनगाँ",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बर्रा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय विश्वबैंक बर्रा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय डिप्टीपड़ाव गुर्जनी",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय किदवई नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या किदवई नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जुगैयागंगापुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि बाबू पुरवा",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "खालसा इ०कालेज गोविन्द नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "मोहन विद्यामन्दिर इ0 कालेज गोविन्दनगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "सेन्ट फान्सिस जेवियर्स इ० कालेज कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "रामकृष्ण मिशन हा०से०स्कूल रामकृष्ण नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एम०ए‌म० अली मेमोरियल इ०का० बेकनगंज कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहरलाल नेहरू इ०का० कल्याणपुर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "काइचर्ज इण्टर कालेज मालरोड कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "हरजेन्दर नगर इ0 कालेज कानपुर नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "चिरंजी लाल राष्ट्रीय इ० का० किदवई नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीनागरजी इ०का० कृष्णानगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर्यकन्या इ0 कालेज गोविन्द नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "खालसा गर्ल्स इ०का० गोविन्द नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "कु0 उद्यान गर्ल्स इ०का०",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "डी०एच० डी० गर्ल्स इ०का० मकराबर्टगंज कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "ज्ञान भारती गर्ल्स इ०कालेज बिरहाना रोड कानपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चन्दवा",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 न0 भम्मा",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मुजफर नगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 न्यौली",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 मनिकापुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 मगंदपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 कुवंरपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बाजनगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हसनपुर अल्प",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बघेला पुख्ता",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खडेरी पुख्ता",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दतलाना",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कर्नेलगंज",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पाठकपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रामपुर पुख्ता",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बनूपुर पुख्ता",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0बघराई",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0न0रमसी",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0बढौला-1",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0बढ़ौला 2",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मुजरफर नगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बाजनगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 फतेहपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रामछितौनी",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रोशन नगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रामताल",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नगला ठकुरी",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरौना",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 दतलाना",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कादरबाडी",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 न0 दल",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरौदा",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 दुलीचन्द्रपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बहोरा",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 राजा रिजोला",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज कासगंज",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इं0का0 कासगंज",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज दरियावगंज पटियाली",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0 इं0 का0 पटियाली",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीन दयाल उपाध्याय राजकीय मा0 इं0 का0 थरा चीतरा अमांपुर",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपाध्याय राजकीय मा0 इं0 का0 लभेड गंजडुण्डवारा",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपाध्याय राजकीय मा0 इं0 का0 भरगैन पटियाली",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपाध्याय राजकीय मा0 इं0 का0 अलाउद्दीनपुर सहावर",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयालउपाध्याय राजकीय मा0 इं0 का0 टिम्बरपुरसोरों",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल नगला यादकरन अमांपुर",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 भिटौना",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बहोरा",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूलनमैनी",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कादरगंज खाम गंजडुण्डवारा",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल रसूलपुर अरौरा गंजडुण्डवारा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बड़ाहारी",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय तरमौरा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देवारी",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय डेढ़ावल",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कटारी",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जगतपुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गणेशपुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लोहारी",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बैरागीपुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दलेलगंज",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नानई का पुरा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मवई",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जमुनापुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पभोसा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय उमरावां",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नौबस्ता",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उमरावल",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दुर्गपुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नंदा का पुरा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "यू पी एस शीतला धाम",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कंथुवा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय ताजमल्लाहां",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1 - 8) सुल्तानपुर ख्वाजा कड़क",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) तारा का पुरा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) जलालपुर भारती",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) शेरगढ़",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) शेवफधा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय उमरावल",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चकपिनहा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) अकबराबाद",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रसूलपुर ब्यौर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) उजिहिनी खालसा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पलहाना कछार",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) कुरई उपरहार",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज कड़ा कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीन द0उ0रा0मा0 इण्टर कालेज केसारी कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "केजीबीवी राजकीय हाईस्कूल कसिया कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पल्हाना कछार कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल महेवाघाट कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल शेरगढ़ कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल राला कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल अमिरतापुर कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल ढोकसहा कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल अर्कामहावीर कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "महेश्वरी प्रसाद इण्टर कालेज आलमचन्द्र कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "बह्मचारी इण्टर कालेज तिल्हापुर कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "कौशाम्बी इण्टर कालेज कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "कृषक इण्टर कालेज हिनौता कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रणजीत पंडित इण्टर कालेज शहजातपुर कौशाम्बी",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल छितौनी कोप (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल छितौनीकोप मुशरटोली (1 से 8 कंपोजिट)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल उधौ छपारा नौकाटोला (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल दारागौली कदहवा टोला (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल हनुमानगंज मंदिर टोला (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "के जूनियर हाई स्कूल खड्डा (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल हनुमानगंज (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल चतुर छपारा (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल भुजौली बाजार (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल भेड़ी जंगल (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पिपरिया (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल जखनिया (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय साहबगंज (1 से 8 तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "यूपीएस सरयाखुर्द (1 से 8 कंपोजिट)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सियराहा परसौनी (1 से 8 कक्षा तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सुमही संत पट्टी (1 से 8 तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय तरया सुजान (1 से 8 तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय तरया सुन्दर (1 से 8 कक्षा तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गोपालपुर (1 से 8 तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जवाही दयाल (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल माधोपुर गौरी श्रीराम",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल दुदही",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल विजयपुर उत्तरपट्टी",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पिपरा जटामपुर",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सिसवा मठिया",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "के जूनियर हाई स्कूल मटिहनिया बुजुर्ग",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल साढ़ी खुर्द",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल शिवपुर बुजुर्ग",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कल्याण छापर",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल भावपुर",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सिसवा दीगर",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बाजू पट्टी",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल तिनबरदाहा",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सिसवा गोपाल",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल विशुनपुरा खैरहवा टोला",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श एग्लो इण्टर कालेज दुदही, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इण्टर कालेज धौरहरा, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज धुरिया, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "लाल बहादुर शास्त्री इण्टर कालेज सपही टडवा, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री शैल्य कुमार सिंह इण्टर कालेज दिलीपनगर, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "गोस्वामी तुलसीदास इ०का० पडरौना, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू इ०का० पटेहरवा, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजेन्द्र सिंह इ०का०लेहनी कुसुम्हा, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकुमार उ०मा०वि० कुबेरनाथ, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता उ०मा०वि० विजयपुर, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या उ०मा०वि० बसडीला पाण्डेय, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल राजकीय मॉडल इंटर कॉलेज",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल राजकीय मॉडल इंटर कॉलेज",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल राजकीय मॉडल इंटर कॉलेज",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ०का० मनिकौरा, कुशीनगर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0गूम",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 इच्छारामपुरवा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खगईपुरवा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रेहरियाकला",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्राय0वि0 जिगनिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रेहरियाकला",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सराॅय सूरत",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 शेरपुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0बेहजम",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0रतसिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कटैया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दाउदपुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0बिजुआ",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रेवतीपुरवा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हाफिजपुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रघुनाथपुर 1",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कस्ता कालोनी",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 सिसैया कलां",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रामबट्टी",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संविलयन वि0 धौरहरा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संविलयन वि0 समर्दा बदाल",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 डिहुआकलां",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "सं0वि0 लालजीपुरवा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 कटैया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 दाउदपुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 नौसरागुलरिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 मांझासुमाली",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "सं0वि0 गौढी",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "सं0वि0 सैनापुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 गूम",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 फूलबेहड बालक",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 श्रीनगर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संवि0वि0 इन्द्रानगर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संवि0वि0 लैलूननगर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संवि0वि0 महुरेना",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "धर्म सभा इण्टर कालेज,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "भगवान दीन आर्य कन्या इण्टर कालेज,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपाध्याय सरस्वती विद्या मंदिर इ0का0",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "पी0डी0 भारती इ0का0मोहम्मदी",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "बी0बी0एल0सी0का0 खम्हरिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, धौरहरा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 निघासन",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजा प्रताप विक्रम शाह इ0का0 सिंगाही।",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "द्वारिका प्रसाद गायत्री देवी इ0का0",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "बल्देव बैदिक इ0का0 पलिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "जिला पंचायत बालिका इ0का0 पलिया,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "के0एं0यू0के इण्टर कालेज, पड़रिया तुला",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "सावित्री देवी देशराज सिंह इण्टर कालेज,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "पब्लिक इण्टर कालेज, ईसानगर",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भदरौ",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय निनिर्या",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खौखर",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुचदोन",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कुमरौ",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय ममलार",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मलार",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मलार",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रुखवाहा कम्पोजिट",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गुनखुरू समद",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नवीन कुरौरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राइमरी जूनियर स्कूल खुशीपुरा पचोड़ा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नवीन बंदेसरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रामपुरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कंपोजिट 12वीं",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय व उच्च प्राथमिक विद्यालय बंदेशरा कंपोजिट 12वीं",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दौलता",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० खडोबरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० अमरपुर",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० खडोबरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० बसवां",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० नदनवारा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० रोड़ा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० खडोबरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० गोरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० पाचौनी",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० बम्हौरीकला",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू० हा०स्कू० खड़ेश",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० बुढवार",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा० स्कू० दावनी",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०ह 10 स्कू० मसौरा खुर्द",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० मसौरा खुर्द",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० करगन",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० दावनी",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० मनगुवां",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री शान्ति निकेतन इ०का० महरौनी",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सी०बी०गुप्त इ०का० महरौनी",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री मर्दन सिंह इ०का० तालबेहट",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का० तालबेहट",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री मदन मोहन मालवीय इ०का० गुढा",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सरस्वती मंदिर इ०का० मडावरा",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री बर्णी जैन इन्टर कालेज ललितपुर",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का० ललितपुर",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पी०एन० इ०का० ललितपुर",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ०का० ललितपुर",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रघुवीर सिंह नेता जी इ०का० बार",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ०का० बार",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री स्याद्वाद वर्णी इ०का० गदयाना",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री किसान इ०का० विरधा",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "बुन्देलखण्ड इ०का० जाखलौन",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ईश्वरी खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इरादत नगर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खरिकाई",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिल्लावां",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बाग मिरिया जुम्मा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किला मोहम्मद नगर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय आशियाना",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेहसा 2",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भक्ति खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गौरी-2",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमौसी-2",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सार्डिनी नगर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गहरू",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मक्का खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गंगादीन खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय स्कूटर इंडिया ",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसंत खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरदासी खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमराई गांवी",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तकरोही",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तकरोही प्रथम",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुण्डरी",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नया ऐशबाग",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गजीपुर बलराम",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खड़गपुर फरीदीनगर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय निशातगंज",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लौंगा खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पिपराघाट",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लमटिनियर का पुरवा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय केबिनेट गंज",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिकंदरपुर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोदियन पुरवा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मड़ियांव",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भेरी मंडी",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छितवापुर",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय सैनिक इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "अभिनव विद्यालय",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सब्या बंचिया",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुसहरी टोला",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मटियरवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिशुनपुरवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कमनाहा पुरन्दरपुर",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोमर टोला",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कम्नहवा नगवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तेलियाडीह",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बागापार 2",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामपुर बुजुर्ग",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तरकुलवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोटा मुकुन्द",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हनुमानगढ़िया",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महदेइया",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सेमरा सूर्यपुरा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जगरनाथपुर",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कौलाही",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रतनपुर 2",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घुघली 1",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पौहरिया",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0ववि0 विषखोप",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरवा खुर्द",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 केवलापुर खुर्द",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 परासखाड",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 महुआरी",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 धीवपीड",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 बारीगाव",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 बडहरामीर",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 बुढाडीह",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 कुआ चाफ",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 अमवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 सिंहपुर अयोध्या",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 मझौवा कम्पोजिट",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 धेतिहवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 दशरथपुर",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीन द0उ0राजकीय मा0 इण्टर कालेज कुआचाफ पनियरा",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीनदयाल उ0रा0मा0इ0क0 सहजनवा बाबू बृजमनगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 नौनिया निचलौल महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 सेमरा चन्द्रौली",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 मेदनीपुर महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बेलटिकरा",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 रामपुर चकिया",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 उटी खांस",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 सोहवल",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 वि0 मिर्जापुर पकडी महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 पुरैना खण्डी चैरा महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विश्वनाथपुर महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बजरहा",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बरडाड धानी",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 भगवानपुर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राश्यमिक विद्यालय लाड़पुर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खंदियाजैतपुर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अकौना",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राममिक विद्यालय लमोरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "नवीन प्राथमिक विद्यालय फुलपहाड",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक विद्यालय मंगरौलखुर्द",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खोई",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महुआबांध",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राममिक विद्यालय रगौलिया बुजुर्ग",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बचेवरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय थुरट",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बगरौन",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इमिलिया डांग",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुसरमा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नौसारा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बैहारी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसौठ",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बारी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ऐचाना",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पहरेता",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बलचैर संवि0",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चन्दौली",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गुढा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बैहारी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय आरी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय लाडपुर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सतारी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय सीगौन",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय सुंगिरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चन्द्रपुरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय प्रेमनगर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय लिलवां",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय तेइया",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय भूरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय बम्हौरी कुर्मिन",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती वी एम इंटर कॉलेज महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री अवधूतानंद हाईस्कूल अंदवारा विजयपुर महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "नरेन्द्र सिंह हाई स्कूल चरखारी महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "जी बी इस्लामिया इंटर कॉलेज कुलपहाड़ महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "स्वर्गीय एस.बी.एस.बालिका इंटर कॉलेज कबरई महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "एसटी जोसेफ्स हायर सेकेंडरी स्कूल महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मंदिर इंटर कॉलेज चरखारी",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "एस ए डी जी एस इंटर कॉलेज उटियां महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती बालिका विद्या मंदिर इंटर कॉलेज नरूपुरा रामकथा मार्ग महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "मानव कल्याण सेवा समिति इंटर कॉलेज नहदौरा माफ महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "पटेल एस.एस. इंटर कॉलेज ननौरा महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "बजरंग इंटर कॉलेज गदोखर धाम पनवारी महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सिद्धगोपाल रिछारिया इंटर कॉलेज जैतपुर महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री ब्रह्मचारी इंटर कॉलेज खन्ना महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "अटल ज्योति इंटर कॉलेज जैतपुर महोबा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 शिशु उधान",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गंज बालक",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 न्यू गाडीवान",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 ट्यूववेल नम्वर 2",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 देवी रोड",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खजुरिया",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नगला दुधी",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बरनाहल",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जैतपुर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 रसेमर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 सुजानपुर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 कुरावली",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 नगला मांझ",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 फतेहजंगपुर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 विघरई",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 महठौली",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 घिरोर प्रथम",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 गोधना",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लपगवां",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 कछपुरा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरनाहल",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 प्रहलादपुर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 तिलियानी",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मद्दापुर धर्म",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 परोंख",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 न0 भन्त",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भोजपुरा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भटानी",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 पडरिया",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 एलाऊ",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 रतनपुरबरा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 कछपुरा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 कुरावली",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 व0 महादेवा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 वि0 सोनई",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर काॅलेज मैनपुरी",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी सदानन्द राजकीय कन्या इ0का0",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "स्व0 रघुवीर सिंह यादव राजकीय इण्टर कालेज,",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "जैन इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "श्री नेहरू स्मारक, इण्टर कालेज,",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "मलिखान सिंह इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "अमर शहीद इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "कु0ॅं रामचन्द्र कन्या इण्टर कालेज,",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "सुदिती ग्लोबल एकेडमी",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय आँवला सुल्तानपुर",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरारी प्रथम",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेरी प्रथम",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भाहाई",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौकीपुरा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धाना तेजा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गढ़ी चुरमुरा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बबूरी",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाबूगढ़",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाद",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाजना",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाटी।।",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसई",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ऋषि संतान",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बालमुकुन्द",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ब्रजकुन्ज",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कमला नेहरू का",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० महर्षि वाल्मिक",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अकबरपुर-I",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय औढूटा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० बरौली",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गढी आशा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नीमगॉव",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नैनूकलों",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० लालपर",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० जौनाई",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० जुनसुटी",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "पृ०मा०वि० नगला सीता",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० धौली प्याऊ",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कोनकेरा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल नपुरिया",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पसौली",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भूरेका",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय ब्योहाई",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चिंतागढ़ी",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय लौहवन, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय सैनवा, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय भद्रवन, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय दघेंटा, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय भैंसा ,मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज बरौली मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज जैंत मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज करहारी मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज सिहाना मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज सौनई मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 बल्देव मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 परखम ,मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय भारतीय इ0का0 अड़ीग, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 फरह , मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0 विद्यालय बिवावली मांट, मथुरा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खुखुन्दवा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्र्रा0वि0 पारामुबारकपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जयसिंहपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भदसा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चेरूईया",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 बन्दीद्यााट",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भीरा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 गालिबपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बरहदपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कुतुबपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि भट्कुआपट्टी",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि0 हकिकतपुरा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि0 सुल्तानपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि0 मुशीपुरा(बालिका)",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 यूसुफपुरा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "क0वि0 धर्मपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 धर्मपुर देवारा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खैरा देवारा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 विनटोलिया",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "क0वि0 चक्कीमुसाडोेही",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बांदीकला",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल मोहम्मदाबाद",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय फ़तेहपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रानीपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हरदौली",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भदशा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गरथौली",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मुहम्मदपुर झझवा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हाजीपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय घोसी",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मधुवन",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय फतहपुर तलराटोया",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गोंथा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय खांडेरायपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गढ़ा",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "तालीमुद्दीन इंटर कॉलेज",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "बनदेवी इंटर कॉलेज",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय जामदरा",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय विद्यालय नदवल",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "बहा थानीदास आईसी अमिला",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "वेक्ट्री इंटर कॉलेज दोहरघाट",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "शहीद इंटर कॉलेज फ़तेहपुर",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय सुल्तानीपुर",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "पीडी दिनदयाल इंटर कॉलेज अइलख",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "टाउन इंटर कॉलेज मऊ",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय जनता इंटर कॉलेज",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "जैश किसान इंटर कॉलेज वनगावा",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज मुजार बुजुर्ग",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल माध्यमिक महाविद्यालय कटिहारी",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "पीडी दीन दयाल सेकेंडरी इंटर कॉलेज",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​बफावत",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​पबरसा",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​पनवारी",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​सुरानी",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​टांडी",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल मवाना खुर्द",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बटनौर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल गैडीना",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कुंडा",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सनुटा",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट हाजीपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जेएन.एच.एस. (कम्पोजिट) ​​खेड़ा बलरामपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जेएन.एच.एस. कम्पोजिट गगोले",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जेएन एच.एस. कम्पोजिट कायसटगावाड़ी",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​छाजूपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल औरंगाबाद",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल ज्ञानपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल मोरना",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "भवनपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जेएन एच.एस गर्ल्स इंचौली",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बस्तोरा नारंग",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल जलालपुर जोरा",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल किशोरपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल दूधली खादर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल हस्तिनापुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सिरजेपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल गणेशपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल करीमपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल रठौरा कलां",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल लतीफपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल राठौरा खुर्द",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल तारापुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल एदलपुर",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "जूनियर हाई स्कूल एम.पुर शिहेरा",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "जूनियर हाई स्कूल समसपुर",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "सी०एल०एम० इण्टर कालिज जानी, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालिज, कल्याणपुर, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "सेंट चार्ल्स इण्टर कालिज, सरधना, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "चौ० बशीर खाँ इण्टर कालेज, हर्रा, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालिज, लावड, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू स्मारक इण्टर कालिज, चितमाना शेरपुर",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "ए०एस० इण्टर कालिज, मवाना, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "चौ० प्रेमनाथ के०वी० इण्टर कालिज, माछरा मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालिज, हस्तिनापुर, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालिज, हस्तिनापुर, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "आचार्य नमि सागर जैन इण्टर कालिज, हस्तिनापुर, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल, राठौडा खुर्द, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल तरबियतपुर जनूबी मेरठ",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भेवर करमनपुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय अरगजा पांडे",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मुहकुचवा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय जिवती",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय शाहपुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय निफारा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सुरहन",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कुशहा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय महामलपुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मझौवा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय अघौली",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हरघर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय घमहापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय फुलियारी",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय पिडखिर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चेतगंज",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बामी",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "बसंत विद्यालय इंटर कॉलेज मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "जयहिंद विद्या मंदिर इंटर कॉलेज अहरौरा, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अधवार",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय ड्रमंडगंज",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हथेड़ा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय कौड़िया कला",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय तुलसी",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय देवरी पूरब",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रामपुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "बी.एल.जे. इंटर कॉलेज मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री शिवाजी नेशनल इंटर कॉलेज हासीपुर, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज विंध्याचल, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बदाउहां",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दुबारकला",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय रामपुर वासिद अली",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बिरोही",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बरौंधा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गदौली",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रजौहां",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अमोई",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजस्थान इंटर कॉलेज मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री माता प्रसाद माता भीख इंटर कॉलेज मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इंटर कॉलेज, बरेवां, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री बृजराज आदर्श इंटर कॉलेज चुअखड़ा, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भैसोदबले पहाड़",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चेतगंज (कन्या)",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय खैरा",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री स्वामी गोविंदाश्रम इंटर कॉलेज पैदापुर, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श बालिका इंटर कॉलेज, सीखड़, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "जनत जनार्दन इंटर कॉलेज भुड़कुड़ा, मीरजापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "छत्रपति शिवाजी इंटर कॉलेज खजुरौल, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज बरौधा, मिर्ज़ापुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या पी0 ए0 सी0 काशीराम नगर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कटार शहीद",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या मुगलपुरा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या तहसील",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय राजकीय फेजगंज",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दोग",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मझोला",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्र0 वि0 बढापुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कत्पोजिट विद्यालय राईभूड",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बलिया",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लालापुर पीपलसाना प्",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लालापुर पीपलसानसा प्प्",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 चकरपुर पूरनपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोेजिट विद्यालय सेेहल",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोेजिट विद्यालय बढुपुरा मझॅरा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 सिरसवा गौड",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बगरौआ",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 मिलक नगलिया जट",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मुस्तफापुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कुमरिया जुबला",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अहमदपुर आनन्दपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अलियाबाद",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मॅझरा आदमपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सलेम सराय",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कत्पोजिट विद्यालय ढकिया पीरू",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 काजीपुरा -प्प्",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्र0 वि0 पाकबडा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय डिडौरा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उत्तमपुर बहलोलपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मंगुपूरा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 मिलक बहलोलपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 पाकबडा -प्रथम",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्र0 वि0 मोरा मिलक",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मालीपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 भाण्डरी",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "महाराजा अग्रसेन इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "चित्रगुप्त इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "पारकर इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "आर0एन0 इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "एस0एस0 इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "हिन्दू मार्डन इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "जी0जी0 हिन्दू इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "फलाहे दारेन इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "मैथोडिस्ट गल्र्स इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "आर्य कन्या इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "रामचन्द्र शर्मा कन्या इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "कौसल्या कन्या इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "बल्देव आर्य कन्या इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "हैविट मुस्लिम इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "के जी के इंटर कॉलेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सलेमपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बुढाना-3",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शाहडब्बर-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नदीनपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नावला-3",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "पू०गा०वि० नावला",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जटमुझेडा",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पूर्वी पाठशाला",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० तितावी-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नीला महाराजनगर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० बहादरपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० कासमपुरा",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र० वि० जलालपुर नीला",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चरथावल-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाननगर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शाहपुर-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिसोली-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिसोली-2",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गर्दी बालक",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 भैंसी",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुरादपुरा",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मजलीसपुर तोफीर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय, शाहपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0विद्यालय गोकलपुर (1-8)",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय, खेड़ी तगान",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रायपुर नगली",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय, मुजाहिदपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बुढ़ाना",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० कि० भोपा",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र० वि० बिहारगड",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा० वि० स्याली",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र० वि० हुसैनपुर खादर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय, असदनगर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोट वि० बिसलरी",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो०वि० हैबतपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ०का०",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ०का०",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "डी०ए०वी० इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "दीपचन्द ग्रेनचैम्बर इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "जैन कन्या इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "वैदिक पुत्री कन्या इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "जयहिन्द इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "डी०ए०वी० इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "डी०ए०वी० इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "वे०के० जैन इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एस०डी० इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "मॉडर्न इण्टर कालेज",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बहरुआ",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परेवा वैश्य",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय फुलैया",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक स्कूल पिंजरा वामनपुरी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अजान",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बड़हरा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बैला डांडी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसेंगा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोपालपुर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हीरपुर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय आजमपुर बरखेरा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अखोला",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमराकरोद",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमृता खास",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भदरा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक स्कूल सरीफगानी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरोरी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गणेशपुर गोरिया",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक स्कूल बिठौरा कलां",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौड़ा खेड़ा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पिनिरा वामनपुरी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल परेवा वैश्य",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल रामनगर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल अधकटा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल रामपुर अमृत",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बढ़पुरा मरोरी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल लाडपुर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल अमृता खास",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पट्टी सियाबाड़ी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल उमरसाढ़",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल वाहनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल बिठौरा कलां",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल शेरपुर कला",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल शिवनगर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल टांडा गुलाबराय",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "ड्रमण्ड राजकीय इ०का०पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल बूदी भूड पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल मुरौना गौडी पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल खिडिकिया बरगदिया पीलीभीत।",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "सन्त रवि दास ज्ञानार्जन इ०का०पुरैना तालुके महाराजपुर पूरनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "लाल बहादुर शास्त्री इ०का० चदिया हजारा पूरनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "शारदा उ०मा०वि० कबीरगंज पूरनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा० इ०का०पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "आदित्य पब्लिक इ०का० कबीरगंज पूरनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "सनातन धर्म बाँके बिहारी राम इ०का० पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "अवन्तीबाई जिला पंचायत बालिका इ०का० पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "कुँवर झनकार सिहं इ०का०वरसिया वीसलपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "कुवर बहादुर इ०का० शहवाजपुर वीसलपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज गभिया सहराई पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "स०व०भ०प०इण्टर कालेज अमृताखास पीलीभीत",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0, कटैया नेवादा",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0, तिलौरी",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0, दादूपुर पड़ान",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0, देवली",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 पट्टी प्रथम",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 भवसरनपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 बसौली 1 से 8",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 नारंगपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 महदहा",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लखनीपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 पूरे रैकवारन",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बरौलिया",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 देवरी हरदोपट्टी",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 तिवारी महमदपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बेधनगोपालपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बलीपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लौवा",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बानेमऊ",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 चैकापारपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सुबरनी",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "सं0 वि0 सरौली",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "सं0वि0 परसरामपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 सरूआ",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "कन्या उ0प्रा0वि0 अन्तु दंहात",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 समापुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 सेतापुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "पू0 मा0 वि0 कस्बा लतीफपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 श्रीपुर चैहरजन",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "पू0 मा0 वि0 नेवादाकला",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 चैकापारपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 डेरवा",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 बसीरपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "पू0 मा0 वि0 भरतगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 गाबी महुआबन",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 मुस्लिम बस्ती खरगपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज शेखूपुर प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज शेखूपुर प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०विद्यालय देवरी हरदोपट्टी",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०विद्यालय कन्धई मधुपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०विद्यालय लोहंगपट्टी",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०विद्यालय रसूलपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज बरहदा प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "लोकमान्य तिलक इण्टर कालेज प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीराम बालिका इ०का० चिलबिला प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "पी०बी०इ०का० सिटी प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "अनन्त प्रसाद इ०का० भदोही प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "रामराज इण्टर का० पट्टी प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "ाीतला प्रसाद इ०का० गढ़वारा प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "साकेत गल्र्स इ०का० प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा० विद्यालय बसौली",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कनकपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पिपरी",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दांदरूपुर कक्षा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय घोघापुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय थरवई",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्राथमिक विद्यालय शिवगढ़-प्रथम",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय राजापुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अराजी उमापुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय करनाईपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र बलकरनपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र पुरखीपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र मऊदोस्तपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कनेहटी",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र ढोकरी",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र देवारा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र पवार",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय करेहा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय खेकसा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र सुजौना",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र घूरपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. शंकरगढ़",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. धनुपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. मऊआइमा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. मेजा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. सैदाबाद",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. हंडिया",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. होलागढ़",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. जसरा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. कौड़िहार",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. कोरॉव",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. उरवा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी.करछना",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. फूलपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. चाका",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. सोरांव",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज स्वरूपरानी प्रयागराज।",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "जगततारन गल्र्स इण्टर कालेज, प्रयागराज।",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "सेन्ट ऐंथोनी कान्वेंट गल्र्स इण्टर कालेज, प्रयागराज।",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "मेरिवाना मेकर गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज, कटरा प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "हिन्दू महिला इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "एंग्लो बंगाली इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "शिवचरणदास कन्हैयालाल इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "सेन्ट एन्थोनी गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "मेरी वानामेकर गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "डी0पी0 गल्र्स इण्टर कालेज प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "गुरु तेग बहादुर खालसा गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "जगत तारन गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "डी0पी0 गल्र्स इण्टर कालेज प्रयागराज",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लेटमुहा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बैखरा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अशर्फाबाद",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिधौना",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कौआडीह",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भौसी",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चितवनिया",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रायपुर नेरूवा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामपुर पदुमनाथ",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिवली",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जमुनिहा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राजापुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेला टेकई",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तौधकपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शेखवापुर 1",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लालगंज राजकीय",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मीठापुर बढैया",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तेलियानी",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उमरामऊ",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किटौली",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नरेन्द्रपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुन्दनगंज",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जलालपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भैरमपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोझरी",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सताॅव 2",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मलिकमऊ चैबारा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किलौली",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टाण्डा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पहंरावां",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देदौर 1",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देदौर 2",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सींकी सलीमपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सवनई",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेला गुसीसी",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इन्टर कालेज रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर राजकीय इन्टर कालेज सेमरी रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इन्टर कालेज अटौरा बुजुर्ग रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इन्टर कालेज डीह रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इन्टर कालेज हलोर रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज डीह रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज लालगंज रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज महराजगंज रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज देवानन्दपुर रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज अजीतपुर रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज सलोन रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीनदयाल उपा0मा0इ0कालेज हरीपुर निहस्था रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीनदयाल उपा0मा0इ0कालेज कुॅवरमऊ रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीनदयाल उपा0मा0इ0कालेज भीरा गोविन्दपुर रायबरेली",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सूरत सिहं पुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खौद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० प्रानपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अहरौला",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लालपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० केसरपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अहमद नगर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बरखेड़ा, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लश्करगंज शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मीरापुर, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० महेवा, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मोतीपुरा, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शेखुपुरा, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बेहटा, मिलक",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भैसौड़ी, मिलक",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० निस्वी, मिलक",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रहमतगंज-२",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मझरा बहादुर, स्वार",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अच्वास नगर, स्वार",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० धनौरा, स्वार",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० हकीमगंज",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जगतपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० केसरपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० आंगा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० शाहदरा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० मझरा घोसीपुरा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कूप",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गजरौला",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० पजाबा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० अशोकनगर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० रामनगर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० पसियापुरा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० घोसीपुरा पट्टी कलां",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० केथोला",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गंगापुर कदीम",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मुर्तजा इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय जुल्फिकार इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सुन्दर लाल इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "कन्या इण्टर कालेज, खारी कुआं, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "विद्या मन्दिर इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0वी0 इण्टर कालेज, विशारदनगर, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गुरूनानक इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, स्वार",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सर्वहितकारी इण्टर कालेज, मसवासी, स्वार, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, टाण्डा, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, टाण्डा बादली, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, धमौरा, मिलक, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रेशम प्यारी कन्या इण्टर कालेज, मिलक, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, शाहबाद, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालेज, शाहबाद, रामपुर।",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 वि0 आटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 धर्मपुर कुईया",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 मझावली",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 जैरोई हयातनगर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 नेहटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) धुरैटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) अतरासी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) काजीबहटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) सा0 शो0 मुन्जब्ता",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) लखनपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) रायपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 इटायलामाफी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 मदाला फततेपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 असमाोली",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 अकबरपुर गहरा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 शहबाजपुर कलाॅ",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 ऐचैडा कम्बोह",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सराय व्रहामण",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नगलिया देवी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 काशीपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि पर्तइे कायस्त",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मिर्जापुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुल्हैटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पाजिट विद्यालय बेहटा जय सिंह",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दिलगौरा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बहजोई देहात",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय चितनपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पाठकपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट भारतल सिरसी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट जालब सराय",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट हिसामपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट हज़रत नगर गढ़ी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट शरीफपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट शाहपुर चमारान",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "शहीद ग्रीश चंद कम्पोजिट विद्यालय",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "इण्टरमीडिएट काॅलेज बहजोई।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "एस0एम0 इ0 का0 चन्दौसी।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "षंकर भूषण षरण जनता इ0का0 सम्भल।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "बाबूराम सिंह इण्टर काॅलेज बबराला।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इ0का0 जुनावई।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "नानक चन्द्र आदर्ष इ0का0 चन्दौसी।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "चन्दौसी इण्टर काॅलेज चन्दौसी।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "एफ0आर0 इ0का0 चन्दौसी।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "बी0एम0जी0 इ0का0 चन्दौसी",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 लहरावन",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "चन्द्रपाल आर्य आदर्ष इ0का0 बहजोई",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय क0 इ0 का0 सम्भल",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "हिन्द इ0 का0 सम्भल।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 धीमर खेडी सम्भल।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "प0दी0द0उ0माॅ0राजकीय इ0का0 राजपुर।",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रसूलपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सरही",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सरौली प्राथमिक चहारूम",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हाड़ापार",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 विषयाहन्नू",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महुआडाबर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भिटिनी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रैनियाॅ",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तिल्ठा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सोन्डीहा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तामा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0सरौना खास",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 औटना",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 धरूई",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 एकडंगा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रक्शा कला",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महुआडाबर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भिटिनी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रैनियाॅ",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तिल्ठा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय विश्वनाथपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 देवरिया गंगा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय पचेठी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय हरदी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 बेलहरकला",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय मेंहदावल",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 विद्यालय नंदौर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय सोनाड़ी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0वि0 शानिचरा बाजार",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय सांथा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय कटया",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय बाधनगर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0वि0 सेमरियावां",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 नाथनगर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय महुली प्रथम",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 खलीलाबाद",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बघौली",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 धौरहरा",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 सालेहपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 महुली",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 जामडीह",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 सियाकटाई",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 करमाखान",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 उमिला",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 गिरधपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 गोविन्दगंज",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बभनौली",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 रमजगला",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 भगवानपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बनौली",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मीरापुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राइमरी स्कूल फेरू माजरा",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नारायणपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय समसपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सुल्तानपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नाथोडी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टोडरपुर भूखरी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गजदीनपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहपुर दाउद",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "पी.एस.मुकरमपुर उर्फ़ लालवाला",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नारायणपुर गुज्जर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पंचकुआ",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौरा केला",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहजहाँपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छबडी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मझाड़ी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बार्था कोरसी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेगिनाजार",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरजपुर सैय्यद",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ततारपुर खुर्द",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०दि० इस्माईलपुर मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० फन्दपुरी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०पा०वि० रणदेवा मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नाईनगली मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० नल्हेंडा मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कुराली मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खारीबांस मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० तिरपडी मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बसी कुण्डा कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०बि० कल्लरहेडी कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०ग्रा०वि० चाउपुरा कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खालिदपुर कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दौलतपुर कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० शकरपुर कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जानपुर माजरी कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दिगम्बर जैन कन्या इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गुरुनानक कन्या इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गौरी शंकर इन्द्रपाल इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रामकृष्ण योगाश्रम इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री जैन इण्टर कालेज, देवबन्द।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दिगम्बर जैन कन्या इण्टर कालेज, सरसावा।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री दिगम्बर जैन क030मा0 वि‌द्यालय, देवबन्द।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "आर्य कन्या इण्टर कालेज, गंगोह, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दिलीप सिंह राष्ट्रीय कन्या इण्टर कालेज, गंगोह सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "इण्ड्रस्टियल मुस्लिम गर्लस इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालेज, देवबन्द, सहारनपुर",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर हाईयर सेकन्डरी, सहारनपु",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री हिन्दू कन्या इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज कैलाशपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सकटिया",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय विक्रमपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भुदनपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खूबपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अचिंतपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय केसी नगला",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पकड़िया",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरुआरा",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय प्रह्लादपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुनिया 1",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुनिस 2",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पहरुआ कलां",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसंत नेगर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धीमरपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खानपुर औरंगाबाद",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय औरंगाबाद",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जंगलपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुदरिया",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बोझी",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नारायण नगर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मैगटोरा",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पेहना",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय टापर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय थाथरमई",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय आलमनगर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय धियारपुरा",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बझेड़ा",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय थिंगन",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दहेना",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भरथौली",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय समापुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हुल्लापुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मऊ एस.पी.एन",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मऊ खास",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पसिट स्कूल कोटा",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल नाहिल शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कुदैया मदनापुर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल खंडहर जलालाबाद शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पदेचा कांत शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल चिल्हौटा बंडा शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल शंकरपुर पितरहाई जैतीपुर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल नौगई तिलहर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल शाबाजनगर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल सादिकपुर मिर्ज़ापुर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल धन्यौरा ददरौल शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल सिंहपुर पनई बंडा शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल परसौना खलीलपुर निगोही शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल नौसाना खुदागंज शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल ररूआ बंडा शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल हथनी नगरिया कलां शाहजहाँपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0वि0 म्यान कस्बा",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0वि0 रजाक नगर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 सकौती",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 कबीरपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 दरगाहपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 नौनांगली",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 चैसाना",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 जसाला",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मखमूलपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कनियान",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ0प्रा0वि0 चढाव",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मलकपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ0प्रा0वि0 भनेडा",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ0प्रा0वि0 भारसी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मसावी (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चन्दैना माल (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0्रप्रा0वि0 ख्यावडी (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 गौसगढ (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मादलपुर कम्पोजिट",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 तितारसी कम्पोजिट",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 कुतुबगढ (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कुडाना",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भैंसवाल",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जंधेडी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट करौडी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 करौदा हाथी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट बुटराडा",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट काबडौत",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि बुच्चाखेडी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि मंडावर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि गन्द्राव",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि मवी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि पांवटी कलां",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि अलीपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि जगनपुर",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज इत्सत्तोपुर खुरगान",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज भर्ना उदद्वा",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या हाईस्कूल दथेडा",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल जैनपुर",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल टोडा",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकन्या हाईस्कूल बन्तीखेडा",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल बलवा शामली",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या हाईस्कूल गंगेल",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पावटी कला",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या हाईस्कूल टपराना",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "श्री तनातन धर्म संस्कृत नाव्यमिक विद्यालय लॉक",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "लाला नरसिहदास सरस्वती विद्या मन्दिर इ० क० कैराना",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती बालिका विद्या मन्दिर इण्टर कालिज शामली",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मन्दिर इण्टर कालेज, शामली",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सत्यनारायण इण्टर कालेज, शामली",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नत्थापुरवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट मझौवा सुमाल",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट पिपराहवा कोठी",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जयचंदपुर कटघरा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अकबरपुर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बहोरवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटही-बगही",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मसहा कला",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट मोतीपुर मसहा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रनियांपुर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट सोहेलवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कुथनी",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सम्भारपुरवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बरगदवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट रतनापुर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मोहरनियां",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० मोहरनियां",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लक्ष्मननगर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०पि० फतुहापुर प्रथम",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दमावां",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पटना खास",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बडका फत्तेपुर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जमुनहां",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट नासिरगंज",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट महरूमुर्तिहा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० काला",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बेलरी",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बनकटवा महोली",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० भलुहिया",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट पटना खरगौरा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "जू०हा० भिनगा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बन्दरहा दक्षिणी",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भिनगा -।",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट सत्तीचौरा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट राजापुर रानी",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिक इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "श्री अलक्षेन्द्र इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इ0का0 पटना",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "जगतजीत इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी विवेकानन्द इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "नीलम राजकीय बा0इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू स्मारक इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "श्री प्रेम शुक्ला इ0क0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "तपसी इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "शिवराजी इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "लाल बहादुर शास्त्री इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "श्री मेवालाल सियाराम पटवारी इ0का0",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरनी (τ.a.)",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बनचौरा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तुलसियापुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "जे.एच.एस. बर्डपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय असोगावा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ओदानाताल",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट प्राथमिक विद्यालय रेहरा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट प्राथमिक विद्यालय जनियाजोत",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्राथमिक विद्यालय लामुईताल",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाईस्कूल सुराही ताल",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल छितौनी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट प्राइमरी स्कूल बनकटा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किशुनपुरवा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रुडलापुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मिठावल अहातामाली",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसंतपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कथेला ग्रांट",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल रसूलपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाईस्कूल के. डुमरियागंज",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कॉम्पसाइट जूनियर हाई स्कूल बंगवा नानकार",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सीर मझरी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चैनिया",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल वीरपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल फत्तेपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल शाहपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल गौरडीह",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाईस्कूल होरीलापुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमरिया",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इटौवा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धुसावा राजा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल सजनी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चुरिहारी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छोरावर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल एनआईबीआई",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल लेडावा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0, नौगढ",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0, तेतरी बाजार सिद्धार्थनगर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0, बसौनी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0, कोड़रा ग्रान्ट",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0, टड़िया बाजार",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता विद्याा मन्दिर इ0का0 उदयराजगंज",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "मुस्लिम इ0का0 महदेइया",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर लाल ने0स्मा0इ0का0 नौगढ",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सिंहेश्वरी इ0का0 तेतरी बाजार",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जय किसान इ0का0 सकतपुर सनई",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "सिद्धार्थ शिक्षा निकेतन इ0का0 तेतरी बाजार",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा गौतम बुद्ध उ0मा0विद्यालय जोगिया",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "बुद्ध विद्या पीठ इ0का0 बर्डपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "तिलक इ0का0 बांसी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इ0का0 उसका बाजार",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लालपुर फॉर्म",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० शाहजादपु",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिपनापुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पीरकपुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुरोती",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० बरेती जलालपुर कम्पोजिट",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पु०पा०नि० टॉडा कली कम्पोजिट",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसुदहा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उण्प्रा०वि० बसुदहा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० आगगीरिया",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० देवरिया खुर्द",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गोधनी सरैया",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बजेहरा नवीन",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बजेहरा नवीन",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कम्हरिया कटेसर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो०वि० किस्तापुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० चिल्डिया",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लालूपुरवा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ताजपुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सलौली",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल अखरी",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल शुक्लनपुरवा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल कनरखी",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल अटोरा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल बगस्ती",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय रूखारा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय रूकनापुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय खालेपुरवा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय दाखिन",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय ढोलनापुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय नसीरपुर कम्पोजिट",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथनिक विद्यालय असईपुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गोलोक कोडर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रण्डाकोडर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, साण्या सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, बॉसुस सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, जनुना विसर्वो सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इण्टर कालेज, सरैया राजासाहब सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "काल्चिन इण्टर कालेज महमूदाबाद सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गाँधी विद्यालय इण्टर कालेज सिधौली सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज मछरेहटा सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "एम.डी. इण्टर कालेज मिश्रित सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज बीहटगौड़ सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "कृषक इण्टर कालेज, महोली सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "जे.एल.एम.डी.जे. इण्टर कालेज खैरावाद सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "पन्त इण्टर कालेज बम्भौरा सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "बिडला विद्या मंदिर इण्टर कालेज हरगाँव सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "सेवाश्रम इण्टर कालेज कॉजी कमालपुर ऐलिया सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "खेमकरन इण्टर कालेज लहरपुर सीतापुर",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बीड़र",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दुद्धी-2",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महुली",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कर्री",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 डुमडीहा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अरंगी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तरावां",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बिच्छी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 छपका",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कम्हारी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तेलगुड़वा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चेरोबस्ती",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पड़रीपान",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पकरी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बाड़ी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पिण्डारी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 किशुनपुरवा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बगिया",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बोधाडीह",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महिद्दीनपुर",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मंठहवा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कुसुम्हा-1",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भवानीगांव",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 केतार",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 निपनिया",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्हौरा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नगांव",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि0 चकया",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बलुआ",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नंदना",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 डूभा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सुरंगीटोला",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नवाटोला-1",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नवाटोला (पोखरा)",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रन्दहटोला",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० इ०का० डोहरी",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "पं० दीनदयाल उपा०रा०मा०इ०का० मुडिलाडीह",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय इ०का० सिरसिया ठकुराई खैरपुर",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय इ०का० अनपरा",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "पं०दीनदयाल उपा०रा०मा०इ०का० रामगढ़",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "पं० दीनदयाल उपा०रा०मा०इ०का० मेडरदह",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० हाईस्कूल चतरा",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० हाईस्कूल पइका",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० हाइस्कूल हिनौती",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल परसौना",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल पड़वनिया",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बालिका हाईस्कूल गोठानी",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल जुगैल",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल कन्हौरा",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० हाईस्कूल मुसौलिया",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पातीपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० महेशुआ",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ज्ञानीपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० केशापट्टी",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० संसारीपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हरीपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटघरपुर चैहान",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मगरसनकलां",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गोदाम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मेवपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चंदौली",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खरहा",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० धधुआ",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुढ़हा",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चांदपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कूरेभार प्रथम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० विझुरी",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुजेश",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गुफ्तारगंज प्रथम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कूरेभार",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ब्रह्मजीतपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट रवनिया पश्चिम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अलीगंज-1",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अलीगंज-2",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० इसरौली",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लक्ष्मणपुर प्रथम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पुरेटिवरान",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० राइबिगो प्रथम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जफरपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुड़िलाडीह",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दक्खिनगाव",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पुरे मठा",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० इसौली",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० द्वितीय",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अरवल",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गोपाल नारायण इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "स्लीम उच्चतर मा०वि०",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "त्रिलोचन शास्त्री इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दामोदर इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रणवीर राजकुमार इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "महाराणा प्रताप उत्तरी",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रामरती इंटर कॉलेज द्वारिकागंज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "जजीरादास शम्भुनाथ इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "हनुमत इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री विश्वनाथ इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीजसिंह इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सुभाष इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर लाल स्मारक इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "मधुसूदन इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सर्वोदय इंटर कॉलेज",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि टीकरगढ़ी",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सकरन",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० रायपुर बुजुर्ग",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०प्रा०वि० पाली",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०प्रा०वि० कुतुबद्दीन गढ़वा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ०पा०वि० ओसिया",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० मोहिद्दीनपुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हसनापुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०मा०वि० धौंरा हसनगंज",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बल्लापुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गहरपुरवा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भिखारीपुर पतसिया",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० बछौली",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शाहपुर तोंदा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आदमपुर फैजुल्लानग",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0उ0प्रा0वि० बजेहरा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०प्रा०वि० कुसुम्भी",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0उ0प्रा0वि० अजगैन",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मऊ",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भटियापुर बांगरमऊ",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पंजूपुरवा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दोस्ती नगर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० थाना प्रथम",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जगतपुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि० मनिकापुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बिसरामऊ",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० विद्यालय खलीलनगर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० बडादेव",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 रायपुर नेवादा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हफीजाबाद प्रथम",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० वंश खेड़ा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० छंगा खेडा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०प्रा०वि० भवानीगंज",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0वि० करदहा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो०उ0 प्रा0वि0 मौरावां आदर्श",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज नवाबगंज उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज चमरौली उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज सफीपुर उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा गांधी इण्टर कालेज सफीपुर उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "अटल बिहारी इण्टर कालेज उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "सुभाष इण्टर कालेज बांगरमऊ उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "हुबलाल इण्टर कालेज टेढ़ा उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रामनाथ इण्टर कालेज रूझई उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "त्रिवेणी काशी इण्टर कालेज बिहार, उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "गिरजाशंकर सरलादेवी इण्टर कालेज बिहार, उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "के०एन०पी०एन०इण्टर कालेज मौरांवा।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "यामलाल इण्टर कालेज नवाबगंज, उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मन्दिर इण्टर कालेज शुक्लागंज उन्नाव।",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कोटवा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल नवापुरा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल रसूलगढ़",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल उमारहा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल पूरनपट्टी",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल केशरीपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल केराकतपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कादीपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल महेशपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल महगांव",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल गंगापुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल शंशाहपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल दयापुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कापरफोरवान",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल बेलवरिया",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल शिवपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल पिसनहरिया",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल मल्टी स्टोरी",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल महमूरगंज",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कबीरचौरा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल दशाश्वमेध",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल ऑर्डेली बाजार",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कोतवाली",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल चौबेपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल चोलापुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल धरहरा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल गोसाईपुर पठखौली",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल उगापुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल बेसाहूपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल डीईईपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल दिल्ली विनायक",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल करधना",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल पिनराई",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल सामोग्रा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल रमईपट्टी",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "गोव क्वींस इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "प्रभु नारायण गोव इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "गवर्नमेंट जीआईआईआरएलएस इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज जक्खिनी",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय अभिनव इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "भारती शिक्षा मंदिर इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "थायोसोफिकल हायर सेकेंड्री स्कूल",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "सनातन धर्म इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "सीएम एंग्लो बंगाली कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "बंगलालीटोला इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "कमलाकर छत्तीसगढ़ आदर्श सेवा विद्यालय इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "उदय प्रताप इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "काशी कृषक इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "महाबोधि इंटर कॉलेज",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "अन्य",
  },
];

export const schools1 = [
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "गांधी इण्टर कालेज एरवाकटरा",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गजेन्द्र सिंह पब्लिक इ0का0 बिधूना",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्यामन्दिर इ0का0 दिबियापुर",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "वैदिक इ0का0 दिबियापुर",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "नगर पालिका इण्टर कालेज औरैया",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "जिला पंचायत इण्टर कालेज मुरादगंज",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "चै0 विशम्भर सिंह इ0का0 ओैरैया",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "तिलक इण्टर कालेज औरैया",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सुन्दर सिंह इ0का0 रामगढ़ हरचन्दपुर",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "नरायन इण्टर कालेज बडेरा",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज अजीतमल",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "जनसहयोगी इण्टर कालेज अमावता",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 आत्माराम मिश्रा इ0का0 क्योंटरा",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 बाबूराम पाण्डेय ऐकडमी औरेया रोड दिबियापुर",
  },
  {
    district: "औरैया",
    medium: "माध्यमिक शिक्षा",
    name: "अमर शहीद वीरागंना रामकली बाई उ0मा0वि0 अटसू ओरैया",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दर्शन नगर-2",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिव नाथपुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय टेंधा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सरायनासिर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नायपुरा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरदोइया",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज गौहनिया अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पी एस पिलखावां",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पी एस देवराकोट",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय राम नगर धौरहरा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय ददेरा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अकवारा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पुरा-1",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रसूलाबाद",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उदय",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरियावां",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय मुंगीशपुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय संदरी",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिरौंधापुर रुदौली",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अब्बुसराय",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बभनगावां",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भदरसा बहार",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "कन्या पूर्व माध्यमिक विद्यालय बीकापुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय लुत्फाबाद बचैली",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय चैरे बाजार",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय कोचा कम्पोजिट",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "मलेथू बुजुर्ग",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अटेसर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गाडौली",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मंझनपुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कटघरा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राम नगर अमावा सूफी",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जसरपुर",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पाराताजपुर प्रथम",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परवर तारा",
  },
  {
    district: "अयोध्या",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घुरेटा",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "तुलसी राजकीय बालिका इंटर कॉलेज",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज हैदरगंज अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज टोरो माफ़ी दरियागंज बीकापुर अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज अमानीगंज अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज सलोनिया अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज रुदौली अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज सौरी सोहावल अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पूरे नंदा तिवारी बीकापुर अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका उच्च विद्यालय गढ़ा मिल्कीपुर अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका उच्च विद्यालय हरदी माया अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय सराय सागर माया अयोध्या",
  },
  {
    district: "अयोध्या",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय वेनवा माया अयोध्या",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "गांधी गुरूकुल इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "गांधी इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "शिब्ली नेशनल इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0वी0 इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्री दुर्गाजी इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "शिक्ष्क इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "जय किसान इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "एस0के0पी0 इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्री राम राष्ट्रीय इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "मुबारकपुर इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्री अग्रसेन कन्या इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्री कृष्णा गीता राष्ट्रीय इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "इण्टरमीडिएट इण्टर कालेज",
  },
  {
    district: "आजमगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "सी0बी0 इण्टर कालेज",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0दहतोरा कम्पो0",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 न0 सोहनलाल कम्पो0",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 कैलाश कम्पो0",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 बाईपुर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 पथौली- 1",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 पथौली- 2",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बाईपुर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दहतोरा-1",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पथौली-1",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पथौली-2",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तोरा",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय म्यापुर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कलाल खेडिया",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बुढेरा",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कन्या बरौली अहीर-2 कम्पोजिट",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कु आखेडा कम्पोजिट",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय लकावली कम्पोजिट",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहगंज नं0 1",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय डिप्टी ग्याप्रसाद",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल (Kahrai)",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज शाहगंज आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रत्नमुनी जैन इण्टर कालेज आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "पं०दीन द०उ०रा०मा०इ0का0 मिढाकुर आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "पं०दीन द० उ०रा०मा०इ० का०मनकेडा आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "पं०दीन द० उ०रा०मा०इ० का०सैमरा आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कलाल खेरिया आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज नैनानाजाट आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज ऑवल खेडा आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीमती दानकुवरि इण्टर कालेज ऑवल खेडा आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "वैप्टिस्ट उ०मा०वि० सांई की तकिया आगरा।",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "बी0डी०जैन कन्या इण्टर कालेज आगरा",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "नगर निगम इण्टर कालेज ताजगंज आगरा",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "मुरारी लाल खत्री कन्या इण्टर कालेज आगरा",
  },
  {
    district: "आगरा",
    medium: "माध्यमिक शिक्षा",
    name: "सेन्ट जॉस कन्या इण्टर कालेज आगरा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कोड़रा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कोछोड़",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सुजानपुर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय साथनी",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दादों",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पिशावा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रामगढ़ पंजीपुर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कन्या प्राथमिक विद्यालय न0 12",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "बा0 पाठशाला न0 19,",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पुल नानऊ",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जरतौली",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गोसपुर",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "कमालपुर मरौठा",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बीठना",
  },
  {
    district: "अलीगढ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सिघारपुर",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "नौरंगीलाल राजकीय इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज, अतरौली, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर काॅलेज, नरौना, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "चिरंजीलाल बालिका इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "खैर इण्टर काॅलेज, खैर अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0वी0 इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "एस0एम0बी0 इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "धर्म समाज इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "उदय सिंह जैन कन्या इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "शिवदान सिंह इ0का0, इगलास, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "महेश्वर कन्या इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "रतन पे्रम डी0ए0वी0 इ0का0, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "बाबूलाल जैन इण्टर काॅलेज, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "के0एण्ड0 एस0आर0एम0वी0 इण्टर काॅलेज, अतरौली, अलीगढ़",
  },
  {
    district: "अलीगढ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज, कजरौठ, अलीगढ़",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अछती",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बुकिया",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मदुआना",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मसेना मिर्ज़ापुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर प्रथम",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर केवटाही",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय आराजी देवारा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय माझा कम्हरिया यथानाद्ध",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देवलर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोपालपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कमालपुर पिकर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय एआरएआई सेकंड",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अशरफपुर भुआ",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बड़ेपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बहापुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमदही",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अंजोरपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसहा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौदहपरास",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खालिसपुर गोदाम",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय अलावलपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बहलोलपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बनियानी",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय फ़तेहपुर न्यू",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बसोहरी",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय उमरावां",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय भिटौरा दक्षिणी",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्चप्राथमिक विद्यालय माझा कम्हरिया",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्चप्राथमिक विद्यालय आराजी देवारा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्चप्राथमिक विद्यालय सुरहुरपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय भगोला",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय मूसेपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय औसानपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्चप्राथमिक विद्यालय फ़तेहपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय मुबारकपुर बालक",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर वी पी इंटर कॉलेज विधुत नगर टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "मिश्री लाल आर्य कन्या इंटर कॉलेज टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श जनता इंटर कॉलेज टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "कौमी इंटर कॉलेज टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एच टी इंटर कॉलेज टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एस वी एफ आर बी इंटर कॉलेज कुर्की बाजार",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज अकबरपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बी इंटर कॉलेज इनामीपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज एन पी एन टांडा",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "नरेन्द्र देव इंटर कॉलेज जलालपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रांगे राघव प्रथम हंसवर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज जलालपुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज आलापुर",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज भीटी",
  },
  {
    district: "अम्बेडकर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर बी इंटर कॉलेज बेवाना",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमेठी 2",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरायखेमा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमेठी 1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेनीपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जयस 2",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मोहना",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "थाना भदैया महमूदपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उडवा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सवंगी",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धीमा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इस्माइलपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भेंवई",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घाटकौर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सेमरा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उसका",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भुसियावां",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पचेहरी",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय साड़ी हृदय शाह",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय माधवपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भटगवां.2",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पालपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "थाना दौलतपुर लोनहट",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गड़रिया डीह",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पलिया पश्चिम",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कटारी 1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बलभद्रपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "थाना अजबगढ़",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरौलिया.1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पिंडारा 1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय दारा1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "थाना अढ़नपुर1",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोचहित",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ठेंगहा",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तिवारीपुर",
  },
  {
    district: "अमेठी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जोगापुर",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "उमारमण राजकीय इ0का0 जामो अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीन दयाल उपाध्याय राजकीय माडल इ0का0 धरौली मुसाफिरखाना अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज शाहगढ अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज गौरीगंज अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज जामों अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज मुसाफिरखाना अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज सोनारीकला अमेठी।",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज इनहौना अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज राजाफत्तेपुर अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज फुरसतगंज अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज टीकरमाफी अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल हरिहरपुर अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कंजास अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल अमेठी",
  },
  {
    district: "अमेठी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल मंगरौली अमेठी",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 नौगावां सादात",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चैक",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 कैलसा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 जोया",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "कन्या उ0प्रा0वि0 धनौरा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 डियोटी",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 शिशोवाली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 कन्या बछरायूॅ",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 गजरौला",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 कुआ खेड़ा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 चकनवाला",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सिहाली जांगीर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 उझारी-1",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सैदनगली-1",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 कन्या लाल बाग",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 शोहरका नबावपुरा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 शेखूपुर झकड़ी",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 मौहम्मदाबाद",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सुल्तानठेर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 खुंगावली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सकतपुर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 रूखालू",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 आदमपुर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 बुरावली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 गंगाचोली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 जिवाई",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 अशरफपुर फेजगंज",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 आलमपुर कैंच",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 अल्लीपुर खादर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 डिडौली",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 इकौंदा",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 सुलतानपुर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 नन्हेड़ा अल्यारपुर",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 बोरवरी",
  },
  {
    district: "अमरोहा",
    medium: "बेसिक शिक्षा",
    name: "संवि0 बिजरा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज अमरोहा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "जे0एस0 हिन्दू इण्टर कालेज अमरोहा।",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "सिख इण्टर कालेज नारंगपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "भगवत शरण इण्टर कालिज, जोया",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "कृषक इण्टर कालेज कोठी खिदमतपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू मैमो0 इण्टर कालिज, ढवारसी",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "छतर सिंह सर्वेश देवी इण्टर कालेज मंगरौली हसनपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इण्टर कालेज ताहरपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "दयानन्द इण्टर का0, सैदनगली (इ0 वित्तविहीन) सैदनगली।",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालेज जब्दा,",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीराम किसान इण्टर कालेज बादशाहपुर",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज मूढ़ाखेड़ा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गांधी विधालय इण्टर कालिज, धनौरा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालेज धनौरा",
  },
  {
    district: "अमरोहा",
    medium: "माध्यमिक शिक्षा",
    name: "ज्ञान भारती इण्टर कालेज गजरौला",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० समरेर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० डेली",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० धौरेरा",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कल्यानपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भीकमपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अल्लापुर भोगी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अन्नी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बरायमई खेड़ा",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० घटूना",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नसरुल्लापुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मदनजुड़ी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पनौड़ी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पैगाभीकमपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लधेरा",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० संडोली",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० करखेड़ी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हर्रायपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रानेट",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गौसपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गिरधरपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० डरैला",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० चैन",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० भानपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० डोलापुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जरीफनगर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि०मचलई",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "संवि०वि० जखौरा जौहरपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कादरवाडी",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० म्याऊं",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सुखौरा",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खैरपुर खेराती",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उवप्रा०वि० गुरूपुरी विनायक",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खेडा जलालपुर",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दहेमू",
  },
  {
    district: "बदायू",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० टिकुरी",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीन दयाल उपा० राजकीय माडल इ०का० चौड़ेरा कादरचौक बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का० चौड़ेरा कादरचौक बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ० का० घटपुरी बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 यूसुफनगर बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ० का० बुर्रा अहिरवारा ;बोंदरीद्ध बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 सैदपुर बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 सहसवान बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 नसीरपुरटप्पा मलसई बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ०का० दातागंज बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 डहरपुर कलां बदायूँ विज्ञानानन्द रामनारायण वैदिक इ0का0 बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "सिंग्लर गर्ल्स इ0का0 बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "कुँवर रूकुम सिंह वैदिक इ0का0 नगला पूर्वी बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "श्री कृष्ण इ0का0 बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "हाफिज सिद्दीक इस्लामियाँ इ0का0 बदायूँ",
  },
  {
    district: "बदायू",
    medium: "माध्यमिक शिक्षा",
    name: "रामनारायण वैदिक इंटर कॉलेज",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तितरौदा नं01",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 तितरौदा",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 शाहजहापुर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भडल नं 02",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कान्हड",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पटटीबंजारान",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सन्तनगर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसी न0-1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसी न0-2",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसी न0-3",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्र्रा0 वि0 डौलचा- 1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्र्रा0 वि0 डौलचा- 2",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा वि कोताना -1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा वि कोताना -2",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय डौला",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय पुरामहादेव",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शबगा 1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शबगा- 2",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गौरीपुर जवाहरनगर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 गौरीपुर जवाहरनगर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सिसाना",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सिसाना कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 पाली कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खेडकी",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खुब्बीपुर",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 निवाडा",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 निवाडा",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नैथला",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नैथला",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सुल्तानपुर हटाना कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 निनाना- 1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बागपत कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कन्या बागपत कम्पोजिट",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 कण्डेंरा-1",
  },
  {
    district: "बागपत",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 कण्डेंरा-2",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज जहांगढ़ उर्फ ​​दौझा",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेजएबागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इंटर कॉलेज बावली बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इंटर कॉलेज बड़ौतए बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज दाहा बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज तुगाना बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय अहेड़ा बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय गौना बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च माध्यमिक विद्यालय सूपबागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय तिलपानी बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय मुबारिकपुर खेखड़ा बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय मवीकलां बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या उच्च विद्यालय फ़तेहपुर पुट्ठी बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय छोबली बागपत",
  },
  {
    district: "बागपत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय बसौद बागपत",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्रा0पं0 डीहा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्रा0पं0 बरौवा भदौली",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्रा0पं0 चिलवरिया",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्रा0पं0 अमीनपुर नगरौर",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "नाजिरपुरा बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्रा0पं0 बनकटा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्रा0पं0 अमदापुर",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्रा0पं0 शिवदहा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्रा0पं0 भदौली चक",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम नारायनपुर कला न्याय पंचायत पतरहिया वि0खं0 बलहा बहराइच पिन कोड 271865",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम पुरैना भवानी बक्श न्याय पंचायत बगहा वि0खं0 बलहा बहराइच पिन कोड 271865",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम महुली शेर खां न्याय पंचायत गुरघुटटा वि0खं0 बलहा बहराइच पिन कोड 271865",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम बढ़ैया कला न्याय पंचायत चन्दनपुर वि0खं0 बलहा बहराइच पिन कोड 271830",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "थैलिया",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "महसी",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "पूरे शिवरतन सिंह",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "गंगापुरवा",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "देवलाख कैसरगंज",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "परसेण्डी कैसरगंज",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "गाजीपुरवा रवेली कैसरगंज",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "पथरहिया बलहा बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कड़सर बेतौरा कैसरगबज ब्रह",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम मुकेरिया महसी बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम नौबना पोस्ट मझारा मिहिंपुरवा बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "सईदा बभनी रिसिया बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम बाबाबिहारी दास कुट्टी पोस्ट चौकशहर बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "सेमरा डोलकुवन विशेश्वरगंज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "कमोलियाखास चित्तौरा बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ऊपर मुकाम हुजूरपुर बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम व पोस्ट झालतारहार पयागपुर बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "शरदपारा फखरपुर बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "बेदनापुर तेजवापुर बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "ग्राम.अटवा तपेसिपाह जरवल बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "करीमगांव नवाबगंज बहराइच",
  },
  {
    district: "बहराइच",
    medium: "बेसिक शिक्षा",
    name: "चांदपुरा बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "गेंदघर बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "गेंदघर बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "इंदिरा स्टेडियम के पास बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "विकास भवन के पास सिविल लाइन बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "दीवानी कचेहरी के पास बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "पुलिस लाइन बहराइच के पास",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "डिगिहा चौराहा बहराइच के पास",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "आदिसासी अभियान विधुत वितरण के पास",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "डालमिया धर्मशाला के पास बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "चांदपुरा चौराहा बहराइच के पास",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "छावनी चौराहा बहराइच के पास",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "दरगाह सरीफ बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "के0 डी0 सी0 के पास बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "स्टीलगंज तालाब के पास बहराइच",
  },
  {
    district: "बहराइच",
    medium: "माध्यमिक शिक्षा",
    name: "चौक घंटाघर के पास बहराइच",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट जूनियर हाई स्कूल बैरिया",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट जूनियर हाई स्कूल तालिबपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट जूनियर हाई स्कूल धाही बैरिया",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बघनव",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बकवा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय चौकानी",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भरसौता",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल रुद्रपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भरसौता नीरूपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय करंबर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सुजौली",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय सवारा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय चिलकहर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक विद्यालय बलुआ",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बसरिकापुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय छटा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय सवरूपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय सिंहाचौर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक विद्यालय पियारिया",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सागरपाली क्रमांक 1",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय टोला बाजराय",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय केवरा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय बड़सरी",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय रजौली",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय चैबे छपरा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय टोला फतेहराय",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय शाहपुर अफगा",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय सोनाडीह",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय तुर्की",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय कोंथ",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय एकइल",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय अठिला",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय खरूआंव",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय जुड़नपुर",
  },
  {
    district: "बलिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय बछईपुर",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस एस एच एस एस हुसेनाबाद बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "पी एम एच एस स्कूल परसिया बिसौली बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस बी आर एच एल एच एस स्कूल छाता बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस के एन के एस इंटर कॉलेज छितौनी छत बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "नागेश्वरी देवी इंटर कॉलेज छेदी बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "ए एस एस के के इंटर कॉलेज नारायणगढ़ बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस एस एस एस एस एस इंटर कॉलेज बहुआरा बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "राम सिद्ध इंटर कॉलेज सोनवानी बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "विश्वनाथ तिवारी उ म वि नीरूपुर बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस पी बी एच एस एस सीताकुंड बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एच एस स्कूल जवही दियर बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "इंटर कॉलेज दिउली बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "एच एस स्कूल देवकली बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "आर आर आर बी इंटर कॉलेज आदर्श नगर सागरपाली बलिया",
  },
  {
    district: "बलिया",
    medium: "माध्यमिक शिक्षा",
    name: "श्री एस एन इंटर कॉलेज रतसर बलिया",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सहजौरा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सादुल्लाह नगर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मुबारकपुर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय अचलापुर घाट",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बसावन बनकट",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लालनगर विरदा प्रथम",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय कन्या उतरौला",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय महुआधनी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बनघुसरा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय शिवपुर ग्रण्ट",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "नगर पालिका जूनियर हाई स्कूल",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भोजपुर नवीन",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मध्यनगरी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गौसड़ी डीह",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मटेहना",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोरखी डीह",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रतनपुर सोनपुर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोहरगडडी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मझगवां",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धनखरपुर हाटी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जानकीनगर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "जमुनी कलां",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय विजुवा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय धर्मपुर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय हंसुवाडोल",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय मुजेहानी",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय रमई डीह",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भू जेहरा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कौवापुर",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रामपुर बगनहा",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आदर्श",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गुलवरिया",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खम्हरिया बाघा प्रथम",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पिपरी कोल्हुई",
  },
  {
    district: "बलरामपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० विद्यालय ढोया डाबर",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एमण् पीण् इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "बशंत लाल इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "भगवती आदर्श विद्यालय इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "डीण् एण् वीण् इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "स्वतंत्र भारत इंटर कॉलेज तुलसीपुर",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "लोकमान्य तिलक इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "फजले रहमानिया इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रामशंकर भारतीय इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "मोहनलाल रामलाल इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "भारतीय विद्यालय इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "बी पी एस इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "हाजी स्माईल इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "ए जी हाशमी इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज",
  },
  {
    district: "बलरामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय त्रिवेणी 2",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शुकुलकुवां",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परमपुरवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नादिरपुरवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मोहनपुरवा 2",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मवई बुजुर्ग 2",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक स्कूल लुकतारा 2",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खहरा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उटारवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिकलोडी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहपुरसानी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रानीपुर",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नाहर पुरवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नंदमऊ",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मझिवां सानी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पडेरी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जसपुरा 1",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चंदवारा 1",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक स्कूल. कानाखेड़ा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक स्कूल तंगामऊ",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गौरी कला",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय तग्राडेरा कम्पोजिट",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय काजीटोला",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बघैला",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय  महोखर 1",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चहितारा कम्पोजिट",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बेलापुरवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय  नंदनमऊ",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पछौहां",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय नारायणपुर",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बहेरी",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय साहेवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पदमाई",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जमुवा",
  },
  {
    district: "बाँदा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सैमरी",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज बांदा",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज बांदा",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज तिंदवारी",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज पैलानी",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज कालिंजर",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज मटौंध",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज अतर्रा",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज बबेरू",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज कमासिन",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "गांधी राजकीय इंटर कॉलेज ओरान",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज बिलगवां",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज पटेलगंज",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज बिसंडा",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज कोर्रही",
  },
  {
    district: "बाँदा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज करतल",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अरूई",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रसूलपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सैदनपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्राoविo मुर्तीपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० न्योछाना",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मोतीकपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अंडका",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अनवारी",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गुग्गौर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कुर्सी 2",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दरावां",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रावविवसैदापुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बड्डूपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खिझना 2",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० रघुनाथगंज",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मोहम्मदपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हुसैनाबाद",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० मंझर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट गौरा उस्मानपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० बिलौली",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जेठवनी वि०ख० बनीकोडर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गदियाए वि०ख० बंकी",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० अटवटमऊ वि०ख० देवा",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दरियाबादण्1ए वि०ख० दरियाबाद",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० फतेहपुर वि०ख० फतेहपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० हैदरगढए वि०ख० हैदरगढ",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सतरिख वि०ख० हरख",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बडागांव वि०ख० मसौली",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कुर्सी 1 वि०ख० निन्दूरा",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० कस्बा इचौली वि०ख० पूरेडलई",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० किला रामनगर वि०ख० रामनगर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कोठी वि०ख० सिद्धौर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सिरौलीगौसपुर वि०ख० सिरौलीगौसपुर",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सूरतगंजण्1 वि०ख० सूरतगंज",
  },
  {
    district: "बाराबंकी",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दहिला वि०ख० त्रिवेदीगंज",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज बेलहरा",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज सूरतगंज",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज सिरौली गौसपुर",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज निन्दूरा",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "फतेहचन्द्र जगदीशराय इं०का० सफदरगजं बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "पार्श्वनाथ दिगम्बर जैन इं०का० टिकैतनगर बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "लाल बहादुर शास्त्री इण्टर कालेज दरियाबाद",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल इण्टर कालेज फतेहपुर",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "एंग्लो इण्टर कालेज नवाबगंज अलियाबाद बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "पायनियर मांटेसरी इ० का० लखपेड़ाबाग बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "राम सेवक यादव स्मारक इ० का० बडेल बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सांई इंण्टर कालेज लखपेड़ाबाग बाराबंकी",
  },
  {
    district: "बाराबंकी",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती वि०मा०इं०का० केशवनगर बाराबंकी",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कैमुआ",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नवदिया घाढ़पुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बभियाना",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देवचरा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि० भुता",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि० सुनौरा मुरारपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि०शिवनगर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० इनायतपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नंदगाँव",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बल्लिया",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० परचवा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० फाजिलपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० औरंगाबाद",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अनन्दापुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बुखारपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चन्दपुर जोगियान",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मटियार",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० करगैनाण्प्रथन",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० घुरा राधवपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० करेलीण्प्रथम",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मुडिया जागीर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पिपरा नानकार",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय राठ",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चौपारा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अलीनगर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा०वि० सुरला",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा०वि० जादोंपुर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ० प्रा० वि० शिवनगर",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० खजुरिया सम्पत",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ० प्रा० वि० रजऊ",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० अंधरपुरा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नगरिया विक्रम",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जेड",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कठर्रा",
  },
  {
    district: "बरेली",
    medium: "बेसिक शिक्षा",
    name: "उवप्रा०वि० देवरनियों कन्याद्ध",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल उपाध्याय राजकीय मॉडल इण्टर कालेज चौबारी क्यारा बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल नन्दगाँव क्यारा बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "हवलदार अब्दुल हमीद इ०का० वाहनपुर बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "आर०पी० इ०का० मीरगंज बरेली।",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "जे०पी०एन० इ० का० नबावगंजए बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का०ए नबावगंज बरेली।",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल बल्लियों मीरगंज बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "यूनीक मॉडल इद्ध का० फतेहगंज पं० बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "दुर्गा प्रसाद मैमो हायर सकेन्द्री मीरापुर फतेंहगंजए बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "कुवैर ढाकन लाज़ इ०का० सहोड़ा मीरगंज बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "गाधी इण्टर कालेज शाही मीरगंज बरेली।",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल उपाध्याय राजकीय मॉडल इण्टर कालेज कचनेरा दमखोदा बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल शिवपुरी बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "सरदार भगत सिंह हाई स्कूलए राजपुर कलान बरेली",
  },
  {
    district: "बरेली",
    medium: "माध्यमिक शिक्षा",
    name: "शिक्षा निकेतन इ०का० चम्पतपुर बरेली",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक वि०",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय इंटर कॉलेज बस्ती",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "पंडित दीन दयाल उपाध्याय राजकीय मॉडल इंटर कॉलेज गोठवा",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "पंडित दीन दयाल उपाध्याय राजकीय मॉडल इंटर कॉलेज गोठवा श्रीगीनारी",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय कन्या इंटर कॉलेज कछिया",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय कन्या इंटर कॉलेज सोनबरसा",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर माध्यमिक कलवारी एहतमाली",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर माध्यमिक खखुवा",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: '"राजकीय उच्चतर मध्यम ',
  },
  {
    district: ' सिकटा"',
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर माध्यमिक बरहपुर",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "सिकटा उच्चतर माध्यमिक अजगौवा जंगल",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर मध्यम महुलानी",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "राजकीय उच्चतर माध्यमिक परसांव",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: '"जनता ',
  },
  {
    district: ' शिक्षा निकेतन इंटर कॉलेज ओडवारा"',
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "किसान इंटर कॉलेज भानपुर",
  },
  {
    district: "बस्ती",
    medium: "माध्यमिक",
    name: "कृषक इंटर कॉलेज कुरियार",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कस्तुरीपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 एकौनी",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मनापुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मोढ़ स्टेशन",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मोढ़डीह",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पट्टीबेजाव",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मनीगंज",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कौवापुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मँगुरा",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तेजसिंहपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकविद्यालय वीरभद्र पट्टी सेकंड",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकमिल्कीपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकविद्यालय हिम्मतपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकविद्यालय रामनगर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिकविद्यालय दानुपुर पूरब पट्टी",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० चकलाला",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० झौवा",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि० डभका",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिटवि० द्वारिकापुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिटवि० नरथुआ",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिटवि० लालानगर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "पू०मा० वि० लोकमनपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटेबना",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जेठूपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पूरेमिया खां",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 खेम्हईपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बभनौटी ।",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट चेतनीपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अर्जुनपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पिपरी",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सरैया",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 प्रजापतिपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सुल्तानापुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जयरामपुर",
  },
  {
    district: "भदोही",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 अजयपुर",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल बर्जी",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल लालीपुर",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल चकमन्धाता",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल महुआपुर",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल बारीपुर",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "श्री गणेश प्रसाद इ0 का0 बरवाँ",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "गुलाबधर मिश्र इ0का0 गोपीगंज",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "पं0 रामवंकल इ0का0 रोही",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "लक्ष्मण प्रसाद इ0का0 करियाव",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "महाबीर इ0का0 बिछिया बनकट",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "कालिका इ0का0 कोईरौना",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "डा0 लोहिया इ0का0 सागरपुर बवई",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "इण्टर कालेज दुर्गागंज",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "श्री काशीराज महाविद्यालय इ0का0 औराई",
  },
  {
    district: "भदोही",
    medium: "माध्यमिक",
    name: "श्री रामसजीवन लाल इ0 का0 खमरिया",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 इमरत पुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बनियावाला",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 साहा नगर कुराली",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय माधोवाला",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मुस्तफापुर तैय्यब",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मुस्तफापुर तैय्यब",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल वाजिदपुर बीरू",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल हकीमपुर मेढ़ा",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कशमीरी",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चकरपुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 फलोदा",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल नकीब पुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जसमौर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सेलाखेड़ी",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कुरीबांगर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बल्लानागला",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 छज्जूपुरा सादात",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "थाना जौनुलाबदीनपुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बालिका झालू",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नांगल जाट द्वितीय",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 नजीबाबाद",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हल्दौर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 स्वाहेड़ी",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सुवावाला",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नहटौर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 स्योहार द्वितीय",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गजरौला पाईमार प्रथम",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पिलाना",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सदाफल",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भटियाना",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पुरैनी",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 धौकलपुर",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 किवाड़",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गंगोड़ा जट",
  },
  {
    district: "बिजनौर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कुम्हारपुरा द्वितीय",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय आदर्श जनता इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय नवरत्न इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय विवेकानन्द विद्या मन्दिर इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "पंडित दीनदयाल उपाध्याय राजकीय माडल इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "पंडित दीनदयाल उपाध्याय राजकीय माडल इण्टर कालेज",
  },
  {
    district: "बिजनौर",
    medium: "माध्यमिक",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ऊँचागाँव खादर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा वि० नोजलपुर खादर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नित्यानन्दपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० उ०प्रा०वि० ग्रा०वि० रूठ बांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गोपालपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० उ०प्रा०वि० जोखाबाद",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० तिल की भवैया",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रजपुरा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शेरपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० राजारामपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० अप्रैल",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० उ०प्रा०वि० रमपुरा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० उ०प्रा०वि० मामनखुर्च",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुंडा खेड़ा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक स्कूल",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक स्कूल",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 गोकलपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रामघाट",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नरौरा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 फरीदाबांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चासीरसूलपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 थानागजरौला",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 स्याना",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सराय",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नहचैली",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 शेरपुरबांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 हसनपुरबांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सिरौराबांगर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 तिबडा",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 हजरतपुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 दरियापुर",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "भागीरथी वेदाग इ0का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री देवत्रय आदर्ष इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "इर्रिगेशन इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "इन्द्रजीत इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "इ0 गा0 क0 इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "पब्लिक इ0 का0-स्याना",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "भू0 स0 मै0 इ0 का0-स्याना",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "विवेकाननद इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "एल0 डी0 ए0 बी0 इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "जी0डी0ए0बी0इ0का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई0स्कूल",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "बुदसैन प्रेम चन्द्र इ0 का0",
  },
  {
    district: "बुलन्दशहर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री कृश्ण इ0 का0",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय खजहरा",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कसावद",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय तालसपुर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय ककरैत",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोरखहा",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जगदीशसराय",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय एम.कांशीराम आवास चंदौली",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिछिया-प्रथम",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिछिया कला",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय फुटिया",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इलिया-प्रथम",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कलानी",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तियारी",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेन",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गांधी नगर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नौगढ़ प्रथम",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अमृतपुर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जरहर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिकनी",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मझगांवा माझी बस्ती",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुंडा कनाला",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "यूपीएस कुंडा खुर्द",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुंडाखुर्द",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घुरहुपुर",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कम्हरिया",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खेतरपाला",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय महराजगंज",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चकिया",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिंघा",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नाडी द्वितीय",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गद्दोचक",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय प्रसहता",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमादपुर प्रथम",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पपरौल",
  },
  {
    district: "चन्दौली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पपरौल",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल चिठवनकला चकिया",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कुरहना चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कैडीहार चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल देवरीकला चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल लवारीकला नौगढ़ चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल गोलाबाद नौगढ़ चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल जमसोत नौगढ़ चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल उदितपुर मुर्रा चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल सोनवार चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "आर्दश जनता हा०से० स्कूल चतुरीपुर चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "बाल्मीकि इ0का0 बलुआ चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "सरवस्ती इ0का0 टाण्डा चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकुमार इ0का0 भुजना चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "धराव इ0का0 धराव चन्दौली",
  },
  {
    district: "चन्दौली",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल उपाध्याय उच्चतर मा०वि० मोदी नगर नियमताबाद",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गड़रियान पुरवा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमरपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नया पुरवा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बगुड़ा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मानिकपुर ग्रामीण",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भनभिया",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसिला",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बड़ी मड़ैयां",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कराही",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुरका",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अहिरानपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टिटौली",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोपा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जमीरा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छिवलहा 1",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरमबाबा का पुरवा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोलमाजरा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बंधी",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देउंधा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चकुंड",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चकजफर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय काशी 2",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कंठीपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सोनपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिवरामपुर 1",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक बड़ी मंडियां",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक दादरी माफ़ी",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व मध्यमक्लोधवारा",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक बारामाफी",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "उच्चतर प्राथमिक विद्यालय लक्ष्मीपुर",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "उच्चतर प्राथमिक विद्यालय अतरौली",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व मध्यम चंदाई",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक मुर्का",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "उच्चतर प्राथमिक विद्यालय कन्या छीबो",
  },
  {
    district: "चित्रकूट",
    medium: "बेसिक शिक्षा",
    name: "उच्चतर प्राथमिक विद्यालय कटिया खादर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज कर्वी",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गंगा प्रसाद जनसेवा इंटर कॉलेज कर्वी",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "सेठ राधा कृष्ण पोद्दार इंटर कॉलेज सीतापुर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजिकीय हाई  स्कूल  शिवरामपुर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजिकीय हाई  स्कूल रौली कल्याणपुर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "चित्रकोट इंटर कॉलेज कर्वी,चित्रकूट",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजिक्य हाईस्कूल भरतकूप",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "राजिकीय हाई  स्कूल नोनार",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "कृषक इंटर कॉलेज भौंरी",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "त्यागी इंटर कॉलेज ऐंचवारा",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श इंटर कॉलेज मानिकपुर",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "सुभाष इंटर कॉलेज इटवा",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "रतन नाथ इंटर कॉलेज रसिन",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "गोस्वामी इंटर कॉलेज छीबो चित्रकूट",
  },
  {
    district: "चित्रकूट",
    medium: "माध्यमिक शिक्षा",
    name: "एस. पालेश्वर नाथ इंटर कॉलेज पहाड़ी चि",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रजडीहा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नौतन",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पिपरा खेमकरण",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बनकटा चित्रसेन",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भदीला",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नवलपुर",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० इचौना बाजार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चकरवाँ बहोरदास",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भागलपुर 2",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टिकर",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महुई श्रीकांत",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भैदवा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परसिया देवार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेलदार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिरईगोड़ा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सेमरौना",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सत्तूआभार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खोपा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेलवा दुबौली",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हतवा बाजार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जयराम",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "क०पू०मा०वि० मस्जिद वार्ड",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कल्याणी",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० धनीती कला",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० फुलेहरा देसही देवरिया",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि०खडेसर बरहज",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० तिलौली",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० उसका",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खुड़िया मिश्रा",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खोराराम",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बागापार",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० हरखौली",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक वि० भड़सर",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बंजरिया",
  },
  {
    district: "देवरिया",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दुबौली",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "महाराजा अग्रसेन इण्टर कालेज देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "लाला करमचन्द्र थापर इण्टर कालेज, बरारी बैतालपुर देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "अशोक इण्टर कालेज रामपुर कारखाना-देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "चन्द्रशेखर अजाद इण्टर कालेज गौरी बाजार - देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "आचार्य नरेन्द्र देव इण्टर कालेज, पथरदेवा",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "महन्थ त्रिवेणी पर्वत इण्टर कालेज बरारी बैतालपुर देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "सुभाष इण्टर कालेज भटनी, देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "बाबा राघव दास कृषक इण्टर कालेज भाटपाररानी, देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "बापू इण्टर कालेज, सलेमपुर-देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "एस०डी० इण्टर कालेज, भाटपाररानी, देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "बब्बन सिंह इण्टर कालेज, रतसिया कोठी-देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "हर्ष चन्द्र इण्टर कालेज, बरहज देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "मदन मोहन मालवीय इण्टर कालेज भाटपाररानी, देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीकृष्ण इण्टर कालेज आश्रम बरहज- देवरिया",
  },
  {
    district: "देवरिया",
    medium: "माध्यमिक शिक्षा",
    name: "बाबा गायादास टेक्निकल इण्टर कालेज, बरहज-देवरिया",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुचदायम खा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरायअगहत",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बनी",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला उम्मेद",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कैला",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इमादपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सर्रा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला मोहन",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जरारी",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लालपुर जखा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खिरिया बनार",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ललहट",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ढीपा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सकतपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घुटलई",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुबारकपुर छछैना",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सैदपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बहलोलपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अंगदपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कन्या खरसुलिया",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 राजा का रामपुर पू0",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अल्लापुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बन्धा",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भदकी",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अहराई",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अहमदपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अमृतपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बड़ागाव",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बढ़ापुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 आसपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बक्सीपुर",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चपरई",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अयार",
  },
  {
    district: "एटा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बहलोलपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "अविनाशी सहाय आर्य इ0का0 एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "एम0जी0एच0एम0 इ0का0 मारहरा एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज कैल्ठा एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0बी0 इ0का0 अलीगंज एटा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "वि़द्या निकेतन इण्टर कालेज वाहनपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालेज जिन्हैरा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री किसान इण्टर कालेज धिरामई",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री के0 एस0 इण्टर कालेज हिम्मतपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "उल्फतराय इण्टर कालेज वसुन्धरा",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "लालबहादुर शास्त्री इण्टर कालेज कंगरौल",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "आर्दश इण्टर कालेज उम्मेदपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "एस0 वी0 एस0 इण्टर कालेज सकरौली",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "जन कल्याण इण्टर कालेज सिकरारी लालपुर",
  },
  {
    district: "एटा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री नेहरू स्मारक इण्टर कालेज जैथरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० दासीपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० दतावली",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० चितभवन",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० कृपालपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० बीना",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बरालोकपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० शेखूपुर सरैया",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० पचावली",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० रामनगर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० नगला छत्ते",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० बेलैयापुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० घरवार",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि०कम्पो० बसैयाहार",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि०कम्पो० करौंधी",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० खितौरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० मडैया दिलीप नगर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० ईश्वरीपुरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० मेंहदीपुरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० विरौंधी",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कम्पो० अकबरपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ० प्रा०विद्यालय लोहा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय बनामई",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा० विद्यालय महोला",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रादिद्यालय कम्पो० (नगर) जोर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय हनुमन्तपुरा",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ० प्रा० विद्यालय दनी केशीपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय आढरपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०ग्रा० विद्यालय कम्पो० बेसोलीघाट",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०दिद्यालय बिरारी",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय कम्पोत सलेमपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र० विद्यालय जोनई",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०ग्रा०विद्यालय सारंगपुर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा० विद्यालय धिगरई",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय यासीनगर",
  },
  {
    district: "इटावा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०विद्यालय अकबरपुर",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज कुण्डेशार",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "जन सहयोगी इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "गोपाल कन्हैया लाल इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "सर्वोदय इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "स्वतंत्र भारत इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "प्रहलाद स्मारक इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "बलवन्त शहीद इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीमती इन्दिरा गांधी इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "एस०ए०वी०इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "लोकमान्य रूरल इण्टर कालेज",
  },
  {
    district: "इटावा",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर इण्टर कालेज",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गरिया हैवतपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हुसैनपुर तराई",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नैगवां-द्वितीय",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पपरीखुर्द",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परतापुर तराई",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय  रामपुर जसु",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रोशनाबाद",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुइयां खेड़ा",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रठौरा",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हिसामपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरना बुजुर्ग",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कनकौली",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राजेपुर टप्पा मंडल",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मधवापुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय  फ़तेहपुरराव साहब",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गुलरिया",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोपालपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बहबालापुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अजमतपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय विजहादरपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरझाला",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रसीदपुर तराई",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भटपुरा",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रजीपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सकवाई",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 पिपरगाॅव",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अताईपुर जदीद",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 जिनौल",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 राजेपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बहादुरपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरौन",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 महरूपुर सहजू",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "संविलयन विद्यालय कक्यौली",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "संविलयन विद्यालय नौगांव",
  },
  {
    district: "फर्रूखाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0 विद्यालय उम्मरपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "प०जवाहर लाल इ०का० जरारी",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "चौधरी गजराज सिंह इ० का० टिलियां",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी रामप्रकाश आ० इ०का०तिराहा मुरहास",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "रूपकिशोर चतुर्वेदी इ० का० सिकंदरपुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "के०एस०आर० इ०का० कम्पिल",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इ0का0 रशीदाबाद तिवारीय",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "कर्नल ब्रह्मानंद इ० का० शुकरुल्लापुर",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजाराम इ० का० अचरा खलवारा नवाबगंज",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय वि०उ०मा०वि०आर्यननगर खलवारा",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहरलाल नेहरु उ०मा०वि० हुसैनपुर नवाबगंज",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "शास्त्री उ०मा०वि० चन्दुइया",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "सूर्य कुमारी इ०का० मंझना",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "श्री महिपाल शास्त्री इ०का०बबना",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी दीनबन्धु इ0का0",
  },
  {
    district: "फर्रूखाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "ए०वी०इण्टर कालेज",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कोराई द्वित्तीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० वाहिदपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० त्रिलोकीपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सहिली",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जगन्नाथपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सोनही",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कुसुम्भी द्वितीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रमवा द्वितीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० छिछनी",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० थरियावं प्रथम",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हसवां द्वितीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुरांव",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सुकेती",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गाजीपुर द्वितीय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मखदूमपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पैगम्बरपुर बकरी",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० झलहा",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पलवां",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० छिवलहा प्रथम",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गौसपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० आबूनगर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० आदर्श खेलदार",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नहमूदपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बक्सपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नरायनपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० चितौरा",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कासिमपुर बीबीहाट",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बिलन्दा प्रथम",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० त्रिलोकीपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० चखेडी",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गौरा चुरियारा",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० उदई संराय",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गोवर्धनपुर",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०कि० बेलाया",
  },
  {
    district: "फतेहपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र०वि० टकौली",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पं० दीन दयाल उपाध्याय राजकीय माडल इण्टर कालेज ऐलई",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०वि० अजमतपुर फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०वि० लालपुर दरौटा फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दयानन्द इंटर कालेज बिन्दकी",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "बहुआ इंण्टर कालेज बहुआ",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पं० दीन दयाल उपाध्याय राजकीय माडल इण्टर कालेज लतीफपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एच एन बहुगुणा इण्टर कालेज हुसेनगंज",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पं जवाहर लाल इण्टर कालेज खखरेरू",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०वि० टीकर फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का० फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पं० दीन दयाल उपाध्याय राजकीय माडल इण्टर कालेज अलादातपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सदाशिव इण्टर कालेज रेलबाजार फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "निरंकारी बालिका इण्टर कालेज फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "ए०एस० इंटर कालेज फतेहपुर",
  },
  {
    district: "फतेहपुर",
    medium: "माध्यमिक शिक्षा",
    name: "भारतीय विद्यालय हा०सें० स्कूल फतेहपुर",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि० सराय मटियारी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मिलावली",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० थरौआश्",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सुरेला",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नगला अकोला",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खैरगढ़ 1",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कुसुआमई",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बिल्टीगड 1",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० साथी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०कि नगला डरू",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कठफोरी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मदनपुर",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिरसाखास",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सरायमुरलीधर",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लखनई",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रूपसपुर 2",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सापुर 1",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नीम खेरिया",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नौशहरा",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० आररोज",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पुल खडीत",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कनवारा",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बिल्टीगढ",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भदान",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालयब दिवायची",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय विलहना",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय शंकरपुर",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय औरंगाबाद",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दारापुर मिलावली",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय ढोलपुरा",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बरतरा",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मौहम्मदी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बैजुआ खास",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कंथरी",
  },
  {
    district: "फिरोजाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय नगला सिंघी",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेज",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेज",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कॉलेज",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "फिरोजाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अट्टा 1",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अट्टा 2",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नया बासं",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हरौला",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मारेना",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय निठारी",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय छलेरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सदरपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० छलेरा 1",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सै0 12",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सै० 22",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गिझोड",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय होशियारपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यलाय आगाहपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० डाबरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० सादोपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० जोनसमाना",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० रानौलीलतीफपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० बीलअकबरपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० धूममानिकपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० रायपुर बाँगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० घोडीबछेडा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० चिटहरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० कटहरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० रामगढ",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० शादीपुर छिडौली",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नवादा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिलासपुरण् 2",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय समसपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय क्यामपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बल्ला की मढैया",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चुहड़पुर खादर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दौला रजपुरा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय परसौल",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय उस्मानपुर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज नोएडा गौतम बुद्ध नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इंटर कॉलेज होशियारपुर नोएडा गौतम बुद्ध नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल  छाजरसी जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल  शादीपुर चिडोली जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री दयानंद इंटर कॉलेज बम्बावर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गांधी आईसी गौरी बछेड़ा सेक्टर एमयू द्वितीय जीआर नोएडा",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "चौधरी केशराम आईसी हबीबपुर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय एस एस 1सी सदरपुर जीबी नगर ",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एस  एस  स्मारक किक शरफाबाद जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एस डी कन्या इंटर कॉलेज बिलासपुर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "मिहिर भोज गर्ल्स इंटर कॉलेज दादरी जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "विद्या देवी इंटर कॉलेज मकनपुर खादर गौतम बुद्ध नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "नोएडा कन्या इंटर कॉलेज भंगेल बेगमपुर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "बी डी आर डी एस वी एम इंटर कॉलेज दनकौर जीबी नगर",
  },
  {
    district: "गौतमबुद्ध नगर",
    medium: "माध्यमिक शिक्षा",
    name: "पी टी सालाग्राम इंटर कॉलेज हबीबपुर जीबी नगर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घूखना",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिहानी 2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिहानी 3",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिहानी 4",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिहानी गेट",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिरोरी-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गनोली",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय फतियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राजपुर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय निस्तोली-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नैपुरा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय यासीनगढ़ी",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जलालाबाद-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कनौजा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नूरपुर-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुशलिया",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भीकनपुर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शामली",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किन्हापुर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नगला मूसा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पट्टी",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमराला",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सारा-1",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट  विद्यालय जगतपुरी",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेगमाबाद-2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जोया",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मुरादनगर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय शहजादपुर",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सौंदा-2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरना",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ढिंडार नं. 2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भनेडा नं. 2",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुन्हैरा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय झलावा",
  },
  {
    district: "गाजियाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय विहंग",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "सेठ मुकन्द लाल इन्टर काॅलिज, गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "मनोहारी विद्यालय मन्दिर हा0 सै0 स्कूल गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "नगर पालिका बालिका इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "एम0एम0एच0 बालिका इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "सुशीला गल्र्स इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सनातन धर्म इन्टर काॅलिज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गुरूनानक कन्या इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "इन्ग्राहम इंटर कॉलेज राजनगर गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इन्टर काॅलिज विजयनगर गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "कन्या वैदिक इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज नन्दग्राम गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "जैनमति उजागर मैल जैन इंटर कॉलेज कविनगर गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "एम0एम0एच0वी0 इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "एन0एच0के0एम0 इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजियाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "संभू दयाल इंटर कॉलेज गाजियाबाद",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दिलदारनगर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गहमर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजि विद्यालय वरेजी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० आराजी ओड़ासन",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० देवकठिया",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सराय वन्दी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० देवकली.1",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मऊ पारा",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पियरी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० करण्डा.2",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सहेड़ी.1",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दीनापुर.1",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अगस्ता",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नूरपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चकदराय",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कनेरी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पवहारी कुटी",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सरदरपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हथौड़ा",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रावल",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पचोखर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० देवकली",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० रमवल",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० रसूलपुर हबीबुल्ला",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० मिर्जाबाद",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० उतराव",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कादीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय औड़िहार",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० सरदरपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हथौड़ा",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० पारा",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय वाजिदपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० रामपुर बलभद्र",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बद्दोपुर",
  },
  {
    district: "गाजीपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय धरवों",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गाधी इ०का० ढोटारी गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल इ०का० कासिमाबाद गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गाधी गेगोरियल इ०का० बहादुरगंज",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पी०एन० इ०का० मरदह गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श सेवा इ०का० नोनहरा गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "डा० एम० असारी इ०का० युसुफपुर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "इण्टर कालेज करण्डा गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "शिवकुमार शास्त्री इ०का० जंगीपुर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "इ०का० खालिसपुर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "केदारनाथ कृषक इ०का० उचौरी गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "टाउन नेशनल इ०का० सैदपुर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "हनुमान सिंह इ०का० देवकली",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "शहीद स्मारक इ०का० नन्दगंज गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गहमर इ०का० गहमर गाजीपुर",
  },
  {
    district: "गाजीपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीकृष्ण इण्टर कालेज डेढगांया गाजीपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दुल्लापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटरा लकड़मण्डी",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हनुमानगंज",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जहगीरवा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटरा शहबाजपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाबूराम पुरवा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पारस पुरवार डीह",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पिपरी रंहुआ",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भानपुरवा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० तपस्वीधाम",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नकहा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जोकही",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कादीपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पूरे गुलरिहा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बरदहीमऊ",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बिसुनापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बभनजोत",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० औराडीह",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दौलतपुर माफी",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लखनीपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० बेलहरी",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० ठाकुर दास पुरवा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० चन्दीपुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० बनघुरा",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० पूरे गजराज",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० मोहना",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० पण्डरीकृपाल",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० मल्लापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० छितौनी",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पों० वि० हथियागढ़",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० चन्दापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० बंजरिया",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० रामापुर",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० तिलका",
  },
  {
    district: "गोण्डा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० बड़गांव",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "गंगा प्रसाद मिश्री लाल इण्टर कालेज कौड़िया गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता उच्चतर माध्यमिक विद्यालय कौड़िया गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "संत सहजवन इण्टर कालेज बाबागंज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज इटियाथोक गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "महाराजा देवी बक्श सिंह इण्टर कालेज तरबगंज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "डी०ए०वी० इण्टर कालेज नबाबगंज",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "सुभाष इण्टर कोज उमरी बेगमगंज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज अमदही",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "कन्हैया लाल इण्टर कालेज कर्नलगंज",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इण्टर कालेज भभुआ गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "बालिका उ०मा०वि०कर्नलगंज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०वि० बस्ती गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "फ० अ० अ० राजकीय इण्टर कालेज गोण्डा",
  },
  {
    district: "गोण्डा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा० उ०मा०वि० रामपुर टेपरा गोण्डा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तिनकोनिया नं01",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 श्रीपुरा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भौवापार 1",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ०प्रा०वि० करही",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बकुलही",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सोनबरसा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 सोनवे",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 पथरा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अजायब टोला",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चनऊ",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बाघागाढ़ा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ०प्रा०वि० भरकच्छा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गाहासाड़ प्रथम",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 लहसडी 1",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 नौवा डुमरी 2",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 डोमहर",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जं0 औराही",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 छपिया",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ०प्रा०वि० डीहघाट",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 सेन्दुली बेन्दुली",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 बरबसपुर बिनहा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 सेमरौना",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "ए0डी0 राजकीय कन्या इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एल0पी0के0 इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 झरवा",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "महाराणा प्रताप इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अयोध्या प्रसाद",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 वसुधा",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० डीहघाट 1",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 डुहिया",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय जुबिली इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एम0एस0आई0 इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा गाँधी इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "मारवाड़ इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गाँधी शताब्दी इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बरौली",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बगही",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिंहोरवा",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "इमामबाड़ा मुस्लिम गल्र्स इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दयानन्द डी0ए0वी0 इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री भोला राम मस्कारा इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जं0 नन्दलाल",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भगवानपुर",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पकड़ीयार",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "एम0पी0पी0 आर्य कन्या इ0का0",
  },
  {
    district: "गोरखपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मंदिर इण्टर कालेज",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुंडेरा ठकुराई",
  },
  {
    district: "गोरखपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बड़हरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मेरापुर",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भोला डेरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुतुबपुर पटिया",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इन्द्रपुरी",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बरौली",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उच्च प्राथमिक विद्यालय मनकी कलों",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ० प्राथमिक विद्यालय मनकी खुर्द",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कलौलीतीर",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चन्दुलीतीर",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरदहा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पारा ओझी कम्पो0",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सुरौलीखुर्द",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भौरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बडा कछार",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बरूवा कम्पोजिट",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "क० प्राथमिक विद्यालय अरतरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिसोलर",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) भदुरी",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिहरका",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गुरदहा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8)",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "गुसियारी",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) रीवन",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "कन्या उ० प्राथमिक विद्यालय बहदीना अछपुरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोरनपुरवा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय औरा डेरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भटरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गल्हिामऊ",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुल्लूपाल का डेरा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "कन्या प्राथमिक विद्यालय बहगांव",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कुछेछा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नई बस्ती मलेहटा",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरि०ब० मझगवां",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरगांव",
  },
  {
    district: "हमीरपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरि०ब० लिधौरा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 बेरी",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पारा रैपुरा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कुछेछा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 कुनहेटा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 पाटनपुर",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल गुन्देला",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल धमना",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 गोहाण्ड",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल बिलगाॅव नवीन",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 बौखर",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 भेडी डाडा",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रा0कस्तुरबा गांधी बा0मा0वि0जरिया",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मा0वि0 चण्डौत",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री चित्रगुप्त इं0का0 राठ",
  },
  {
    district: "हमीरपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सरस्वती वि0मं0इं0का0 हमीरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खेड़ा-1",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 लालपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मिलक",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हसनपुर-1",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सिवाया",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दहीरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गांधी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 शिवगढ़ी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गणेश",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चमरी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गौंदी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सिरौधन",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अब्दुल्लापुर मोढ़ी-2",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गढ़-1",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 आलमगिरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मौ0 ररूस्तमपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कल्याणपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जनूपुरा-1",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भोगापुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बागडपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 आरिफपुर मढ़ैया",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नवादाकलां",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 फुलडेरा",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय लक्ष्मी",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आदर्श",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कपूरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 करनपुर जट्ट",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हिम्मतनगर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पूठाहुसैनपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कस्तूरबा",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अम्बेडकर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय राजकीय",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हैदरपुर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 गढ़मुक्तेश्वर",
  },
  {
    district: "हापुड",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नयाबांस",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज बडौदा हिन्दूवान हापुड",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज मुर्शदपुर हापुड",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श इण्टर कालेज गढ",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज अनूपपुर डिबाई",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "लाला बाबू बैजल मो०इण्टर०का० लोधीपुर",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "उदयप्रताप इ०का०सपनावत",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "मारवाड इ० का० पिलखुवा",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "बी०आर० इण्टर का० समाना",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "एस०एस०बी० इण्टर का० हापुड.",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "दीवान इण्टर कालेज हापुड़",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "श्री चण्डी विद्यालय इ०का०पिलखुवा",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "सवैहितैषी इ०का० मदस्याना",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "स्ववंत्रभारत इ०का०सलारपुर",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "आर्दश इ०का० मतनौरा",
  },
  {
    district: "हापुड",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज शेरपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महोनी",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खेतुई",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोढ़वा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय काशीपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुजाहिदपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रीठवाई",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महसोना",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय निगोहिया",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ढिकुन्नी",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भरावन 1",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हड़हा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक शाला सिलबारी",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय फरिगहना",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खदिया नगला",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अजमतनगर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मेउरा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय झाला पुरवा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोडरा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिशिताली",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जमुनिहारा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल नयागांव मुबारकपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बावन",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कासिमपुर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल लालपुर बजरा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल मुंडेर",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल यूटीआरए",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बालामऊ",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल शाहपुर गंगा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल इटारा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल लछनपुरवा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल मलैया",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल इंगवान",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बरहा",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल टंडियावां",
  },
  {
    district: "हरदोई",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल टोंडरपुर",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 हरदोई",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 टडियावां",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 हरदोई",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 सण्डीला",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 बिलग्राम",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 पाली",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 पिहानी",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपा0 रा0 माॅडल इ0का0 सिकरोहरी",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपा0 रा0 माॅडल इ0का0 खसरौल",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपा0 रा0 माॅडल इ0का0 जरौआ",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 टोडरपुर",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इ0का0 बेगमगंज",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 अर्जुनपुर",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 रौरा",
  },
  {
    district: "हरदोई",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 शिवसिंह पुरवा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय गंगाचौली",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय पुरा खुर्दा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कटैलिया",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय चन्द्रगढ़ी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय नूरपुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय जसराना",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय हनुमान चौकी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय सलेम पुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय सम्मामय रुहाल",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बसैकागी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय मुहारिया",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय छोड़ा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कोमारी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय राघनिया",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय ऊसावा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय निनामाय",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बासगोई",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बिजलीघर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बराह",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय अण्डौली",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय विष्णुपुरी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय पयंदापुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बासतोई",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बसई",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय सीछाबली सैनी",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय मीतई",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कोटा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय हतीषा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कुँवरपुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विधायला कपुरा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय कच्चापुरा",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय नगला भूषण",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय खिजरपुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय रतिभानपुर",
  },
  {
    district: "हाथरस",
    medium: "बेसिक शिक्षा",
    name: "संविलियन विद्यालय बारामाय",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "पी.बी.ए.एस. इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "श्री अक्रूर इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "डी.आर.बी. इंटर कॉलेज आगरा रोड हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीकुकवनिक हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा गांधी इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "सेठ हरचरण दास गर्ल्स इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "आर.सी.ए. गर्ल्स इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "सुरजोबाई बालिका इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "सी.एल.आर.एन. सेकसरिया इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "आर.बी. इंटर कॉलेज हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "वी.सी. जे एच आर सेकेंडरी स्कूल हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज,हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "श्री राधे लाल आर्य इंटर कॉलेज एहन हाथरस",
  },
  {
    district: "हाथरस",
    medium: "माध्यमिक शिक्षा",
    name: "मानिक चन्द्र इंटर कॉलेज लाडपुर हाथरस",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कंझारी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "पाथमिक विद्यालय निनानली जागीर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टोहर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छीना",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सोनेपुरा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बाबई",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरसेला",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरकुपुर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय दमरारा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुसमरिया",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शंकरपुर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "कन्या प्राथमिक विद्यालय बावली",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुठीद",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय विचौली",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नैनापुर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गायर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सालाबाद",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लौना",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सखजूरी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मिटारा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बम्हन मिनौरा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बजीदा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 ऐरी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कंझारी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 निनावली जागीर",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कोड़ा किर्रही",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 लौना",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 खजुरी",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 उसरगांव",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 छौक",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कुंआ खेड़ा",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भेडी खुर्द",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 शिवनी बुजुर्ग कम्पो0",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 पचीपुरा कम्पो0",
  },
  {
    district: "जालौन",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 ईगुई कलां",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गाँधी इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0वी0 इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "सनातनधर्म इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "जनता विद्या मन्दिर इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "सर्वोदय इं0का0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "आचार्य नरेन्द्रदेव इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "आर्यकन्या इं0का0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "रा0वा0 इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मन्दिर इं0का0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "श्री विनायक एकेण्डमी इंटर कॉलेज उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "श्री महाकवि कालीदास उ0मा0वि0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी विवेकानन्द उ0मा0वि0 उरई।",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "एस0आर0 इं0का0 उरई",
  },
  {
    district: "जालौन",
    medium: "माध्यमिक शिक्षा",
    name: "सनातनधर्म वा0इं0का0 उरई",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हिम्मतपुर बदलापुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सिंगरामऊ बदलापुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "श्रीकृष्ण नगर बदलापुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय लालपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय महिमापुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय विवांमऊ",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दाउदपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रामपुर खुर्द",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट चौकीखुर्द",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सेटअपपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय शुदानीपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कुरानी",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गुरदौर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय उदयचंद्रपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आदर्श केराकत",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय शेखपुर अशरफपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय तिघरा",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खुटहन प्रथम",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय राजापुर देदारपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जमालपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नारायणपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय देवकाली",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "समग्र अहान",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कबीरपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पवारा",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गरियांव",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मयन्दीपुर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भन्नौर",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "समग्र गोरापट्टी",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भद्रंवा",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालयबिथार",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय चौकीपिलखुवा",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सखैला",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कोठवार",
  },
  {
    district: "जौनपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आरा",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज जफराबाद जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका प्रथम सी मछलीशहर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज मड़ियाहूं जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   पोरई कला जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल  सराय मोहुद्दीनपुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   डिहिया जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   सरायखानी जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   मैनुद्दीनपुर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या  हाई स्कूल रामनगर जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय राजकीय  हाई स्कूल   भकुरा जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय राजकीय  हाई स्कूल   कच्ची डीह जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज विद्यालय तियरा जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   बलरामपुर डोभी जौनपुर",
  },
  {
    district: "जौनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय  हाई स्कूल   माई जलालपुर जौनपुर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खरकासानी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हरपुरा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कैमाई",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मदरवास",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चुरारा-2",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सिजारी बुजुर्ग-2",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जनकपुर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रक्सा प्रथम",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बलौरा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खैलार प्रथम",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रसौई",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बम्हरौली",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 टांडा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भरोसा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 साजौनी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खजूरी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बांगरी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 लहरगिर्द",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 करगुवाँ",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अलीगोल",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चुरारा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 स्यावनी खुर्द",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रक्सा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सरवां",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बसोबई",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 अमरौख",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 डी0सी0 तालपुरा",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कोछांभॅावर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 गोरामछिया",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 गांधीनगर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रानीपुर",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 घुराट",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 सिगार",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चैकरी",
  },
  {
    district: "झाँसी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रमपुरा",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज कटेरा",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज शाहजहांपुर मोठ",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज रक्सा",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "आर्यकन्या इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "बड़ागाँव इण्टर कॉलेज बड़ागाव",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श जनप्रिय इण्टर कॉलेज टहरौली",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "क्रिश्चियन इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "कस्तूरबा कन्या इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गुरूनानक खालसा इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "डा0 राजेन्द्र प्रसाद कन्या इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 कृष्ण चन्द्र शर्मा कन्या इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजर्षि पुरूषोत्तम दास टण्डन हा0से0स्कूल",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "लक्ष्मी व्यायाम मंदिर इण्टर कॉलेज",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "शिक्षक उच्चतर माध्यमिक विद्यालय",
  },
  {
    district: "झाँसी",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल हाफिज सिद्दकी इण्टर कॉलेज",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कछियापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अलीपुर जलेसर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गाजीपुर्वा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सराय दौलत",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 इन्दरगढ़ द्वितीय कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय छिबरामऊ",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय शरीफापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बेहेटा रामपुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 प्यारपुर्वा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अमरौली",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पड़ुआपुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मखाईपुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 झबरा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हरीपुर्वा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महसौनापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 ठठिया",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बद्धापुर्वा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "क0पा्र0वि0 अगौस",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 पट्टी कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नादेमऊ कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 हसेरन",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रेरीरामपुर कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 खरगपुर कम्पोजिट",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मानिकापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कछियापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जललाबाद",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जलालाबाद",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हैवतपुर कटरा",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सौरिख",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रसूलाबाद",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सराय प्रयाग",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय इंदरगढ़",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जरिहापुर",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय, अगौस",
  },
  {
    district: "कन्नौज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय, खैरनगर",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज समधन कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "लाला श्याम लाल इण्टर कॉलेज कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "सेठ वासुदेव सहाय इ0 कॉ0 कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "बी0 डी0 तिवारी इ0 का0 जलालाबाद कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी बेधड़क हा0 से0 स्कूल नदसिया कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "महर्षि निशंक इ0 का0 नवादा कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "रसाल सिंह इ0 का0 महोई नौली कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "भारती वि0 इ0 कॉ0 कायमपुर सौरिख कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "श्री बाबू सिंह हा0 से0 स्कूल अरूहो कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "भारतीय शिक्षा सदन इ0 कॉ0 सिकन्दरपुर कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी इकरसानन्द इ0 कॉ0 तेराजाकेट कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "के0 एस0 हा0 से0 स्कूल मकरन्दनगर कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "बेनी माधौ अग्निहोत्री इ0 का0 ठठिया कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "कन्हैयालाल सरस्वती विद्या मन्दिर इ0 कॉ0 कन्नौज",
  },
  {
    district: "कन्नौज",
    medium: "माध्यमिक शिक्षा",
    name: "महेन्द्र नीलम इ0 कॉ0 तिर्वा कन्नौज",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सुमेरपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पतारी",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रार्थने० सरका",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "पा०वि० गिल्किनपूता",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गहलिया",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा० वि० भोगनीपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रेगवा",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बील्हापुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "पा०वि० करियापुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हलधरपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० राजपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पलावर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जवाहर नगर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० विकास नगर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० उसरी",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० विल्हा",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बहादुरपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चीतापुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० निभु",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बेनीपुर",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय रोषनमऊ, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय अम्बरपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय इवघरा, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय लक्ष्मणपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय सहबाजपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय हसनापुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय बुडिया, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय ज्योतिश, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय तोड़ामोहम्मदपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय करौसा कानपुर, देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय पुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय आलमचन्द्रपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय मुक्तापुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय रहिनियापुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0 विद्यालय अन्दाया, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "ग्राम विकास इण्टर कालेज बुधौली विकास खण्ड़ राजपुर,कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "राज इं0का0 सिकन्दरा, विकास खण्ड़ राजपुर कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "शिव सहाय इं0का0 कौरू फरहतपुर सन्दलपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "जन कल्याण इं0का0 उरसान, सन्दलपुर कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "गाॅधी विद्यालय इं0का0 झींझक कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "अन्तःपुरी इं0का0 सबलपुर,झींझक कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "विवेकानन्द इं0का0 पुखरायाॅ कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीकृश्ण औद्योगिक इं0का0 मोहम्मदपुर, मलासा कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "पटेल इं0का0 करौर, मलासा कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू इं0का0 गजनेर, सरवनखेड़ा कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "क्षेत्रीय इं0का0 फतेहपुर रोषनाई, सरवनखेडा, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "अकबरपुर इं0का0 अकबरपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "दयानन्द इं0का0 बाढ़ापुर, अकबरपुर, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "बाघपुर इं0का0 बाघपुर, मैथा, कानपुर देहात",
  },
  {
    district: "कानपुर देहात",
    medium: "माध्यमिक शिक्षा",
    name: "ताराचन्द्र इं0का0 षिवली, मैथा, कानपुर देहात।",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा वि सुजातगंज",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि क0 कराची खाना",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 सदर बाजार",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 पटकापुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 हाता सवाई रिरह",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 क0 नौघडा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 कुलगाव",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 मोतीपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च मा0 वि0 क0 रूमा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च मा0 वि0 शेखपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 छतमरा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 वि0 टटिया भगवन्त",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कर्नलगंज",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय ग्वाल टोली",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय लाटूश रोड",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रामकृष्ण नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मॉडल प्रेमनगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय परेड",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय प्रेम नगर डिप्टी पढाव",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अनवरगज स्टेशन",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय परमपुरवा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय निराला नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या जूही",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या मछरिया",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नांवस्ता",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय लालपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अर्रा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय विनगाँ",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बर्रा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय विश्वबैंक बर्रा",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय डिप्टीपड़ाव गुर्जनी",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय किदवई नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या किदवई नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जुगैयागंगापुर",
  },
  {
    district: "कानपुर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि बाबू पुरवा",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "खालसा इ०कालेज गोविन्द नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "मोहन विद्यामन्दिर इ0 कालेज गोविन्दनगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "सेन्ट फान्सिस जेवियर्स इ० कालेज कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "रामकृष्ण मिशन हा०से०स्कूल रामकृष्ण नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एम०ए‌म० अली मेमोरियल इ०का० बेकनगंज कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहरलाल नेहरू इ०का० कल्याणपुर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "काइचर्ज इण्टर कालेज मालरोड कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "हरजेन्दर नगर इ0 कालेज कानपुर नगर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "चिरंजी लाल राष्ट्रीय इ० का० किदवई नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीनागरजी इ०का० कृष्णानगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "आर्यकन्या इ0 कालेज गोविन्द नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "खालसा गर्ल्स इ०का० गोविन्द नगर कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "कु0 उद्यान गर्ल्स इ०का०",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "डी०एच० डी० गर्ल्स इ०का० मकराबर्टगंज कानपुर",
  },
  {
    district: "कानपुर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "ज्ञान भारती गर्ल्स इ०कालेज बिरहाना रोड कानपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चन्दवा",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 न0 भम्मा",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मुजफर नगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 न्यौली",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 मनिकापुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 मगंदपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 कुवंरपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बाजनगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हसनपुर अल्प",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बघेला पुख्ता",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खडेरी पुख्ता",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दतलाना",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कर्नेलगंज",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पाठकपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रामपुर पुख्ता",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बनूपुर पुख्ता",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0बघराई",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0न0रमसी",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0बढौला-1",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0बढ़ौला 2",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मुजरफर नगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बाजनगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 फतेहपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रामछितौनी",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रोशन नगर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 रामताल",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 नगला ठकुरी",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरौना",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 दतलाना",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कादरबाडी",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 न0 दल",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरौदा",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 दुलीचन्द्रपुर",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बहोरा",
  },
  {
    district: "कासगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 राजा रिजोला",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज कासगंज",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0इं0का0 कासगंज",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कॉलेज दरियावगंज पटियाली",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा0 इं0 का0 पटियाली",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीन दयाल उपाध्याय राजकीय मा0 इं0 का0 थरा चीतरा अमांपुर",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपाध्याय राजकीय मा0 इं0 का0 लभेड गंजडुण्डवारा",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपाध्याय राजकीय मा0 इं0 का0 भरगैन पटियाली",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपाध्याय राजकीय मा0 इं0 का0 अलाउद्दीनपुर सहावर",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयालउपाध्याय राजकीय मा0 इं0 का0 टिम्बरपुरसोरों",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल नगला यादकरन अमांपुर",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 भिटौना",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बहोरा",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूलनमैनी",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कादरगंज खाम गंजडुण्डवारा",
  },
  {
    district: "कासगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल रसूलपुर अरौरा गंजडुण्डवारा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बड़ाहारी",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय तरमौरा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देवारी",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय डेढ़ावल",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कटारी",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जगतपुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गणेशपुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लोहारी",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बैरागीपुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दलेलगंज",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नानई का पुरा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मवई",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय जमुनापुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामनगर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पभोसा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय उमरावां",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नौबस्ता",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उमरावल",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दुर्गपुर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नंदा का पुरा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "यू पी एस शीतला धाम",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कंथुवा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय ताजमल्लाहां",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1 - 8) सुल्तानपुर ख्वाजा कड़क",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) तारा का पुरा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) जलालपुर भारती",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) शेरगढ़",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) शेवफधा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय उमरावल",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चकपिनहा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) अकबराबाद",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रसूलपुर ब्यौर",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) उजिहिनी खालसा",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पलहाना कछार",
  },
  {
    district: "कौशाम्बी",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय (1-8) कुरई उपरहार",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज कड़ा कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीन द0उ0रा0मा0 इण्टर कालेज केसारी कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "केजीबीवी राजकीय हाईस्कूल कसिया कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पल्हाना कछार कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल महेवाघाट कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल शेरगढ़ कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल राला कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल अमिरतापुर कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल ढोकसहा कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल अर्कामहावीर कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "महेश्वरी प्रसाद इण्टर कालेज आलमचन्द्र कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "बह्मचारी इण्टर कालेज तिल्हापुर कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "कौशाम्बी इण्टर कालेज कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "कृषक इण्टर कालेज हिनौता कौशाम्बी",
  },
  {
    district: "कौशाम्बी",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रणजीत पंडित इण्टर कालेज शहजातपुर कौशाम्बी",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल छितौनी कोप (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल छितौनीकोप मुशरटोली (1 से 8 कंपोजिट)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल उधौ छपारा नौकाटोला (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल दारागौली कदहवा टोला (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल हनुमानगंज मंदिर टोला (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "के जूनियर हाई स्कूल खड्डा (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल हनुमानगंज (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल चतुर छपारा (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल भुजौली बाजार (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल भेड़ी जंगल (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पिपरिया (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल जखनिया (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय साहबगंज (1 से 8 तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "यूपीएस सरयाखुर्द (1 से 8 कंपोजिट)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सियराहा परसौनी (1 से 8 कक्षा तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सुमही संत पट्टी (1 से 8 तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय तरया सुजान (1 से 8 तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय तरया सुन्दर (1 से 8 कक्षा तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गोपालपुर (1 से 8 तक)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय जवाही दयाल (1 से 8 समग्र)",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल माधोपुर गौरी श्रीराम",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल दुदही",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल विजयपुर उत्तरपट्टी",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पिपरा जटामपुर",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सिसवा मठिया",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "के जूनियर हाई स्कूल मटिहनिया बुजुर्ग",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल साढ़ी खुर्द",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल शिवपुर बुजुर्ग",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कल्याण छापर",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल भावपुर",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सिसवा दीगर",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बाजू पट्टी",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल तिनबरदाहा",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सिसवा गोपाल",
  },
  {
    district: "कुशीनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल विशुनपुरा खैरहवा टोला",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श एग्लो इण्टर कालेज दुदही, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इण्टर कालेज धौरहरा, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालेज धुरिया, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "लाल बहादुर शास्त्री इण्टर कालेज सपही टडवा, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री शैल्य कुमार सिंह इण्टर कालेज दिलीपनगर, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "गोस्वामी तुलसीदास इ०का० पडरौना, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू इ०का० पटेहरवा, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजेन्द्र सिंह इ०का०लेहनी कुसुम्हा, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकुमार उ०मा०वि० कुबेरनाथ, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता उ०मा०वि० विजयपुर, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या उ०मा०वि० बसडीला पाण्डेय, कुशीनगर",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल राजकीय मॉडल इंटर कॉलेज",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल राजकीय मॉडल इंटर कॉलेज",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल राजकीय मॉडल इंटर कॉलेज",
  },
  {
    district: "कुशीनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ०का० मनिकौरा, कुशीनगर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0गूम",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 इच्छारामपुरवा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खगईपुरवा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रेहरियाकला",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्राय0वि0 जिगनिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रेहरियाकला",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सराॅय सूरत",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 शेरपुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0बेहजम",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0रतसिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कटैया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दाउदपुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0बिजुआ",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रेवतीपुरवा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 हाफिजपुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रघुनाथपुर 1",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कस्ता कालोनी",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्र0वि0 सिसैया कलां",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रामबट्टी",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संविलयन वि0 धौरहरा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संविलयन वि0 समर्दा बदाल",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 डिहुआकलां",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "सं0वि0 लालजीपुरवा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 कटैया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 दाउदपुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 नौसरागुलरिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 मांझासुमाली",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "सं0वि0 गौढी",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "सं0वि0 सैनापुर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 गूम",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 फूलबेहड बालक",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्र0वि0 श्रीनगर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संवि0वि0 इन्द्रानगर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संवि0वि0 लैलूननगर",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "बेसिक शिक्षा",
    name: "संवि0वि0 महुरेना",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "धर्म सभा इण्टर कालेज,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "भगवान दीन आर्य कन्या इण्टर कालेज,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "पं0 दीनदयाल उपाध्याय सरस्वती विद्या मंदिर इ0का0",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "पी0डी0 भारती इ0का0मोहम्मदी",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "बी0बी0एल0सी0का0 खम्हरिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, धौरहरा",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 निघासन",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजा प्रताप विक्रम शाह इ0का0 सिंगाही।",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "द्वारिका प्रसाद गायत्री देवी इ0का0",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "बल्देव बैदिक इ0का0 पलिया",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "जिला पंचायत बालिका इ0का0 पलिया,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "के0एं0यू0के इण्टर कालेज, पड़रिया तुला",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "सावित्री देवी देशराज सिंह इण्टर कालेज,",
  },
  {
    district: "लखीमपुर-खीरी",
    medium: "माध्यमिक शिक्षा",
    name: "पब्लिक इण्टर कालेज, ईसानगर",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भदरौ",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय निनिर्या",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खौखर",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुचदोन",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कुमरौ",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय ममलार",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मलार",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मलार",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रुखवाहा कम्पोजिट",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गुनखुरू समद",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय नवीन कुरौरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राइमरी जूनियर स्कूल खुशीपुरा पचोड़ा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नवीन बंदेसरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रामपुरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कंपोजिट 12वीं",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय व उच्च प्राथमिक विद्यालय बंदेशरा कंपोजिट 12वीं",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दौलता",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० खडोबरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० अमरपुर",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० खडोबरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० बसवां",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० नदनवारा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० रोड़ा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० खडोबरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० गोरा",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० पाचौनी",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० बम्हौरीकला",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू० हा०स्कू० खड़ेश",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० बुढवार",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा० स्कू० दावनी",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०ह 10 स्कू० मसौरा खुर्द",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० मसौरा खुर्द",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० करगन",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० दावनी",
  },
  {
    district: "ललितपुर",
    medium: "बेसिक शिक्षा",
    name: "जू०हा०स्कू० मनगुवां",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री शान्ति निकेतन इ०का० महरौनी",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सी०बी०गुप्त इ०का० महरौनी",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री मर्दन सिंह इ०का० तालबेहट",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का० तालबेहट",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री मदन मोहन मालवीय इ०का० गुढा",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सरस्वती मंदिर इ०का० मडावरा",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री बर्णी जैन इन्टर कालेज ललितपुर",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इ०का० ललितपुर",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "पी०एन० इ०का० ललितपुर",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ०का० ललितपुर",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रघुवीर सिंह नेता जी इ०का० बार",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ०का० बार",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री स्याद्वाद वर्णी इ०का० गदयाना",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री किसान इ०का० विरधा",
  },
  {
    district: "ललितपुर",
    medium: "माध्यमिक शिक्षा",
    name: "बुन्देलखण्ड इ०का० जाखलौन",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ईश्वरी खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इरादत नगर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खरिकाई",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चिल्लावां",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बाग मिरिया जुम्मा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किला मोहम्मद नगर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय आशियाना",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेहसा 2",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भक्ति खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गौरी-2",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमौसी-2",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सार्डिनी नगर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गहरू",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मक्का खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गंगादीन खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय स्कूटर इंडिया ",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसंत खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हरदासी खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमराई गांवी",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तकरोही",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तकरोही प्रथम",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुण्डरी",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नया ऐशबाग",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गजीपुर बलराम",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खड़गपुर फरीदीनगर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय निशातगंज",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लौंगा खेड़ा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पिपराघाट",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लमटिनियर का पुरवा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय केबिनेट गंज",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिकंदरपुर",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोदियन पुरवा",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मड़ियांव",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भेरी मंडी",
  },
  {
    district: "लखनऊ",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छितवापुर",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय सैनिक इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "अभिनव विद्यालय",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर",
  },
  {
    district: "लखनऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सब्या बंचिया",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुसहरी टोला",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मटियरवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बिशुनपुरवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कमनाहा पुरन्दरपुर",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोमर टोला",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कम्नहवा नगवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तेलियाडीह",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बागापार 2",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामपुर बुजुर्ग",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तरकुलवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कोटा मुकुन्द",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हनुमानगढ़िया",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महदेइया",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सेमरा सूर्यपुरा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जगरनाथपुर",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कौलाही",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रतनपुर 2",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय घुघली 1",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पौहरिया",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0ववि0 विषखोप",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरवा खुर्द",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 केवलापुर खुर्द",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 परासखाड",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 महुआरी",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 धीवपीड",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 बारीगाव",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 बडहरामीर",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 बुढाडीह",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 कुआ चाफ",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 अमवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 सिंहपुर अयोध्या",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 मझौवा कम्पोजिट",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 धेतिहवा",
  },
  {
    district: "महराजगंज",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 दशरथपुर",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीन द0उ0राजकीय मा0 इण्टर कालेज कुआचाफ पनियरा",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीनदयाल उ0रा0मा0इ0क0 सहजनवा बाबू बृजमनगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 नौनिया निचलौल महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 सेमरा चन्द्रौली",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 मेदनीपुर महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बेलटिकरा",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 रामपुर चकिया",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 उटी खांस",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 सोहवल",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 वि0 मिर्जापुर पकडी महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 पुरैना खण्डी चैरा महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विश्वनाथपुर महराजगंज",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बजरहा",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बरडाड धानी",
  },
  {
    district: "महराजगंज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 भगवानपुर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राश्यमिक विद्यालय लाड़पुर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खंदियाजैतपुर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अकौना",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राममिक विद्यालय लमोरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "नवीन प्राथमिक विद्यालय फुलपहाड",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्रथमिक विद्यालय मंगरौलखुर्द",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खोई",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय महुआबांध",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राममिक विद्यालय रगौलिया बुजुर्ग",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बचेवरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय थुरट",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बगरौन",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इमिलिया डांग",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुसरमा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नौसारा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बैहारी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसौठ",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बारी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ऐचाना",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पहरेता",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बलचैर संवि0",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चन्दौली",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गुढा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बैहारी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय आरी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय लाडपुर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय सतारी",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय सीगौन",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय सुंगिरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चन्द्रपुरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय प्रेमनगर",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय लिलवां",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय तेइया",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय भूरा",
  },
  {
    district: "महोबा",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 उच्च प्राथमिक विद्यालय बम्हौरी कुर्मिन",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती वी एम इंटर कॉलेज महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री अवधूतानंद हाईस्कूल अंदवारा विजयपुर महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "नरेन्द्र सिंह हाई स्कूल चरखारी महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "जी बी इस्लामिया इंटर कॉलेज कुलपहाड़ महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "स्वर्गीय एस.बी.एस.बालिका इंटर कॉलेज कबरई महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "एसटी जोसेफ्स हायर सेकेंडरी स्कूल महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मंदिर इंटर कॉलेज चरखारी",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "एस ए डी जी एस इंटर कॉलेज उटियां महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती बालिका विद्या मंदिर इंटर कॉलेज नरूपुरा रामकथा मार्ग महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "मानव कल्याण सेवा समिति इंटर कॉलेज नहदौरा माफ महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "पटेल एस.एस. इंटर कॉलेज ननौरा महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "बजरंग इंटर कॉलेज गदोखर धाम पनवारी महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सिद्धगोपाल रिछारिया इंटर कॉलेज जैतपुर महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "श्री ब्रह्मचारी इंटर कॉलेज खन्ना महोबा",
  },
  {
    district: "महोबा",
    medium: "माध्यमिक शिक्षा",
    name: "अटल ज्योति इंटर कॉलेज जैतपुर महोबा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 शिशु उधान",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 गंज बालक",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 न्यू गाडीवान",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 ट्यूववेल नम्वर 2",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 देवी रोड",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खजुरिया",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नगला दुधी",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बरनाहल",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जैतपुर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 रसेमर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 सुजानपुर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 कुरावली",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 नगला मांझ",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 फतेहजंगपुर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 विघरई",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 महठौली",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 घिरोर प्रथम",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 गोधना",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लपगवां",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 कछपुरा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 बरनाहल",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 प्रहलादपुर",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 तिलियानी",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मद्दापुर धर्म",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 परोंख",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 न0 भन्त",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भोजपुरा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भटानी",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 पडरिया",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 एलाऊ",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 रतनपुरबरा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 कछपुरा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 कुरावली",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 व0 महादेवा",
  },
  {
    district: "मैनपुरी",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 वि0 सोनई",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर काॅलेज मैनपुरी",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी सदानन्द राजकीय कन्या इ0का0",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "स्व0 रघुवीर सिंह यादव राजकीय इण्टर कालेज,",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "जैन इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "श्री नेहरू स्मारक, इण्टर कालेज,",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "मलिखान सिंह इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "अमर शहीद इण्टर काॅलेज",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "कु0ॅं रामचन्द्र कन्या इण्टर कालेज,",
  },
  {
    district: "मैनपुरी",
    medium: "माध्यमिक शिक्षा",
    name: "सुदिती ग्लोबल एकेडमी",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय आँवला सुल्तानपुर",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरारी प्रथम",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेरी प्रथम",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भाहाई",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौकीपुरा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धाना तेजा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गढ़ी चुरमुरा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बबूरी",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाबूगढ़",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाद",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाजना",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाटी।।",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसई",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ऋषि संतान",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बालमुकुन्द",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ब्रजकुन्ज",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कमला नेहरू का",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० महर्षि वाल्मिक",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अकबरपुर-I",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय औढूटा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० बरौली",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गढी आशा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नीमगॉव",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नैनूकलों",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० लालपर",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० जौनाई",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० जुनसुटी",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "पृ०मा०वि० नगला सीता",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० धौली प्याऊ",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कोनकेरा",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल नपुरिया",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पसौली",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भूरेका",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय ब्योहाई",
  },
  {
    district: "मथुरा",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चिंतागढ़ी",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय लौहवन, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय सैनवा, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय भद्रवन, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय दघेंटा, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0 मा0 विद्यालय भैंसा ,मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज बरौली मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज जैंत मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज करहारी मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज सिहाना मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "प0 दीनदयाल राजकीय मा0इ0कालेज सौनई मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 बल्देव मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 परखम ,मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय भारतीय इ0का0 अड़ीग, मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 फरह , मथुरा",
  },
  {
    district: "मथुरा",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0 विद्यालय बिवावली मांट, मथुरा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खुखुन्दवा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्र्रा0वि0 पारामुबारकपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जयसिंहपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भदसा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चेरूईया",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 बन्दीद्यााट",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भीरा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 गालिबपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बरहदपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कुतुबपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि भट्कुआपट्टी",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि0 हकिकतपुरा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि0 सुल्तानपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि0 मुशीपुरा(बालिका)",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 यूसुफपुरा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "क0वि0 धर्मपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 धर्मपुर देवारा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 खैरा देवारा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 विनटोलिया",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "क0वि0 चक्कीमुसाडोेही",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बांदीकला",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल मोहम्मदाबाद",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय फ़तेहपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रानीपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हरदौली",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भदशा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गरथौली",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मुहम्मदपुर झझवा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हाजीपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय घोसी",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मधुवन",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय फतहपुर तलराटोया",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गोंथा",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय खांडेरायपुर",
  },
  {
    district: "मऊ",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गढ़ा",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "तालीमुद्दीन इंटर कॉलेज",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "बनदेवी इंटर कॉलेज",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय जामदरा",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय विद्यालय नदवल",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "बहा थानीदास आईसी अमिला",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "वेक्ट्री इंटर कॉलेज दोहरघाट",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "शहीद इंटर कॉलेज फ़तेहपुर",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उच्च विद्यालय सुल्तानीपुर",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "पीडी दिनदयाल इंटर कॉलेज अइलख",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "टाउन इंटर कॉलेज मऊ",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय जनता इंटर कॉलेज",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "जैश किसान इंटर कॉलेज वनगावा",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज मुजार बुजुर्ग",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "पंडित दीन दयाल माध्यमिक महाविद्यालय कटिहारी",
  },
  {
    district: "मऊ",
    medium: "माध्यमिक शिक्षा",
    name: "पीडी दीन दयाल सेकेंडरी इंटर कॉलेज",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​बफावत",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​पबरसा",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​पनवारी",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​सुरानी",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​टांडी",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल मवाना खुर्द",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बटनौर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल गैडीना",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल कुंडा",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सनुटा",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट हाजीपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जेएन.एच.एस. (कम्पोजिट) ​​खेड़ा बलरामपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जेएन.एच.एस. कम्पोजिट गगोले",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जेएन एच.एस. कम्पोजिट कायसटगावाड़ी",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल (कम्पोजिट) ​​छाजूपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल औरंगाबाद",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल ज्ञानपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल मोरना",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "भवनपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जेएन एच.एस गर्ल्स इंचौली",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बस्तोरा नारंग",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल जलालपुर जोरा",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल किशोरपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल दूधली खादर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल हस्तिनापुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल सिरजेपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल गणेशपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल करीमपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल रठौरा कलां",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल लतीफपुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल राठौरा खुर्द",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल तारापुर",
  },
  {
    district: "मेरठ",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल एदलपुर",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "जूनियर हाई स्कूल एम.पुर शिहेरा",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "जूनियर हाई स्कूल समसपुर",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "सी०एल०एम० इण्टर कालिज जानी, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इण्टर कालिज, कल्याणपुर, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "सेंट चार्ल्स इण्टर कालिज, सरधना, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "चौ० बशीर खाँ इण्टर कालेज, हर्रा, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राष्ट्रीय इण्टर कालिज, लावड, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू स्मारक इण्टर कालिज, चितमाना शेरपुर",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "ए०एस० इण्टर कालिज, मवाना, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "चौ० प्रेमनाथ के०वी० इण्टर कालिज, माछरा मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालिज, हस्तिनापुर, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालिज, हस्तिनापुर, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "आचार्य नमि सागर जैन इण्टर कालिज, हस्तिनापुर, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल, राठौडा खुर्द, मेरठ",
  },
  {
    district: "मेरठ",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाई स्कूल तरबियतपुर जनूबी मेरठ",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भेवर करमनपुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय अरगजा पांडे",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मुहकुचवा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय जिवती",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय शाहपुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय निफारा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सुरहन",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कुशहा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय महामलपुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मझौवा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय अघौली",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हरघर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय घमहापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय फुलियारी",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय पिडखिर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चेतगंज",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बामी",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "बसंत विद्यालय इंटर कॉलेज मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "जयहिंद विद्या मंदिर इंटर कॉलेज अहरौरा, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अधवार",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय ड्रमंडगंज",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय हथेड़ा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय कौड़िया कला",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय तुलसी",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय देवरी पूरब",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रामपुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "बी.एल.जे. इंटर कॉलेज मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री शिवाजी नेशनल इंटर कॉलेज हासीपुर, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज विंध्याचल, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय बदाउहां",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दुबारकला",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट विद्यालय रामपुर वासिद अली",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बिरोही",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बरौंधा",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गदौली",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रजौहां",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अमोई",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजस्थान इंटर कॉलेज मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री माता प्रसाद माता भीख इंटर कॉलेज मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इंटर कॉलेज, बरेवां, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री बृजराज आदर्श इंटर कॉलेज चुअखड़ा, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भैसोदबले पहाड़",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय चेतगंज (कन्या)",
  },
  {
    district: "मिर्जापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय खैरा",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री स्वामी गोविंदाश्रम इंटर कॉलेज पैदापुर, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "आदर्श बालिका इंटर कॉलेज, सीखड़, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "जनत जनार्दन इंटर कॉलेज भुड़कुड़ा, मीरजापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "छत्रपति शिवाजी इंटर कॉलेज खजुरौल, मिर्ज़ापुर",
  },
  {
    district: "मिर्जापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इंटर कॉलेज बरौधा, मिर्ज़ापुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या पी0 ए0 सी0 काशीराम नगर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कटार शहीद",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या मुगलपुरा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्या तहसील",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय राजकीय फेजगंज",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दोग",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मझोला",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्र0 वि0 बढापुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कत्पोजिट विद्यालय राईभूड",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बलिया",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लालापुर पीपलसाना प्",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लालापुर पीपलसानसा प्प्",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 चकरपुर पूरनपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोेजिट विद्यालय सेेहल",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोेजिट विद्यालय बढुपुरा मझॅरा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 सिरसवा गौड",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बगरौआ",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 मिलक नगलिया जट",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मुस्तफापुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कुमरिया जुबला",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अहमदपुर आनन्दपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय अलियाबाद",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मॅझरा आदमपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सलेम सराय",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कत्पोजिट विद्यालय ढकिया पीरू",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 काजीपुरा -प्प्",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्र0 वि0 पाकबडा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय डिडौरा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उत्तमपुर बहलोलपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मंगुपूरा",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 मिलक बहलोलपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 पाकबडा -प्रथम",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्र0 वि0 मोरा मिलक",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय मालीपुर",
  },
  {
    district: "मुरादाबाद",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 भाण्डरी",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "महाराजा अग्रसेन इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "चित्रगुप्त इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "पारकर इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "आर0एन0 इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "एस0एस0 इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "हिन्दू मार्डन इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "जी0जी0 हिन्दू इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "फलाहे दारेन इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "मैथोडिस्ट गल्र्स इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "आर्य कन्या इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "रामचन्द्र शर्मा कन्या इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "कौसल्या कन्या इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "बल्देव आर्य कन्या इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "हैविट मुस्लिम इण्टर काॅलेज, मुरादाबाद।",
  },
  {
    district: "मुरादाबाद",
    medium: "माध्यमिक शिक्षा",
    name: "के जी के इंटर कॉलेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सलेमपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बुढाना-3",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शाहडब्बर-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नदीनपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नावला-3",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "पू०गा०वि० नावला",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जटमुझेडा",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पूर्वी पाठशाला",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० तितावी-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नीला महाराजनगर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० बहादरपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० वि० कासमपुरा",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र० वि० जलालपुर नीला",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चरथावल-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बाननगर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शाहपुर-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिसोली-1",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिसोली-2",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गर्दी बालक",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "पू0मा0वि0 भैंसी",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुरादपुरा",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मजलीसपुर तोफीर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय, शाहपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0विद्यालय गोकलपुर (1-8)",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय, खेड़ी तगान",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय रायपुर नगली",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय, मुजाहिदपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बुढ़ाना",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० कि० भोपा",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र० वि० बिहारगड",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा० वि० स्याली",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्र० वि० हुसैनपुर खादर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय, असदनगर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोट वि० बिसलरी",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो०वि० हैबतपुर",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ०का०",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ०का०",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "डी०ए०वी० इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "दीपचन्द ग्रेनचैम्बर इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "जैन कन्या इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "वैदिक पुत्री कन्या इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "जयहिन्द इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "डी०ए०वी० इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "डी०ए०वी० इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "वे०के० जैन इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "एस०डी० इण्टर कालेज",
  },
  {
    district: "मुज़फ़्फ़र नगर",
    medium: "माध्यमिक शिक्षा",
    name: "मॉडर्न इण्टर कालेज",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बहरुआ",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय परेवा वैश्य",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय फुलैया",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक स्कूल पिंजरा वामनपुरी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अजान",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बड़हरा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बैला डांडी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसेंगा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोपालपुर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय हीरपुर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय आजमपुर बरखेरा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अखोला",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमराकरोद",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमृता खास",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भदरा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक स्कूल सरीफगानी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरोरी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गणेशपुर गोरिया",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक स्कूल बिठौरा कलां",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौड़ा खेड़ा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पिनिरा वामनपुरी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल परेवा वैश्य",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल रामनगर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल अधकटा",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल रामपुर अमृत",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल बढ़पुरा मरोरी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल लाडपुर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल अमृता खास",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल पट्टी सियाबाड़ी",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल उमरसाढ़",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल वाहनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल बिठौरा कलां",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल शेरपुर कला",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल शिवनगर",
  },
  {
    district: "पीलीभीत",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाई स्कूल टांडा गुलाबराय",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "ड्रमण्ड राजकीय इ०का०पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल बूदी भूड पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल मुरौना गौडी पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल खिडिकिया बरगदिया पीलीभीत।",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "सन्त रवि दास ज्ञानार्जन इ०का०पुरैना तालुके महाराजपुर पूरनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "लाल बहादुर शास्त्री इ०का० चदिया हजारा पूरनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "शारदा उ०मा०वि० कबीरगंज पूरनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बा० इ०का०पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "आदित्य पब्लिक इ०का० कबीरगंज पूरनपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "सनातन धर्म बाँके बिहारी राम इ०का० पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "अवन्तीबाई जिला पंचायत बालिका इ०का० पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "कुँवर झनकार सिहं इ०का०वरसिया वीसलपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "कुवर बहादुर इ०का० शहवाजपुर वीसलपुर",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज गभिया सहराई पीलीभीत",
  },
  {
    district: "पीलीभीत",
    medium: "माध्यमिक शिक्षा",
    name: "स०व०भ०प०इण्टर कालेज अमृताखास पीलीभीत",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0, कटैया नेवादा",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0, तिलौरी",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0, दादूपुर पड़ान",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0, देवली",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 पट्टी प्रथम",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 भवसरनपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 बसौली 1 से 8",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 नारंगपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 महदहा",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लखनीपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 पूरे रैकवारन",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बरौलिया",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 देवरी हरदोपट्टी",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 तिवारी महमदपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बेधनगोपालपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बलीपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 लौवा",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 बानेमऊ",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "प्रा0 वि0 चैकापारपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय सुबरनी",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "सं0 वि0 सरौली",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "सं0वि0 परसरामपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 सरूआ",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "कन्या उ0प्रा0वि0 अन्तु दंहात",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 समापुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 सेतापुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "पू0 मा0 वि0 कस्बा लतीफपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 श्रीपुर चैहरजन",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "पू0 मा0 वि0 नेवादाकला",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 चैकापारपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 डेरवा",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 बसीरपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "पू0 मा0 वि0 भरतगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 गाबी महुआबन",
  },
  {
    district: "प्रतापगढ़",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0 वि0 मुस्लिम बस्ती खरगपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज शेखूपुर प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज शेखूपुर प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०विद्यालय देवरी हरदोपट्टी",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०विद्यालय कन्धई मधुपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०विद्यालय लोहंगपट्टी",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा०विद्यालय रसूलपुर",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज बरहदा प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "लोकमान्य तिलक इण्टर कालेज प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीराम बालिका इ०का० चिलबिला प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "पी०बी०इ०का० सिटी प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "अनन्त प्रसाद इ०का० भदोही प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "रामराज इण्टर का० पट्टी प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "ाीतला प्रसाद इ०का० गढ़वारा प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "साकेत गल्र्स इ०का० प्रतापगढ़",
  },
  {
    district: "प्रतापगढ़",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ०मा० विद्यालय बसौली",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कनकपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पिपरी",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दांदरूपुर कक्षा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय घोघापुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय थरवई",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्राथमिक विद्यालय शिवगढ़-प्रथम",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय राजापुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय अराजी उमापुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय करनाईपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र बलकरनपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र पुरखीपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र मऊदोस्तपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय कनेहटी",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र ढोकरी",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र देवारा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र पवार",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय करेहा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय खेकसा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र सुजौना",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "समग्र घूरपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. शंकरगढ़",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. धनुपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. मऊआइमा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. मेजा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. सैदाबाद",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. हंडिया",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. होलागढ़",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. जसरा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. कौड़िहार",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. कोरॉव",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. उरवा",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी.करछना",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. फूलपुर",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. चाका",
  },
  {
    district: "प्रयागराज",
    medium: "बेसिक शिक्षा",
    name: "के.जी.बी.वी. सोरांव",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज स्वरूपरानी प्रयागराज।",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "जगततारन गल्र्स इण्टर कालेज, प्रयागराज।",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "सेन्ट ऐंथोनी कान्वेंट गल्र्स इण्टर कालेज, प्रयागराज।",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "मेरिवाना मेकर गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज, कटरा प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "हिन्दू महिला इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "एंग्लो बंगाली इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "शिवचरणदास कन्हैयालाल इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "सेन्ट एन्थोनी गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "मेरी वानामेकर गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "डी0पी0 गल्र्स इण्टर कालेज प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "गुरु तेग बहादुर खालसा गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "जगत तारन गल्र्स इण्टर कालेज, प्रयागराज",
  },
  {
    district: "प्रयागराज",
    medium: "माध्यमिक शिक्षा",
    name: "डी0पी0 गल्र्स इण्टर कालेज प्रयागराज",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लेटमुहा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बैखरा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अशर्फाबाद",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सिधौना",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कौआडीह",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भौसी",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चितवनिया",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रायपुर नेरूवा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रामपुर पदुमनाथ",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शिवली",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जमुनिहा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय राजापुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेला टेकई",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तौधकपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शेखवापुर 1",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय लालगंज राजकीय",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मीठापुर बढैया",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तेलियानी",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय उमरामऊ",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किटौली",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नरेन्द्रपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुन्दनगंज",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जलालपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भैरमपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गोझरी",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सताॅव 2",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मलिकमऊ चैबारा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किलौली",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टाण्डा",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पहंरावां",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देदौर 1",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय देदौर 2",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सींकी सलीमपुर",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सवनई",
  },
  {
    district: "रायबरेली",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेला गुसीसी",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इन्टर कालेज रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर राजकीय इन्टर कालेज सेमरी रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इन्टर कालेज अटौरा बुजुर्ग रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इन्टर कालेज डीह रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इन्टर कालेज हलोर रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज डीह रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज लालगंज रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज महराजगंज रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज देवानन्दपुर रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज अजीतपुर रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इन्टर कालेज सलोन रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीनदयाल उपा0मा0इ0कालेज हरीपुर निहस्था रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीनदयाल उपा0मा0इ0कालेज कुॅवरमऊ रायबरेली",
  },
  {
    district: "रायबरेली",
    medium: "माध्यमिक शिक्षा",
    name: "पं0दीनदयाल उपा0मा0इ0कालेज भीरा गोविन्दपुर रायबरेली",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सूरत सिहं पुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खौद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० प्रानपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अहरौला",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लालपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० केसरपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अहमद नगर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बरखेड़ा, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लश्करगंज शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मीरापुर, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० महेवा, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मोतीपुरा, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शेखुपुरा, शाहबाद",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बेहटा, मिलक",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भैसौड़ी, मिलक",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० निस्वी, मिलक",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रहमतगंज-२",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मझरा बहादुर, स्वार",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अच्वास नगर, स्वार",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० धनौरा, स्वार",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० हकीमगंज",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जगतपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० केसरपुर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० आंगा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० शाहदरा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० मझरा घोसीपुरा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कूप",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गजरौला",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० पजाबा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० अशोकनगर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० रामनगर",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० पसियापुरा",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० घोसीपुरा पट्टी कलां",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० केथोला",
  },
  {
    district: "रामपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गंगापुर कदीम",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय मुर्तजा इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय जुल्फिकार इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सुन्दर लाल इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "कन्या इण्टर कालेज, खारी कुआं, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "विद्या मन्दिर इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "डी0ए0वी0 इण्टर कालेज, विशारदनगर, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गुरूनानक इण्टर कालेज, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, स्वार",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सर्वहितकारी इण्टर कालेज, मसवासी, स्वार, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, टाण्डा, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, टाण्डा बादली, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, धमौरा, मिलक, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रेशम प्यारी कन्या इण्टर कालेज, मिलक, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, शाहबाद, रामपुर।",
  },
  {
    district: "रामपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालेज, शाहबाद, रामपुर।",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 वि0 आटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 धर्मपुर कुईया",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 मझावली",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 जैरोई हयातनगर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 नेहटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) धुरैटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) अतरासी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) काजीबहटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) सा0 शो0 मुन्जब्ता",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) लखनपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0(1 से 8) रायपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 इटायलामाफी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 मदाला फततेपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 असमाोली",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 अकबरपुर गहरा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 शहबाजपुर कलाॅ",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि0 ऐचैडा कम्बोह",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सराय व्रहामण",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नगलिया देवी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 काशीपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0वि पर्तइे कायस्त",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मिर्जापुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुल्हैटा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पाजिट विद्यालय बेहटा जय सिंह",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय दिलगौरा",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बहजोई देहात",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय चितनपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय पाठकपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट भारतल सिरसी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट जालब सराय",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट हिसामपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट हज़रत नगर गढ़ी",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट शरीफपुर",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विधालय कंपोजिट शाहपुर चमारान",
  },
  {
    district: "सम्भल",
    medium: "बेसिक शिक्षा",
    name: "शहीद ग्रीश चंद कम्पोजिट विद्यालय",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "इण्टरमीडिएट काॅलेज बहजोई।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "एस0एम0 इ0 का0 चन्दौसी।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "षंकर भूषण षरण जनता इ0का0 सम्भल।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "बाबूराम सिंह इण्टर काॅलेज बबराला।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इ0का0 जुनावई।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "नानक चन्द्र आदर्ष इ0का0 चन्दौसी।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "चन्दौसी इण्टर काॅलेज चन्दौसी।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "एफ0आर0 इ0का0 चन्दौसी।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "बी0एम0जी0 इ0का0 चन्दौसी",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 लहरावन",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "चन्द्रपाल आर्य आदर्ष इ0का0 बहजोई",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय क0 इ0 का0 सम्भल",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "हिन्द इ0 का0 सम्भल।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0 धीमर खेडी सम्भल।",
  },
  {
    district: "सम्भल",
    medium: "माध्यमिक शिक्षा",
    name: "प0दी0द0उ0माॅ0राजकीय इ0का0 राजपुर।",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक रसूलपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक सरही",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक सरौली प्राथमिक चहारूम",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक हाड़ापार",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विषयाहन्नू",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक महुआडाबर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक भिटिनी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक रैनियाॅ",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक तिल्ठा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक सोन्डीहा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक तामा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक सरौना खास",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक औटना",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक धरूई",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक एकडंगा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक रक्शा कला",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "महुआडाबर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "भिटिनी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "रैनियाॅ",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "तिल्ठा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय विश्वनाथपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 देवरिया गंगा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय पचेठी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय हरदी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 बेलहरकला",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय मेंहदावल",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0 विद्यालय नंदौर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय सोनाड़ी",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0वि0 शानिचरा बाजार",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय सांथा",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय कटया",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय बाधनगर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0वि0 सेमरियावां",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 नाथनगर",
  },
  {
    district: "संत कबीर नगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्रा0 विद्यालय महुली प्रथम",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0 खलीलाबाद",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बघौली",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 धौरहरा",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 सालेहपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 महुली",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 जामडीह",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 सियाकटाई",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 करमाखान",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 उमिला",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 गिरधपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 गोविन्दगंज",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बभनौली",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 रमजगला",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 भगवानपुर",
  },
  {
    district: "संत कबीर नगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0 बनौली",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मीरापुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राइमरी स्कूल फेरू माजरा",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नारायणपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय समसपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सुल्तानपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नाथोडी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय टोडरपुर भूखरी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय गजदीनपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहपुर दाउद",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "पी.एस.मुकरमपुर उर्फ़ लालवाला",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नारायणपुर गुज्जर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पंचकुआ",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चौरा केला",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय शाहजहाँपुर",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छबडी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मझाड़ी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बार्था कोरसी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बेगिनाजार",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सरजपुर सैय्यद",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ततारपुर खुर्द",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०दि० इस्माईलपुर मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० फन्दपुरी",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०पा०वि० रणदेवा मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० नाईनगली मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा०वि० नल्हेंडा मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० कुराली मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खारीबांस मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० तिरपडी मर्ज",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बसी कुण्डा कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०बि० कल्लरहेडी कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०ग्रा०वि० चाउपुरा कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० खालिदपुर कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० दौलतपुर कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० शकरपुर कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० जानपुर माजरी कम्पोजिट",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दिगम्बर जैन कन्या इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गुरुनानक कन्या इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गौरी शंकर इन्द्रपाल इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रामकृष्ण योगाश्रम इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री जैन इण्टर कालेज, देवबन्द।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दिगम्बर जैन कन्या इण्टर कालेज, सरसावा।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री दिगम्बर जैन क030मा0 वि‌द्यालय, देवबन्द।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "आर्य कन्या इण्टर कालेज, गंगोह, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दिलीप सिंह राष्ट्रीय कन्या इण्टर कालेज, गंगोह सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "इण्ड्रस्टियल मुस्लिम गर्लस इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इण्टर कालेज, देवबन्द, सहारनपुर",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर हाईयर सेकन्डरी, सहारनपु",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री हिन्दू कन्या इण्टर कालेज, सहारनपुर।",
  },
  {
    district: "सहारनपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज कैलाशपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सकटिया",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय विक्रमपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय भुदनपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खूबपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अचिंतपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय केसी नगला",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पकड़िया",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरुआरा",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय प्रह्लादपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुनिया 1",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुनिस 2",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय पहरुआ कलां",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसंत नेगर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धीमरपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय खानपुर औरंगाबाद",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय औरंगाबाद",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय जंगलपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कुदरिया",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बोझी",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय नारायण नगर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मैगटोरा",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय पेहना",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय टापर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय थाथरमई",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय आलमनगर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय धियारपुरा",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय बझेड़ा",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय थिंगन",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय दहेना",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय भरथौली",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय समापुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय हुल्लापुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मऊ एस.पी.एन",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय मऊ खास",
  },
  {
    district: "शाहजहांपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पसिट स्कूल कोटा",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल नाहिल शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल कुदैया मदनापुर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल खंडहर जलालाबाद शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पदेचा कांत शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल चिल्हौटा बंडा शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल शंकरपुर पितरहाई जैतीपुर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल नौगई तिलहर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल शाबाजनगर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल सादिकपुर मिर्ज़ापुर शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल धन्यौरा ददरौल शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल सिंहपुर पनई बंडा शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल परसौना खलीलपुर निगोही शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल नौसाना खुदागंज शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल ररूआ बंडा शाहजहाँपुर",
  },
  {
    district: "शाहजहांपुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल हथनी नगरिया कलां शाहजहाँपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0वि0 म्यान कस्बा",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0वि0 रजाक नगर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 सकौती",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 कबीरपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 दरगाहपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 नौनांगली",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "सवि0 वि0 चैसाना",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 जसाला",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मखमूलपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कनियान",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ0प्रा0वि0 चढाव",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मलकपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ0प्रा0वि0 भनेडा",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उ0प्रा0वि0 भारसी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मसावी (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 चन्दैना माल (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0्रप्रा0वि0 ख्यावडी (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 गौसगढ (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 मादलपुर कम्पोजिट",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 तितारसी कम्पोजिट",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा0वि0 कुतुबगढ (कम्पोजिट)",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 कुडाना",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 भैंसवाल",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जंधेडी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट करौडी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि0 करौदा हाथी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट बुटराडा",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट काबडौत",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि बुच्चाखेडी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि मंडावर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि गन्द्राव",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि मवी",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि पांवटी कलां",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि अलीपुर",
  },
  {
    district: "शामली",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्रा वि जगनपुर",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज इत्सत्तोपुर खुरगान",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज भर्ना उदद्वा",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या हाईस्कूल दथेडा",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल जैनपुर",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल टोडा",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकन्या हाईस्कूल बन्तीखेडा",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल बलवा शामली",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या हाईस्कूल गंगेल",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल पावटी कला",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या हाईस्कूल टपराना",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "श्री तनातन धर्म संस्कृत नाव्यमिक विद्यालय लॉक",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "लाला नरसिहदास सरस्वती विद्या मन्दिर इ० क० कैराना",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती बालिका विद्या मन्दिर इण्टर कालिज शामली",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मन्दिर इण्टर कालेज, शामली",
  },
  {
    district: "शामली",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सत्यनारायण इण्टर कालेज, शामली",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० नत्थापुरवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट मझौवा सुमाल",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट पिपराहवा कोठी",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जयचंदपुर कटघरा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अकबरपुर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बहोरवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटही-बगही",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मसहा कला",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट मोतीपुर मसहा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० रनियांपुर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट सोहेलवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कुथनी",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सम्भारपुरवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बरगदवा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट रतनापुर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मोहरनियां",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० मोहरनियां",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लक्ष्मननगर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०पि० फतुहापुर प्रथम",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दमावां",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पटना खास",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बडका फत्तेपुर",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जमुनहां",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट नासिरगंज",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट महरूमुर्तिहा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० काला",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बेलरी",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बनकटवा महोली",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० भलुहिया",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट पटना खरगौरा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "जू०हा० भिनगा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बन्दरहा दक्षिणी",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भिनगा -।",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट सत्तीचौरा",
  },
  {
    district: "श्रावस्ती",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट राजापुर रानी",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिक इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "श्री अलक्षेन्द्र इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "जनता इ0का0 पटना",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "जगतजीत इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "स्वामी विवेकानन्द इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "नीलम राजकीय बा0इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "नेहरू स्मारक इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "श्री प्रेम शुक्ला इ0क0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "तपसी इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "शिवराजी इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय हाईस्कूल",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "लाल बहादुर शास्त्री इ0का0",
  },
  {
    district: "श्रावस्ती",
    medium: "माध्यमिक शिक्षा",
    name: "श्री मेवालाल सियाराम पटवारी इ0का0",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बरनी (τ.a.)",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बनचौरा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय तुलसियापुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "जे.एच.एस. बर्डपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय असोगावा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय ओदानाताल",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट प्राथमिक विद्यालय रेहरा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट प्राथमिक विद्यालय जनियाजोत",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट उच्च प्राथमिक विद्यालय लामुईताल",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाईस्कूल सुराही ताल",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल छितौनी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट प्राइमरी स्कूल बनकटा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय किशुनपुरवा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय रुडलापुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मिठावल अहातामाली",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय बसंतपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय कथेला ग्रांट",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "जूनियर हाईस्कूल रसूलपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाईस्कूल के. डुमरियागंज",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कॉम्पसाइट जूनियर हाई स्कूल बंगवा नानकार",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय सीर मझरी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चैनिया",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल वीरपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल फत्तेपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल शाहपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल गौरडीह",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाईस्कूल होरीलापुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय अमरिया",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय इटौवा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय धुसावा राजा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल सजनी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय चुरिहारी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय छोरावर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल एनआईबीआई",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट जूनियर हाई स्कूल लेडावा",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इ0का0, नौगढ",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय कन्या इ0का0, तेतरी बाजार सिद्धार्थनगर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0, बसौनी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0, कोड़रा ग्रान्ट",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय उ0मा0वि0, टड़िया बाजार",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जनता विद्याा मन्दिर इ0का0 उदयराजगंज",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "मुस्लिम इ0का0 महदेइया",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर लाल ने0स्मा0इ0का0 नौगढ",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सिंहेश्वरी इ0का0 तेतरी बाजार",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "जय किसान इ0का0 सकतपुर सनई",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "सिद्धार्थ शिक्षा निकेतन इ0का0 तेतरी बाजार",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा गौतम बुद्ध उ0मा0विद्यालय जोगिया",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "बुद्ध विद्या पीठ इ0का0 बर्डपुर",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "तिलक इ0का0 बांसी",
  },
  {
    district: "सिद्धार्थनगर",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इ0का0 उसका बाजार",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लालपुर फॉर्म",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०नि० शाहजादपु",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सिपनापुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पीरकपुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्राथमिक विद्यालय मुरोती",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पू०मा०वि० बरेती जलालपुर कम्पोजिट",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पु०पा०नि० टॉडा कली कम्पोजिट",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बसुदहा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उण्प्रा०वि० बसुदहा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० आगगीरिया",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० देवरिया खुर्द",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० गोधनी सरैया",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बजेहरा नवीन",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० बजेहरा नवीन",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कम्हरिया कटेसर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पो०वि० किस्तापुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० चिल्डिया",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लालूपुरवा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ताजपुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सलौली",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल अखरी",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल शुक्लनपुरवा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल कनरखी",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल अटोरा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट स्कूल बगस्ती",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय रूखारा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय रूकनापुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय खालेपुरवा",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय दाखिन",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "पूर्व माध्यमिक विद्यालय ढोलनापुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय नसीरपुर कम्पोजिट",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथनिक विद्यालय असईपुर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय गोलोक कोडर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय रण्डाकोडर",
  },
  {
    district: "सीतापुर",
    medium: "बेसिक शिक्षा",
    name: "उच्च प्राथमिक विद्यालय",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, साण्या सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, बॉसुस सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज, जनुना विसर्वो सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "किसान इण्टर कालेज, सरैया राजासाहब सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "काल्चिन इण्टर कालेज महमूदाबाद सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री गाँधी विद्यालय इण्टर कालेज सिधौली सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज मछरेहटा सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "एम.डी. इण्टर कालेज मिश्रित सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज बीहटगौड़ सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "कृषक इण्टर कालेज, महोली सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "जे.एल.एम.डी.जे. इण्टर कालेज खैरावाद सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "पन्त इण्टर कालेज बम्भौरा सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "बिडला विद्या मंदिर इण्टर कालेज हरगाँव सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "सेवाश्रम इण्टर कालेज कॉजी कमालपुर ऐलिया सीतापुर",
  },
  {
    district: "सीतापुर",
    medium: "माध्यमिक शिक्षा",
    name: "खेमकरन इण्टर कालेज लहरपुर सीतापुर",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बीड़र",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 दुद्धी-2",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महुली",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कर्री",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 डुमडीहा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 अरंगी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तरावां",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बिच्छी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 छपका",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कम्हारी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 तेलगुड़वा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 चेरोबस्ती",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पड़रीपान",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पकरी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बाड़ी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 पिण्डारी",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 किशुनपुरवा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बगिया",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बोधाडीह",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 महिद्दीनपुर",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 मंठहवा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 कुसुम्हा-1",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 भवानीगांव",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 केतार",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 निपनिया",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय कन्हौरा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नगांव",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट वि0 चकया",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 बलुआ",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नंदना",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 डूभा",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 सुरंगीटोला",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नवाटोला-1",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 नवाटोला (पोखरा)",
  },
  {
    district: "सोनभद",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 रन्दहटोला",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० इ०का० डोहरी",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "पं० दीनदयाल उपा०रा०मा०इ०का० मुडिलाडीह",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय इ०का० सिरसिया ठकुराई खैरपुर",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय इ०का० अनपरा",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "पं०दीनदयाल उपा०रा०मा०इ०का० रामगढ़",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "पं० दीनदयाल उपा०रा०मा०इ०का० मेडरदह",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० हाईस्कूल चतरा",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० हाईस्कूल पइका",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० हाइस्कूल हिनौती",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल परसौना",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल पड़वनिया",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बालिका हाईस्कूल गोठानी",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल जुगैल",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय हाईस्कूल कन्हौरा",
  },
  {
    district: "सोनभद",
    medium: "माध्यमिक",
    name: "राजकीय बा० हाईस्कूल मुसौलिया",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पातीपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० महेशुआ",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ज्ञानीपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० केशापट्टी",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० संसारीपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हरीपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कटघरपुर चैहान",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मगरसनकलां",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गोदाम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मेवपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चंदौली",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० खरहा",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० धधुआ",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुढ़हा",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० चांदपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कूरेभार प्रथम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० विझुरी",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुजेश",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० गुफ्तारगंज प्रथम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० कूरेभार",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० ब्रह्मजीतपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट रवनिया पश्चिम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अलीगंज-1",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अलीगंज-2",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० इसरौली",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० लक्ष्मणपुर प्रथम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पुरेटिवरान",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० राइबिगो प्रथम",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० जफरपुर",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मुड़िलाडीह",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दक्खिनगाव",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पुरे मठा",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० इसौली",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० द्वितीय",
  },
  {
    district: "सुल्तानपुर",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० अरवल",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "गोपाल नारायण इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "स्लीम उच्चतर मा०वि०",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "त्रिलोचन शास्त्री इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "दामोदर इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रणवीर राजकुमार इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "महाराणा प्रताप उत्तरी",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "रामरती इंटर कॉलेज द्वारिकागंज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "जजीरादास शम्भुनाथ इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "हनुमत इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री विश्वनाथ इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्रीजसिंह इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "श्री सुभाष इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "जवाहर लाल स्मारक इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "मधुसूदन इंटर कॉलेज",
  },
  {
    district: "सुल्तानपुर",
    medium: "माध्यमिक शिक्षा",
    name: "सर्वोदय इंटर कॉलेज",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि टीकरगढ़ी",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० सकरन",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ०प्रा०वि० रायपुर बुजुर्ग",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०प्रा०वि० पाली",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०प्रा०वि० कुतुबद्दीन गढ़वा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ०पा०वि० ओसिया",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० मोहिद्दीनपुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हसनापुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०मा०वि० धौंरा हसनगंज",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय बल्लापुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय गहरपुरवा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय भिखारीपुर पतसिया",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ0प्रा0वि० बछौली",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० शाहपुर तोंदा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पोजिट विद्यालय आदमपुर फैजुल्लानग",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0उ0प्रा0वि० बजेहरा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०प्रा०वि० कुसुम्भी",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0उ0प्रा0वि० अजगैन",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० मऊ",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० भटियापुर बांगरमऊ",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० पंजूपुरवा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० दोस्ती नगर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० थाना प्रथम",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि0 जगतपुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा0वि० मनिकापुर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० बिसरामऊ",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० विद्यालय खलीलनगर",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० बडादेव",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो0 रायपुर नेवादा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० हफीजाबाद प्रथम",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० वंश खेड़ा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "प्रा०वि० छंगा खेडा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो० उ०प्रा०वि० भवानीगंज",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "उ0 प्रा0वि० करदहा",
  },
  {
    district: "उन्नाव",
    medium: "बेसिक शिक्षा",
    name: "कम्पो०उ0 प्रा0वि0 मौरावां आदर्श",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज नवाबगंज उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इण्टर कालेज चमरौली उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय बालिका इण्टर कालेज सफीपुर उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "महात्मा गांधी इण्टर कालेज सफीपुर उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "अटल बिहारी इण्टर कालेज उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "सुभाष इण्टर कालेज बांगरमऊ उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "हुबलाल इण्टर कालेज टेढ़ा उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "श्री रामनाथ इण्टर कालेज रूझई उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "त्रिवेणी काशी इण्टर कालेज बिहार, उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "गिरजाशंकर सरलादेवी इण्टर कालेज बिहार, उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "के०एन०पी०एन०इण्टर कालेज मौरांवा।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "यामलाल इण्टर कालेज नवाबगंज, उन्नाव।",
  },
  {
    district: "उन्नाव",
    medium: "माध्यमिक शिक्षा",
    name: "सरस्वती विद्या मन्दिर इण्टर कालेज शुक्लागंज उन्नाव।",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कोटवा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल नवापुरा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल रसूलगढ़",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल उमारहा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल पूरनपट्टी",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल केशरीपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल केराकतपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कादीपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल महेशपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल महगांव",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल गंगापुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल शंशाहपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल दयापुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कापरफोरवान",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल बेलवरिया",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल शिवपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल पिसनहरिया",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल मल्टी स्टोरी",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल महमूरगंज",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कबीरचौरा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल दशाश्वमेध",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल ऑर्डेली बाजार",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल कोतवाली",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल चौबेपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल चोलापुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल धरहरा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल गोसाईपुर पठखौली",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "स्कूल उगापुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल बेसाहूपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल डीईईपुर",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल दिल्ली विनायक",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल करधना",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल पिनराई",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल सामोग्रा",
  },
  {
    district: "वाराणसी",
    medium: "बेसिक शिक्षा",
    name: "कंपोजिट स्कूल रमईपट्टी",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "गोव क्वींस इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "प्रभु नारायण गोव इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "गवर्नमेंट जीआईआईआरएलएस इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय इंटर कॉलेज जक्खिनी",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "राजकीय अभिनव इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "भारती शिक्षा मंदिर इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "थायोसोफिकल हायर सेकेंड्री स्कूल",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "सनातन धर्म इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "सीएम एंग्लो बंगाली कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "बंगलालीटोला इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "कमलाकर छत्तीसगढ़ आदर्श सेवा विद्यालय इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "उदय प्रताप इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "नेशनल इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "काशी कृषक इंटर कॉलेज",
  },
  {
    district: "वाराणसी",
    medium: "माध्यमिक शिक्षा",
    name: "महाबोधि इंटर कॉलेज",
  },
];
